<template>
  <div class="ui-modal-basic i-modal-salesDetail">
    <div class="i-modal-wrap">
      <div class="i-modal-container">
        <div class="i-modal-header">
          <div class="header">
            <div class="prev" @click="close">
              {{ $t("common.prev") }}
            </div>
            <div class="title">
              <strong>{{ currentDate_YYYY_MM_DD }}</strong>
              {{ currentDate_ddd }}
            </div>
          </div>
          <div class="body">
            <div class="title">
              {{ $t("report.pureDeposit") }}
            </div>
            <div class="card">
              <vue-slick-carousel
                v-bind="visitInfoSettings"
                @beforeChange="onBeforeChangeCarousel"
              >
                <div v-for="n in 3" :key="n" class="object">
                  <div class="contain">
                    <ul class="info">
                      <li
                        v-for="(item, key, index) in salesListSummary"
                        :key="index"
                      >
                        <div class="caption">
                          {{ $t(`report.${key}`) }}
                        </div>
                        <div class="content">
                          {{ filterNumber(item) || "-" }}
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </vue-slick-carousel>
            </div>
          </div>
        </div>
        <div class="i-modal-body">
          <vue-slick-carousel ref="slickCarousel" v-bind="visitListSettings">
            <div v-for="n in 2" :key="n" class="body">
              <ul class="info">
                <li v-for="(item, key, index) in viewSalesList" :key="index">
                  <div class="title-wrapper">
                    <div class="title">
                      {{ $t(`report.${key}`) }}
                    </div>
                  </div>
                  <div class="contain">
                    <ul class="data">
                      <li
                        v-for="(dateItem, dateKey, dateIndex) in item"
                        :key="dateIndex"
                      >
                        <div class="header">
                          {{ $t(`report.${dateKey}`) }}
                        </div>
                        <div class="item">
                          {{ filterNumber(dateItem) || "-" }}
                        </div>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
          </vue-slick-carousel>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

export default {
  name: "SalesDetailPopup",
  components: { VueSlickCarousel },
  props: {
    currentDate: {
      type: Object,
      default: null,
    },
    viewSalesList: {
      type: Object,
      default: () => {},
    },
    salesListSummary: {
      type: Object,
      default: () => {},
    },
    filterNumber: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      data: {},
    };
  },
  computed: {
    pureCurrentDate() {
      return moment(this.currentDate).locale("ko");
    },
    currentDate_YYYY_MM_DD() {
      return this.pureCurrentDate.format("YYYY.MM.DD");
    },
    currentDate_ddd() {
      return this.pureCurrentDate.format("(ddd)");
    },
    visitInfoSettings() {
      return {
        arrows: false,
      };
    },
    visitListSettings() {
      return {
        arrows: false,
        touchMove: false,
      };
    },
  },
  methods: {
    async onBeforeChangeCarousel(prev, next) {
      const toNext =
        (prev === 0 && next === 1) ||
        (prev === 1 && next === 2) ||
        (prev === 2 && next === 0);
      await this.$nextTick();
      toNext
        ? this.$refs.slickCarousel.next()
        : this.$refs.slickCarousel.prev();
      this.$emit("submit", toNext);
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>
