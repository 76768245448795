<template>
  <input
    type="text"
    v-bind="$_attrs"
    v-on="$_listeners"
    class="e-control e-input"
    autocomplete="off"
    autocapitalize="off"
    spellcheck="false"
  />
</template>

<script>
import { phoneNumberFormatter} from "@/utils/CellphoneNumberFilter";

export default {
  name: "InputTelephone",
  props: {
    maxLength: {
      type: Number,
      default: () => 12,
    },
  },
  computed: {
    $_attrs() {
      return Object.assign({}, this.$attrs, {
        value: phoneNumberFormatter(this.$attrs.value?.replace(/[^\d]/gi, "")),
      });
    },
    $_listeners() {
      return Object.assign({}, this.$listeners, {
        input: (event) => {
          const afterReplaceValue = event.target.value
            .replace(/[^\d]/gi, "");

          if (afterReplaceValue.length > this.maxLength) {
            event.target.value = this.$attrs.value;
          } else {
            event.target.value = phoneNumberFormatter(afterReplaceValue);
            this.$emit("input", event.target.value);
          }
        },
        keydown: (event) => {
          if (event.key === "*") {
            event.preventDefault();
          }

          this.$emit("keydown", event);
        },
      });
    },
  },
};
</script>
