<template>
  <div class='calendar'>
    <div class='ca-header'>
      <ul class='ca-weeks'>
        <li v-for='(item, index) in days' :key='index'>
          <div class='item'>
            {{ item }}
          </div>
        </li>
      </ul>
    </div>
    <div class='ca-body'>
      <vue-slick-carousel
        ref='carousel'
        :key="carouselKey"
        v-bind='settings'
        @beforeChange='onBeforeChangeCarousel'
      >
        <div
          v-for='n in maxWeekLength' :key='n'
          class='ca-contain'
        >
          <div class='ca-days'>
            <ul
              v-for='(date, index) in viewDates(n)' :key='index'
              class='ca-row'
            >
              <!-- 휴일(Class) : ca-holiday / 오늘(Class) : ca-today / 마감(Class) : ca-deadline -->
              <!-- 이전/이후 일자(Class : ca-disabled -->
              <li
                v-for='(item, subIndex) in date' :key='subIndex'
                :class='[
                  getDayClosed(item) && "ca-holiday",
                  item.day === today && item.month === currentMonth && item.year === currentYear && "ca-today",
                  getDayReservationClosed(item) && "ca-deadline",
                  item.month !== month && "ca-disabled",
                ]'
                @click='onClickDate(item)'
              >
                <label>
                  <input
                    type='radio'
                    :checked='toMomentFormat(item) === selectedDate'
                  />
                  <div class='ca-day'>
                    <div
                      class='ca-item'
                      :style='{...getDayColorValue(item)}'
                    >
                      {{ item.day }}
                    </div>
                  </div>
                  <div class='ca-label'>
                    <div
                      class='ca-item'
                      :class='{...getDayClass(item)}'
                      :style='{...getDayStyle(item)}'
                      v-html='getDayValue(item)'
                    />
                  </div>
                </label>
              </li>
            </ul>
          </div>
        </div>
      </vue-slick-carousel>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import {
  DATE_FORMAT_YYYY_MM_DD,
  DATE_FORMAT_YYYY,
  DATE_FORMAT_M,
  DATE_FORMAT_D
} from '@/utils/Date';
import VueSlickCarousel from 'vue-slick-carousel';
import 'vue-slick-carousel/dist/vue-slick-carousel.css';
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css';
import {deepDiffs} from "@/utils/ObjectUtil";

export default {
  name: 'Calendar',
  components: { VueSlickCarousel },
  props: {
    output: {
      type: Array,
      default: () => []
    },
    isWeeksCalendar: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      carouselKey: 1,
      maxWeekLength: 3,
      dates: [],
      currentYear: 0,
      currentMonth: 0,
      year: 0,
      month: 0,
      lastMonthStart: 0,
      nextMonthStart: 0,
      day: 0,
      today: 0,
      selectedDate: moment().format(DATE_FORMAT_YYYY_MM_DD)
    };
  },
  watch: {
    async isWeeksCalendar(isWeeksCalendar) {
      this.maxWeekLength = this.isWeeksCalendar ? 7 : 3;
      this.carouselKey += 1
      if (
        isWeeksCalendar &&
        this.year &&
        this.month &&
        this.day
      ) {
        await this.$nextTick();
        const viewWeekIndex = this.getWeekOfMonth(moment(`${this.year}/${this.month}/${this.day}`));
        this.$refs.carousel.goTo(viewWeekIndex, true)
      }
    },
    async dates() {
      this.carouselKey += 1
      await this.$nextTick()
      if (!this.isWeeksCalendar) return;
      const viewWeekIndex = this.getWeekOfMonth(moment(`${this.year}/${this.month}/${this.day}`));
      this.$refs.carousel.goTo(viewWeekIndex, true)
    }
  },
  async created() {
    const date = new Date();
    this.currentYear = date.getFullYear();
    this.currentMonth = date.getMonth() + 1;
    this.year = this.currentYear;
    this.month = this.currentMonth;
    this.day = date.getDate();
    this.today = date.getDate();
    await this.calendarData();
  },
  computed: {
    days() {
      return [...new Array(7).keys()]
        ?.map(item => this.$t(`days._${item}`));
    },
    settings() {
      return {
        arrows: false
      };
    }
  },
  methods: {
    async calendarData(to = 'THIS') {
      this.month += to === 'PREV'
        ? -1
        : to === 'NEXT'
          ? 1
          : 0;
      if (this.month === 0) {
        this.year--;
        this.month = 12;
      } else if (this.month > 12) {
        this.year++;
        this.month = 1;
      }
      const [
        monthFirstDay,
        monthLastDate,
        lastMonthLastDate
      ] = this.getFirstDayLastDate(this.year, this.month);
      const dates = this.getMonthOfDays(
          monthFirstDay,
          monthLastDate,
          lastMonthLastDate
      );
      if (deepDiffs(this.dates, dates)) this.dates = dates;
    },
    viewDates(week) {
      return this.isWeeksCalendar
        ? [this.dates[week - 1]]
        : this.dates;
    },
    getWeekOfMonth(month) {
      const fromDateStartWeek = moment(month).startOf('month').startOf('week')
      const toDateStartWeek = moment(month).startOf('week')

      return toDateStartWeek.diff(fromDateStartWeek, 'week')
    },
    getFirstDayLastDate(year, month) {
      const firstDay = new Date(year, month - 1, 1).getDay();
      const lastDate = new Date(year, month, 0).getDate();
      let lastYear = year;
      let lastMonth = month - 1;
      if (month === 1) {
        lastMonth = 12;
        lastYear -= 1;
      }
      const prevLastDate = new Date(lastYear, lastMonth, 0).getDate();
      return [firstDay, lastDate, prevLastDate];
    },
    getMonthOfDays(monthFirstDay, monthLastDate, prevMonthLastDate) {
      let day = 1;
      let prevDay = (prevMonthLastDate - monthFirstDay) + 1;
      const dates = [];
      let weekOfDays = [];
      while (day <= monthLastDate) {
        if (day === 1) {
          for (let i = 0; i < monthFirstDay; i++) {
            if (i === 0) this.lastMonthStart = prevDay;
            weekOfDays.push({
              year: this.month > 1
                ? this.year
                : this.year - 1,
              month: this.month > 1
                ? this.month - 1
                : 12,
              day: prevDay
            });
            prevDay++;
          }
        }
        weekOfDays.push({
          year: this.year,
          month: this.month,
          day
        });
        if (weekOfDays.length === 7) {
          dates.push(weekOfDays);
          weekOfDays = [];
        }
        day++;
      }
      const len = weekOfDays.length;
      if (len > 0 && len < 7)
        for (let i = 1; i <= 7 - len; i++)
          weekOfDays.push({
            year: this.month < 12
              ? this.year
              : this.year + 1,
            month: this.month < 12
              ? this.month + 1
              : 1,
            day: i
          });
      if (weekOfDays.length > 0) dates.push(weekOfDays);
      this.nextMonthStart = weekOfDays[0];
      return dates;
    },
    getPureDayArray({ month, day }, type) {
      return [].concat(...this.output)
        ?.filter(item => item.month === month && item.day === day && item.type === type);
    },
    // 휴일 여부
    getDayClosed(item) {
      const output = this.getPureDayArray(item, 'CLOSED');
      return output.length > 0 && output[0]?.value;
    },
    // 마감 여부
    getDayReservationClosed(item) {
      const output = this.getPureDayArray(item, 'RESERVATION_CLOSED');
      return output.length > 0 && output[0]?.value;
    },
    // 색상 코드
    getDayColorValue(item) {
      const output = this.getPureDayArray(item, 'COLOR_VALUE');
      return output.length > 0 && output[0]?.style;
    },
    // 값 가져오기
    getDayValue(item) {
      const output = this.getPureDayArray(item, 'VALUE');
      return (output.length > 0 && output[0]?.value) || '';
    },
    getDayClass(item) {
      const output = this.getPureDayArray(item, 'VALUE');
      return output.length > 0 && output[0]?.class;
    },
    getDayStyle(item) {
      const output = this.getPureDayArray(item, 'VALUE');
      return output.length > 0 && output[0]?.style;
    },
    toMomentFormat({ year, month, day }) {
      return moment(`${year}/${month}/${day}`)
        .format(DATE_FORMAT_YYYY_MM_DD);
    },
    goToMonth(toNext = false, toEndOfMonth = false) {
      const year = Number(moment(this.selectedDate)
        .add(
          toNext && this.month === 12
            ? 1
            : !toNext && this.month === 1
            ? -1
            : 0,
          'year'
        )
        .format('YYYY'));
      const month = Number(moment(this.selectedDate)
        .add(toNext ? 1 : -1, 'M')
        .format('M'));
      const selectedDate = moment(this.selectedDate)
      const [,,lastDate] = this.getFirstDayLastDate(selectedDate.format('YYYY'), selectedDate.format('M'))
      const day = Number(toEndOfMonth ? lastDate : 1);
      this.onClickDate({ year, month, day }, false, false);
    },
    goToDate(date) {
      const year = Number(moment(date).format(DATE_FORMAT_YYYY));
      const month = Number(moment(date).format(DATE_FORMAT_M));
      const day = Number(moment(date).format(DATE_FORMAT_D));
      this.onClickDate({ year, month, day }, false, false);
    },
    onClickDate({ year, month, day }, isNotRefresh = false, isWeeksCalendar = true) {
      if (year) this.year = year;
      if (month) this.month = month;
      if (day) this.day = day;
      this.selectedDate = this.toMomentFormat({ year, month, day });
      this.calendarData();
      if (!isNotRefresh) this.$emit('selectedDate', this.selectedDate, isWeeksCalendar);
    },
    onBeforeChangeCarousel(prev, next) {
      if (this.isWeeksCalendar) {
        const lastWeek = this.getWeekOfMonth(moment(`${this.year}/${this.month}/1`).endOf('month'))
        const toNext = prev === lastWeek && (next === 0 || next === lastWeek + 1)
        const toPrev = (prev === 0 || prev === this.maxWeekLength) && next === 6
        if (toNext || toPrev) {
          this.goToMonth(toNext, toPrev);
        }
        return;
      }
      const toNext = (
        prev === 0 && next === 1 ||
        prev === 1 && next === 2 ||
        prev === 2 && next === 0
      );
      this.goToMonth(toNext);
    }
  }
};
</script>
