import Vue from 'vue';
import VueRouter from 'vue-router';
import VueBodyClass from 'vue-body-class';
import store from '@/store';
import Main from '@/views/Main';
import SignIn from '@/views/sign/SignIn';
import DailyReport from '@/views/DailyReport';
import ReportView from '@/views/ReportView';
import ReserveStatus from '@/views/ReserveStatus';
import VisitStatus from '@/views/VisitStatus';
import SalesStatus from '@/views/SalesStatus';
import { getAccessToken } from '@/utils/AuthorizationUtil';

Vue.use(VueRouter);

import common from '@/assets/css/common.css';
import commonLazy from '@/assets/css/common.lazy.scss';
import content from '@/assets/css/content.css';
import normalize from '@/assets/css/normalize.css';
import font from '@/assets/fonts/NotoSansCJKkr.css';

const routes = [
  {
    path: '/',
    name: 'Main',
    component: Main,
    meta: {
      layout: 'DefaultLayout',
      bodyClass: [
        'id-dashboard',
        store.getters.darkMode === 'N' ? 'light' : null
      ].join(' ')
    }
  },
  {
    path: '/sign-in',
    name: 'SignIn',
    component: SignIn,
    meta: {
      layout: 'SignLayout',
      bodyClass: [
        'id-index',
        store.getters.darkMode === 'N' ? 'light' : null
      ].join(' ')
    }
  },
  {
    path: '/daily-report',
    name: 'DailyReport',
    component: DailyReport,
    meta: {
      layout: 'DefaultLayout',
      bodyClass: [
        'id-businessReport',
        store.getters.darkMode === 'N' ? 'light' : null
      ].join(' ')
    }
  },
  {
    path: '/report-view',
    name: 'ReportView',
    component: ReportView,
    meta: { layout: 'SignLayout' }
  },
  {
    path: '/reserve-status',
    name: 'ReserveStatus',
    component: ReserveStatus,
    meta: {
      layout: 'DefaultLayout',
      bodyClass: [
        'id-reservation',
        store.getters.darkMode === 'N' ? 'light' : null
      ].join(' ')
    }
  },
  {
    path: '/visit-status',
    name: 'VisitStatus',
    component: VisitStatus,
    meta: {
      layout: 'DefaultLayout',
      bodyClass: [
        'id-visit',
        store.getters.darkMode === 'N' ? 'light' : null
      ].join(' ')
    }
  },
  {
    path: '/sales-status',
    name: 'SalesStatus',
    component: SalesStatus,
    meta: {
      layout: 'DefaultLayout',
      bodyClass: [
        'id-sales',
        store.getters.darkMode === 'N' ? 'light' : null
      ].join(' ')
    }
  }
];

const router = new VueRouter({
  mode: 'history',
  routes
});

router.afterEach((to) => {
  if (
    to.matched?.reduce(
      (previousValue, route) => previousValue || !!route.meta?.useUsualStyles,
      false
    )
  ) {
    common.use();
    commonLazy.use();
    content.use();
    normalize.use();
    font.use();
  } else {
    // common.unuse();
  }
});

const vueBodyClass = new VueBodyClass(routes);
router.beforeEach((to, from, next) => {
  vueBodyClass.guard(to, next);
  if (to.name === 'SignIn' || to.name === 'ReportView') {
    next();
  } else {
    if (!getAccessToken()) {
      next({ name: 'SignIn' });
    } else {
      next();
    }
  }
});

export default router;
