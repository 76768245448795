export const PATH_RESERVATION_TIMES = '/mis/reservation-times';

export function fetchReservationTimes(args) {
  return this.v1.get(`${PATH_RESERVATION_TIMES}/${args.resveDate}`, {
    params: {
      blankFlag: args.blankFlag,
      aditTeamFlag: args.aditTeamFlag
    }
  });
}

export function fetchReservationTimesDetail(args) {
  return this.v1.get(`${PATH_RESERVATION_TIMES}/detail`, {
    params: {
      timeId: args.timeId,
      resveNo: args.resveNo
    }
  });
}
