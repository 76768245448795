<template>
  <div class='ui-modal-basic i-modal-reservationInfo'>
    <div class='i-modal-wrap'>
      <div
          class='i-modal-container'
      >
        <div
            class='i-modal-header'
        >
          <div
              class='body'
          >
<!--                @click='onShowMemberImageClick(data.memberImagePath)'-->
            <div
                class='profile'
            >
              <div
                  class='item'
                  :style='{
                  backgroundImage: userInfo.imagePath && `url(${userInfo.imagePath})`,
                  backgroundSize: userInfo.imagePath && "cover"
                }'
              >
                {{ $t('common.profileImage') }}
              </div>
            </div>
            <div
                class='close'
                @click='close'
            >
              <div class='item'>
                {{ $t('common.close') }}
              </div>
            </div>
            <div
                class='title'
                @click='close'
            >
              <div class='item'>
                {{ headerTitle }}
              </div>
            </div>
          </div>
        </div>
        <div
            class='i-modal-body'
        >
          <div class='wrap'>
            <ul class='info'>
              <li>
                <div class='caption'>
                  <label class="required" >*</label>
                  {{ $t('common.resveName') }}
                </div>
                <div class='content'>
                  <input type="text" @click="lookUpMember" readonly v-model="userInformation.memberName" class="member-name-input input-style">
                </div>
              </li>
              <li>
                <div class='caption'>
                  {{ $t('common.contactTel') }}
                </div>
                <div class='content'>
<!--                  <input type="text" placeholder="'-' 없이 숫자만 입력" maxlength="11" class="input-style" v-model=" userInformation.hpNo ">-->
                  <component-telephone class="input-style" v-model="userInformation.hpNo"></component-telephone>
                </div>
              </li>
              <li>
                <div class='caption'>
                  <label class="required">*</label>
                  {{ $t('common.memberDiv') }}
                </div>
                <div class='content'>
                  <select class="select-style" v-model="userInformation.memberDiv">
                    <option v-for="(data,idx) in memberDivOptions" :value="data.comCode" :key="idx">{{data.comName}}</option>
                  </select>
                </div>
              </li>
              <li>
                <div class='caption'>
                  <label class="required">*</label>
                  {{ $t('common.memberGrade') }}
                </div>
                <div class='content'>
                  <select class="select-style" v-model="userInformation.memberGrade">
                    <option v-for="(data,idx) in memberGradeOptions" :value="data.comCode" :key="idx">{{data.comName}}</option>
                  </select>
                </div>
              </li>
              <li>
                <div class='caption'>
                  <label class="required">*</label>
                  {{ $t('common.resveKind') }}
                </div>
                <div class='content '>
                  <select class="select-style" v-model="userInformation.resveKind">
                    <option v-for="(data,idx) in resveKindOptions" :value="data.comCode" :key="idx">{{data.comName}}</option>
                  </select>
                </div>
              </li>
              <li>
                <div class='caption'>
                  <label class="required">*</label>
                  {{ $t('common.resveChannel') }}
                </div>
                <div class='content'>
                  <select class="select-style" v-model="userInformation.resveChannel">
                    <option v-for="(data,idx) in resveChannelOptions" :value="data.comCode" :key="idx">{{data.comName}}</option>
                  </select>
                </div>
              </li>
              <li>
                <div class='caption'>
                  {{ $t('common.resveRemarks') }}
                </div>
                <div class='content'>
                  <textarea class="textarea-style" v-model="userInformation.resveRemarks"></textarea>
                </div>
              </li>
              <li>
                <div class='caption'>
                  {{ $t('common.areaCode') }}
                </div>
                <div class='content'>
                  <select class="select-style" v-model="userInformation.areaCode">
                    <option v-for="(data,idx) in areaCodeOptions" :value="data.comCode" :key="idx">{{data.comName}}</option>
                  </select>
                </div>
              </li>
              <li>
                <div class='caption'>
                  {{ $t('common.sexCode') }}
                </div>
                <div class='content'>
                  <select class="select-style" v-model="userInformation.sexCode">
                    <option v-for="(data,idx) in sexCodeOptions" :value="data.comCode" :key="idx">{{data.comName}}</option>
                  </select>
                </div>
              </li>
              <li class="btn_box">
                <button type="button" @click="saveMember">예약</button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div
        v-if='isPopupOpened'
        class='ui-modal'
    >
      <time-check-modal
          v-if='isTimeCheckModalPopupOpend'
          ref='timeCheckModal'
          @close='onCloseTimeCheckModalPopup'
          @submit="autoSendSms"
      />
      <member-look-up-list-popup
          ref="memberLookUpListPopup"
          @memberLookUpClose="memberLookUpClose"
          @memberSendList="memberSendList"
          v-if="isMemberLookUpListOpen"
      />
    </div>
  </div>
</template>

<script>

import moment from 'moment';
import ComponentTelephone from "@/components/ComponentTelephone";
import {
  commonCodesGetComName,
  commonCodesGetCommonCode,
  commonCodeGetComCodeDefaultValue,
  commonCodesGetStandardInfo,
  commonCodesGetCommonCodeAttrbNameByCodeAndIdx
} from '@/utils/CommonCodeUtil';
import { getDayOfWeekCaption } from '@/utils/Date'
import GolfErpAPI from '@/api/GolfErpAPI';
import TimeCheckModal from "@/components/Modals/TimeCheckModal";
import MemberLookUpListPopup from '@/components/Modals/MemberLookUpListPopup'
export default {
  name: 'ReservationNoneInfoPopup',
  components: { MemberLookUpListPopup,TimeCheckModal,ComponentTelephone },
  props: {
    currentDate: {
      type: Object,
      default: null
    },
  },
  data() {
    return {
      isTimeCheckModalPopupOpend:false,
      isMemberLookUpListOpen:false,
      data:{},
      userInfo:{},
      arr:[],
      userInformation:{
        timeId: null,
        memberName: null,
        membershipId: null,
        memberNo: null,
        memberDiv: null,
        memberGrade: null,
        resveKind: null,
        resveChannel: null,
        sexCode: null,
        hpNo: null,
        resveRemarks: "",
        areaCode: null,
      },
      $viewer: null,
      memberDivOptions:commonCodesGetCommonCode("MEMBER_DIV"),
      resveKindOptions: commonCodesGetCommonCode("RESVE_KIND"),
      memberGradeOptions: commonCodesGetCommonCode("MEMBER_GRADE"),
      resveChannelOptions: commonCodesGetCommonCode("RESVE_CHANNEL"),
      areaCodeOptions: commonCodesGetCommonCode("AREA_CODE"),
      sexCodeOptions: commonCodesGetCommonCode("SEX_CODE"),
    };
  },
  computed: {
    isPopupOpened() {
      return (
          this.isTimeCheckModalPopupOpend ||
          this.isMemberLookUpListOpen
      );
    },
    headerTitle() {
      const courseLabel = commonCodesGetComName('COURSE_CODE', this.data.resveCourse);
      const partLabel = commonCodesGetComName('PART_DIV', this.data.partDiv);
      return `${this.todayLabel} ${this.data.resveTime} ${courseLabel} ${partLabel}`;
    },
    todayLabel() {
      return moment(this.currentDate)
          .locale('ko')
          .format('YYYY.MM.DD (ddd)');
    },
    // smsSendLabel() {
    //   const isSentSms = this.$t(`common.${this.data.smsSendFlag ? 'smsSend' : 'smsNotSend'}`);
    //   const prevLabel = `${isSentSms} ${this.$t('common.count', [this.data.smsSendCnt])}`;
    //   return this.data.smsSendFlag
    //       ? `${prevLabel} / ${this.data.smsSendDt}`
    //       : prevLabel;
    // }
  },
  mounted() {
    window.addEventListener('beforeunload', this.unLoadEvent);
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.unLoadEvent);
  },
  methods: {
    async unLoadEvent() {
      await GolfErpAPI.timeHoldingMis(this.userInformation.timeId,"CANCEL")
    },
    loadData(data) {
      this.data = data;
      this.defaultSetting();
    },
    defaultSetting() {
      const nowDay = moment(new Date()).format('YYYY-MM-DD')
      if (this.userInformation.resveKind === null) {
        if(this.data.resveDate === nowDay){
          this.userInformation.resveKind = "DAY";
        } else {
          this.userInformation.resveKind = commonCodeGetComCodeDefaultValue(
              "RESVE_KIND"
          );
        }
      }
      if (this.userInformation.resveChannel === null) {
        this.userInformation.resveChannel = commonCodeGetComCodeDefaultValue(
            "RESVE_CHANNEL"
        );
      }
      if (this.userInformation.timeId === null) {
        this.userInformation.timeId = this.data.timeId
      }
    },
    // onShowMemberImageClick(imageUrl) {
    //   if (!imageUrl) return;
    //   this.$viewer = this.$viewerApi({ images: [imageUrl] });
    // },
    async close() {
      await this.$emit('close');
      await this.$nextTick()
      await GolfErpAPI.timeHoldingMis(this.userInformation.timeId,"CANCEL")
      await this.$nextTick()
      await this.$emit("fetchList")
    },
    async lookUpMember(){
      this.isMemberLookUpListOpen = true;
      await this.$nextTick()
      this.$refs.memberLookUpListPopup.$refs.memberNameInput.focus()
    },
    memberLookUpClose() {
      this.isMemberLookUpListOpen = false;
    },
    memberSendList(data) {
      this.userInfo = {
        ...data,
        grpKind:'FIT'
      }
      this.userInformation.memberName = data.memberName ;
      this.userInformation.hpNo = data.hpNo ;
      this.userInformation.memberDiv = data.memberDiv ;
      this.userInformation.memberGrade = data.memberGrade ;
      this.userInformation.areaCode = data.areaCode ;
      this.userInformation.sexCode = data.sexCode ;
      this.userInformation.memberNo = data.memberNo ;
      this.userInformation.membershipId = data.membershipId ;
    },
    async saveMember() {
      this.userInformation.hpNo === "" ?
          this.userInformation.hpNo = undefined
          : this.userInformation.hpNo;

      if(!this.userInformation.memberName){
        this.isTimeCheckModalPopupOpend = true
        await this.$nextTick();
        this.$refs.timeCheckModal.putMessage("예약자명을 입력바랍니다");
        return;
      }
      await this.save();
    },
    async save(){
      const { data:{ resveId , resveNo } } =  await GolfErpAPI.saveMisReservation(this.userInformation)
      this.data.resveId = resveId
      this.data.resveNo = resveNo
      this.data.saveCheck = true
      await this.$emit("fetchList")
      if(this.userInformation.hpNo){
        this.isTimeCheckModalPopupOpend = true
        await this.$nextTick();
        await this.$refs.timeCheckModal.putMessage("예약문자를 전송하시겠습니까?",true);
      } else {
        this.$emit("close");
      }
    },
    onCloseTimeCheckModalPopup() {
      this.isTimeCheckModalPopupOpend = false;
      if(this.data.saveCheck){
        this.$emit("close");
      }
    },
    async autoSendSms() {
      let smsSendList = [];
      await this.setSendSmsData()
          .then((response) => {
            smsSendList = response;
          })
          .catch((error) => {
            console.log("setSendSmsData.===>", error);
          });
      const smsSendInfo = {
        type: "G_RESVE",
        bizName: commonCodesGetStandardInfo("bizName"),
        bizNameLocation: commonCodesGetStandardInfo("bizNameLocation"),
        kakaoSendFlag: false,
        smsKind: null,
        kakaoDispatchKey: commonCodesGetStandardInfo("kakaoDsptchKey"),
        kakaoTemplateCode: null,
        subject: null,
      };
      const smsWords = await GolfErpAPI.fetchAllSmsWords();

      const smsSendType = smsWords.find(
          (data) => data.smsSendType === smsSendInfo.type
      );

      let message =
          (smsSendType.smsWords != null ? smsSendType.smsWords : "") +
          // 이글몬트는 골프예약 SMS 내용에 에코프론트 제외
          // (commonCodesGetStandardInfo("noneChkinUseFlag") && !this.reservationDetailInfo.reservationInfo.grpName ? "\n" + commonCodesGetStandardInfo("noneChkinSmsWords") : "") +
          (smsSendType.aditWords != null ? "\n" + smsSendType.aditWords : "");

      if (smsSendInfo.bizNameLocation === "DOWN") {
        message =
            message +
            (smsSendInfo.bizName != null ? "\n" + smsSendInfo.bizName : "");
      }

      smsSendInfo.kakaoSendFlag = smsSendType.kakaoSendFlag;
      smsSendInfo.kakaoTemplateCode = smsSendType.kakaoTmplatCode;
      smsSendInfo.smsKind = smsSendType.smsKind;
      smsSendInfo.subject = smsSendType.smsSendTypeName;
      const time = new Date();
      let smsRequestList = [];
      smsSendList.forEach((data) => {
          let smsInfo = {
            type: smsSendInfo.type,
            kakaoFlag: smsSendInfo.kakaoSendFlag,
            kind: smsSendInfo.kakaoSendFlag
                ? smsSendInfo.smsKind
                : null,
            kakaoDispatchKey: smsSendInfo.kakaoSendFlag
                ? smsSendInfo.kakaoDispatchKey
                : null,
            kakaoTemplateKey: smsSendInfo.kakaoSendFlag
                ? smsSendInfo.kakaoTemplateCode
                : null,
            key: data.sendKey,
            reservedFlag: false,
            reserveDateTime: null,
            receiverName: data.receiverName,
            receiverNumber: data.contactTel,
            subject: smsSendInfo.subject,
            smsSender:"MIS",
            sendDateTime: time,
            message: message,
            replaceDataMap: {
              name: data.name,
              resveDate: data.resveDate,
              dwName: data.dwName,
              resveCourse: commonCodesGetComName("GAME_COURSE", data.resveCourse),
              resveTime: data.resveTime + " (1팀)",
              resveCourseTime: data.resveCourseTime,
              transResveDiv: data.transFlag === true ? "위임예약" : "예약",
              headerMemo: smsSendInfo.bizNameLocation === "UP" ? (smsSendInfo.bizName ? smsSendInfo.bizName : null) : null,
              cancelDate: data.cancelDate,
              cancelTime: data.cancelTime,
              cancelDw: data.cancelDw,
              resveNo: data.resveNo,
            },
            dispatchNumber: smsSendType.dsptchNo,
            sender: "ERP",
            timeId: (
                commonCodesGetStandardInfo("noneChkinUseFlag") && !this.data.grpName ?
                    this.data?.timeId : null),
            urlName: (commonCodesGetStandardInfo("noneChkinUseFlag") && !this.data.grpName ?
                this.userInfo?.memberName : null),
            urlContactTel: (commonCodesGetStandardInfo("noneChkinUseFlag") && !this.data.grpName ?
                this.userInfo?.hpNo : null),
          };

          smsRequestList.push(smsInfo);

      });

      smsRequestList = smsRequestList.filter(data => data.receiverNumber !== "01000000000");

      if (!(smsRequestList.length > 0)) {
        return;
      }
      await GolfErpAPI.sendSMS(smsRequestList);

      await this.$emit("close");
    },
    async setSendSmsData() {

      return new Promise( (resolve) => {

        let smsSendList = [];
        let sendData = {};

        sendData.sendKey = this.data.resveNo;
        sendData.resveId = this.data.resveId;
        sendData.grpNo = this.data.resveNo;
        sendData.resveCourse = this.data.resveCourse;
        sendData.resveTime = this.data.resveTime;
        sendData.transFlag = false;
        sendData.transResveDiv = "예약";
        sendData.resveNo = this.data.resveTime.replaceAll(":","").concat(this.data.resveCourse);

        const weekdayCncde = commonCodesGetStandardInfo("weekdayCncde");
        const weekendCncde = commonCodesGetStandardInfo("weekendCncde");
        const weekdayCnctime = commonCodesGetStandardInfo("weekdayCnctime");
        const weekendCnctime = commonCodesGetStandardInfo("weekendCnctime");

        if (this.userInfo.grpKind === "FIT") {
          sendData.resveDate = this.data.resveDate;
          sendData.dwName = getDayOfWeekCaption(
              moment(this.data.resveDate).toDate()
          );
          sendData.resveCourseTime =
              commonCodesGetComName("COURSE_CODE", this.data.resveCourse) + " " + this.data.resveTime + " (1팀)";
          sendData.cancelDate = moment(this.data.resveDate).add(this.data.bsnCode === "WEEKDAY" ? weekdayCncde * -1 : weekendCncde * -1, "d").format("YYYY-MM-DD");
          sendData.cancelTime = this.data.bsnCode === "WEEKDAY" ? weekdayCnctime : weekendCnctime;
          sendData.cancelDw = getDayOfWeekCaption(
              moment(sendData.cancelDate).toDate()
          );
        }
        if (this.userInfo.smsRecptnDiv === "B") {
          if (this.userInfo.resveMngrHp) {
            sendData.receiverName = this.userInformation.memberName;
            sendData.name =
                this.userInformation.memberName +
                " " +
                commonCodesGetCommonCodeAttrbNameByCodeAndIdx(
                    "MEMBER_DIV",
                    this.userInformation.memberDiv,
                    1
                );
            sendData.contactTel = this.userInformation.hpNo;
            sendData.smsSendFlag = true;

            smsSendList.push(JSON.parse(JSON.stringify(sendData)));
          }
        } else if (this.userInfo.smsRecptnDiv === "A") {
          sendData.receiverName = this.userInformation.memberName;
          sendData.name =
              this.userInformation.memberName +
              " " +
              commonCodesGetCommonCodeAttrbNameByCodeAndIdx(
                  "MEMBER_DIV",
                  this.userInformation.memberDiv,
                  1
              );

          if (this.userInformation.hpNo) {
            sendData.contactTel = this.userInformation.hpNo;
            sendData.smsSendFlag = true;

            smsSendList.push(JSON.parse(JSON.stringify(sendData)));
          }

        } else {
          if (this.userInformation.hpNo) {
            sendData.receiverName = this.userInformation.memberName;
            sendData.name =
                this.userInformation.memberName +
                " " +
                commonCodesGetCommonCodeAttrbNameByCodeAndIdx(
                    "MEMBER_DIV",
                    this.userInformation.memberDiv,
                    1
                );
            sendData.contactTel = this.userInformation.hpNo;
            sendData.smsSendFlag = true;

            smsSendList.push(JSON.parse(JSON.stringify(sendData)));
          }
        }

        resolve(smsSendList);
      });
    }
  },
};
</script>
<style scoped>
.required {
  color: red;
}
.info > li {
  display: flex;
  flex-flow: column nowrap;
}
.info > li > div {
  width: 100% !important;
}
.info > li > .caption{
  height: 30px;
  display: flex;
}
.member-name-input {
  border-radius: 2px;
  height: 24px;
  border: 0;
  background-size: 10px ,10px;
  background:  url('../../assets/images/button-icon-search.png') no-repeat center;
  background-position-x: 300px ;
  background-color: white;
  padding-right: 25px;
  box-sizing: border-box;
  cursor: pointer;
}
.input-style,.select-style {
  text-indent: 3px;
}
.input-style,.select-style,.textarea-style {
  width: 100%;
  height: 35px;
  background-color: white;
  box-sizing: border-box;
  border-radius: 2px;
  border: 1px solid #e0e0e0;
}
.textarea-style{
  height: 50px;
  resize: none;
}

.btn_box > button {
  width: 320px;
  height: 40px;
  background-color: skyblue;
  border: 0;
  color: white;
  border-radius: 10px;
}
</style>
