<template>
  <!-- 보고서 형태로 보기(Class) : reportView -->
  <div :class='[
    "container",
    isReportMode && "reportView"
  ]'>
    <div class='body-header'>
      <div class='date'>
        <div
          class='prev'
          @click='goToDay("PREV")'
        >
          <div class='item'>
            {{ $t('common.prev') }}
          </div>
        </div>
        <div class='title'>
          <div
            class='item'
            @click='onOpenDatePicker'
          >
            <strong>{{ currentDate_YYYY_MM_DD }}</strong>
            {{ currentDate_ddd }}
          </div>
          <date-picker
            ref='datePicker'
            @selected='onSelectedDatePicker'
          />
        </div>
        <div
          class='next'
          @click='goToDay("NEXT")'
        >
          <div class='item'>
            {{ $t('common.next') }}
          </div>
        </div>
      </div>
<!--      <div-->
<!--        :class='[isReportMode ? "card" : "report"]'-->
<!--        @click='onClickReportMode'-->
<!--      >-->
<!--        <div class='item'>-->
<!--          {{ $t(`common.${isReportMode ? 'cardView' : 'reportView'}`) }}-->
<!--        </div>-->
<!--      </div>-->
      <div
        class='refresh'
        @click='fetch'
      >
        <div class='item'>
          {{ $t('common.refresh') }}
        </div>
      </div>
    </div>
    <div class='body-content'>
      <div class='content-main'>
        <div class='title'>
          {{ $t('dashboard.mainTitle') }}
        </div>
        <div class='info'>
          <div class='card'>
            <vue-slick-carousel
              ref='carousel'
              class='list'
              v-bind='settings'
            >
              <div
                v-for='(data, index) in datasets' :key='index'
                class='object'
              >
                <div class='contain'>
                  <div class='header'>
                    <div class='header-title'>
                      {{ data.title }}
                    </div>
                    <div class='header-label'>
                      <div class='item'>
                        {{ data.label }}
                      </div>
                    </div>
                  </div>
                  <ul class='body'>
                    <li v-for='(item, i) in fields' :key='i'>
                      <div class='caption'>
                        {{ item.value }}
                      </div>
                      <div class='content'>
                        {{ data.content(item.field) | numberWithCommas }}
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </vue-slick-carousel>
          </div>
        </div>
      </div>
      <div class='content-sub'>
          <li class="visitStatus">
            <div class='subTitle'>
              <div class='title'>
                {{ $t('dashboard.subTitle.visitStatus') }}
              </div>
              <div class='caption'>
                {{ $t('dashboard.caption.visitStatus') }}
              </div>
            </div>
            <div class='info'>
              <ul class='list'>
                <li class="dashBoard-card">
                  <div class='header'>
                    <div class='header-title'>
                      {{ $t('report.visitTeamCount') }}
                    </div>
                  </div>
                  <ul class='body'>
                    <li>
                      <div class='caption'>
                        {{ $t('report.DD') }}
                      </div>
                      <div class='content'>
                        {{ visitPer('ddVisit09hTeam') + visitPer('ddVisit18hTeam') | numberWithCommas }}
                      </div>
                    </li>
                    <li>
                      <div class='caption'>
                        {{ $t('report.MM') }}
                      </div>
                      <div class='content'>
                        {{ visitPer('mmVisit09hTeam') + visitPer('mmVisit18hTeam') | numberWithCommas }}
                      </div>
                    </li>
                    <li>
                      <div class='caption'>
                        {{ $t('report.YY') }}
                      </div>
                      <div class='content'>
                        {{ visitPer('yyVisit09hTeam') + visitPer('yyVisit18hTeam') | numberWithCommas }}
                      </div>
                    </li>
                  </ul>
                </li>
                <li class="dashBoard-card">
                  <div class='header'>
                    <div class='header-title'>
                      {{ $t('report.visitCount') }}
                    </div>
                  </div>
                  <ul class='body'>
                    <li>
                      <div class='caption'>
                        {{ $t('report.DD') }}
                      </div>
                      <div class='content'>
                        {{ visitPer('ddVisitPerHoleDiv') | numberWithCommas }}
                      </div>
                    </li>
                    <li>
                      <div class='caption'>
                        {{ $t('report.MM') }}
                      </div>
                      <div class='content'>
                        {{ visitPer('mmVisitPerHoleDiv') | numberWithCommas }}
                      </div>
                    </li>
                    <li>
                      <div class='caption'>
                        {{ $t('report.YY') }}
                      </div>
                      <div class='content'>
                        {{ visitPer('yyVisitPerHoleDiv') | numberWithCommas }}
                      </div>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </li>

        <div class='signUpStatus'>
          <div class='subTitle'>
            <div class='title'>
              {{ $t('dashboard.subTitle.signUpStatus') }}
            </div>
            <div class='caption'>
              {{ $t('dashboard.caption.signUpStatus') }}
            </div>
          </div>
          <div class='info'>
            <ul class='list'>
              <li>
                <ul class='body'>
                  <li>
                    <div class='caption'>
                      {{ $t('report.DD') }}
                    </div>
                    <div class='content'>
                      {{ getWebMemberReportInfo('dayMemberCnt') | numberWithCommas }}
                    </div>
                  </li>
                  <li>
                    <div class='caption'>
                      {{ $t('report.MM') }}
                    </div>
                    <div class='content'>
                      {{ getWebMemberReportInfo('monMemberCnt') | numberWithCommas }}
                    </div>
                  </li>
                  <li>
                    <div class='caption'>
                      {{ $t('report.cumulative') }}
                    </div>
                    <div class='content'>
                      {{ getWebMemberReportInfo('totMemberCnt') | numberWithCommas }}
                    </div>
                  </li>
                  <li>
                    <div class='caption'>
                      {{ $t('report.localResidents') }}
                    </div>
                    <div class='content'>
                      {{ getWebMemberReportInfo('areaMemberCnt') | numberWithCommas }}
                    </div>
                  </li>
                  <li>
                    <div class='caption'>
                      {{ $t('report.importance') }}
                    </div>
                    <div class='content'>
                      {{ getWebMemberReportInfo('areaMemberRate') | numberWithCommas }} %
                    </div>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class='body-content report'>
      <div class='content-sub'>
        <ul class='report'>
          <li>
            <div class='subTitle'>
              <div class='title'>
                {{ $t('dashboard.mainTitle') }}
              </div>
            </div>
            <div class='subTable'>
              <div class='table'>
                <table>
                  <colgroup>
                    <col class='store' />
                    <col class='division' />
                    <col />
                    <col />
                    <col />
                  </colgroup>
                  <thead>
                  <tr>
                    <th style='width: 60px'>
                      {{ $t('report.workPlace') }}
                    </th>
                    <th style='width: 35px'>
                      {{ $t('report.division') }}
                    </th>
                    <th style='width: 60px'>
                      {{ $t('report.DD') }}
                    </th>
                    <th style='width: 60px'>
                      {{ $t('report.MM') }}
                    </th>
                    <th>
                      {{ $t('report.YY') }}
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td
                      rowspan='2'
                      class='store'
                      v-html='$t("report.golfSales")'
                    />
                    <td class='division'>
                      {{ $t('report.sales') }}
                    </td>
                    <td>
                      {{ feeCartRentSalesAmt('DD') | numberWithCommas }}
                    </td>
                    <td>
                      {{ feeCartRentSalesAmt('MM') | numberWithCommas }}
                    </td>
                    <td>
                      {{ feeCartRentSalesAmt('YY') | numberWithCommas }}
                    </td>
                  </tr>
                  <tr>
                    <td class='division borderLeft'>
                      {{ $t('report.guestPrice') }}
                    </td>
                    <td>
                      {{ feeRentCartSalesUnitPrice('DD') | numberWithCommas }}
                    </td>
                    <td>
                      {{ feeRentCartSalesUnitPrice('MM') | numberWithCommas }}
                    </td>
                    <td>
                      {{ feeRentCartSalesUnitPrice('YY') | numberWithCommas }}
                    </td>
                  </tr>
                  <tr>
                    <td class='store'>
                      {{ $t('report.food') }}
                    </td>
                    <td rowspan='2' class='division'>
                      {{ $t('report.guestPrice') }}
                    </td>
                    <td>
                      {{ foodSalesUnitPrice('DD') | numberWithCommas }}
                    </td>
                    <td>
                      {{ foodSalesUnitPrice('MM') | numberWithCommas }}
                    </td>
                    <td>
                      {{ foodSalesUnitPrice('YY') | numberWithCommas }}
                    </td>
                  </tr>
                  <tr>
                    <td class='store'>
                      {{ $t('report.proShop') }}
                    </td>
                    <td>
                      {{ shopSalesUnitPrice('DD') | numberWithCommas }}
                    </td>
                    <td>
                      {{ shopSalesUnitPrice('MM') | numberWithCommas }}
                    </td>
                    <td>
                      {{ shopSalesUnitPrice('YY') | numberWithCommas }}
                    </td>
                  </tr>
                  <tr>
                    <td class='store'>
                      {{ $t('report.food') }}
                    </td>
                    <td rowspan='2' class='division'>
                      {{ $t('report.fees') }}
                    </td>
                    <td>
                      {{ foodSalesAmt('DD') | numberWithCommas }}
                    </td>
                    <td>
                      {{ foodSalesAmt('MM') | numberWithCommas }}
                    </td>
                    <td>
                      {{ foodSalesAmt('YY') | numberWithCommas }}
                    </td>
                  </tr>
                  <tr>
                    <td class='store'>
                      {{ $t('report.proShop') }}
                    </td>
                    <td>
                      {{ shopSalesAmt('DD') | numberWithCommas }}
                    </td>
                    <td>
                      {{ shopSalesAmt('MM') | numberWithCommas }}
                    </td>
                    <td>
                      {{ shopSalesAmt('YY') | numberWithCommas }}
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </li>
          <li class='visitStatus'>
            <div class='subTitle'>
              <div class='title'>
                {{ $t('dashboard.subTitle.visitStatus') }}
              </div>
              <div class='caption'>
                {{ $t('dashboard.caption.visitStatus') }}
              </div>
            </div>
            <div class='subTable'>
              <div class='table'>
                <table>
                  <colgroup>
                    <col />
                    <col />
                    <col />
                    <col />
                  </colgroup>
                  <thead>
                  <tr>
                    <th>
                      {{ $t('report.division') }}
                    </th>
                    <th>
                      {{ $t('report.DD') }}
                    </th>
                    <th>
                      {{ $t('report.MM') }}
                    </th>
                    <th>
                      {{ $t('report.YY') }}
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td>
                      {{ $t('report.visitCount') }}
                    </td>
                    <td>
                      {{ visitPer('ddVisitPerHoleDiv') | numberWithCommas }}
                    </td>
                    <td>
                      {{ visitPer('mmVisitPerHoleDiv') | numberWithCommas }}
                    </td>
                    <td>
                      {{ visitPer('yyVisitPerHoleDiv') | numberWithCommas }}
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </li>
          <li class='signUpStatus'>
            <div class='subTitle'>
              <div class='title'>
                {{ $t('dashboard.subTitle.signUpStatus') }}
              </div>
              <div class='caption'>
                {{ $t('dashboard.caption.signUpStatus') }}
              </div>
            </div>
            <div class='subTable'>
              <div class='table'>
                <table>
                  <colgroup>
                    <col />
                    <col />
                    <col />
                    <col />
                    <col />
                  </colgroup>
                  <thead>
                  <tr>
                    <th>
                      {{ $t('report.DD') }}
                    </th>
                    <th>
                      {{ $t('report.MM') }}
                    </th>
                    <th>
                      {{ $t('report.cumulative') }}
                    </th>
                    <th>
                      {{ $t('report.localResidents') }}
                    </th>
                    <th>
                      {{ $t('report.importance') }}
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td>
                      {{ getWebMemberReportInfo('dayMemberCnt') | numberWithCommas }}
                    </td>
                    <td>
                      {{ getWebMemberReportInfo('monMemberCnt') | numberWithCommas }}
                    </td>
                    <td class='highlight'>
                      {{ getWebMemberReportInfo('totMemberCnt') | numberWithCommas }}
                    </td>
                    <td>
                      {{ getWebMemberReportInfo('areaMemberCnt') | numberWithCommas }}
                    </td>
                    <td>
                      {{ getWebMemberReportInfo('areaMemberRate') | numberWithCommas }} %
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import VueSlickCarousel from 'vue-slick-carousel';
import { mapGetters } from 'vuex';
import { DATE_FORMAT_YYYY_MM_DD } from '@/utils/Date';
import { numberWithCommas } from '@/utils/Number';
import DatePicker from '@/components/DatePicker';
import GolfErpAPI from '@/api/GolfErpAPI';
import 'vue-slick-carousel/dist/vue-slick-carousel.css';
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css';

export default {
  name: 'Main',
  components: {
    VueSlickCarousel,
    DatePicker
  },
  data() {
    return {
      currentDate: moment(),
      reportData: {
        visitPerInfo: {
          ddVisitPer: 0,
          ddVisitPerHoleDiv: 0,
          mmVisitPer: 0,
          mmVisitPerHoleDiv: 0,
          yyVisitPer: 0,
          yyVisitPerHoleDiv: 0
        }
      }
    };
  },
  filters: { numberWithCommas },
  async mounted() {
    await this.fetch();
    await this.$nextTick();
    this.$refs.datePicker.setDate(this.currentDate.format(DATE_FORMAT_YYYY_MM_DD));
  },
  computed: {
    ...mapGetters(['reportModeByDashboard']),
    currentDate_YYYY_MM_DD() {
      return moment(this.currentDate).locale('ko').format('YYYY.MM.DD');
    },
    currentDate_ddd() {
      return moment(this.currentDate).locale('ko').format('(ddd)');
    },
    settings() {
      return {
        arrows: false,
        dots: true
      };
    },
    datasets() {
      return [
        {
          title: this.$t('report.golfSalesWithoutNewLine'),
          label: this.$t('report.sales'),
          content: this.feeCartRentSalesAmt
        },
        {
          title: this.$t('report.golfSalesWithoutNewLine'),
          label: this.$t('report.guestPrice'),
          content: this.feeRentCartSalesUnitPrice
        },
        {
          title: this.$t('report.food'),
          label: this.$t('report.guestPrice'),
          content: this.foodSalesUnitPrice
        },
        {
          title: this.$t('report.proShop'),
          label: this.$t('report.guestPrice'),
          content: this.shopSalesUnitPrice
        },
        {
          title: this.$t('report.food'),
          label: this.$t('report.fees'),
          content: this.foodSalesAmt
        },
        {
          title: this.$t('report.proShop'),
          label: this.$t('report.fees'),
          content: this.shopSalesAmt
        }
      ];
    },
    fields() {
      return [
        {
          field: 'DD',
          value: this.$t('report.DD')
        }, {
          field: 'MM',
          value: this.$t('report.MM')
        }, {
          field: 'YY',
          value: this.$t('report.YY')
        }
      ];
    },
    // isReportMode() {
    //   return this.reportModeByDashboard === 'Y';
    // }
  },
  methods: {
    // ...mapActions(['changeReportModeByDashboard']),
    // async onClickReportMode() {
    //   await this.changeReportModeByDashboard({ reportModeByDashboard: this.isReportMode ? 'N' : 'Y' });
    // },
    getCloseAcmtlSales(acmtlSumDiv) {
      return this.reportData
        ?.closeAcmtlSalesList
        ?.find(item => item.acmtlSumDiv === acmtlSumDiv);
    },
    getVisitTeamCntAndPerTeamList(acmtlSumDiv) {
      return this.reportData
        ?.visitTeamCntAndPerTeamList
        ?.find(item => item.acmtlSumDiv === acmtlSumDiv);
    },
    getWebMemberReportInfo(key) {
      return this.reportData
        ?.webMemberReportInfo?.[key];
    },
    feeCartRentSalesAmt(acmtlSumDiv) {
      return Math.floor(((
        this.getCloseAcmtlSales(acmtlSumDiv)?.feeSalesAmt +
        this.getCloseAcmtlSales(acmtlSumDiv)?.cartSalesAmt +
        this.getCloseAcmtlSales(acmtlSumDiv)?.rentSalesAmt
      ) || 0));
    },
    feeRentCartSalesUnitPrice(acmtlSumDiv) {
      return Math.floor(((
        this.getCloseAcmtlSales(acmtlSumDiv)?.feeRentCartSalesUnitPrice
      ) || 0) );
    },
    foodSalesUnitPrice(acmtlSumDiv) {
      return Math.floor(this.getCloseAcmtlSales(acmtlSumDiv)?.foodSalesUnitPrice || 0);
    },
    shopSalesUnitPrice(acmtlSumDiv) {
      return Math.floor(this.getCloseAcmtlSales(acmtlSumDiv)?.shopSalesUnitPrice || 0);
    },
    foodSalesAmt(acmtlSumDiv) {
      return Math.floor(this.getCloseAcmtlSales(acmtlSumDiv)?.foodSalesAmt || 0);
    },
    shopSalesAmt(acmtlSumDiv) {
      return Math.floor(this.getCloseAcmtlSales(acmtlSumDiv)?.shopSalesAmt || 0);
    },
    visitPer(key) {
      return this.reportData?.visitPerInfo
        ? this.reportData?.visitPerInfo[key]
        : null;
    },
    async goToDay(to = 'THIS') {
      switch (to) {
        case 'PREV':
          this.currentDate = moment(this.currentDate)
            .add(-1, 'days');
          break;
        case 'NEXT':
          this.currentDate = moment(this.currentDate)
            .add(1, 'days');
          break;
        default:
          this.currentDate = moment();
          break;
      }
      await this.fetch();
    },
    onOpenDatePicker() {
      this.$refs.datePicker.openPicker();
    },
    async onSelectedDatePicker(date) {
      this.currentDate = moment(date);
      await this.fetch();
    },
    async fetch() {
      const bsnDate = this.currentDate.format(DATE_FORMAT_YYYY_MM_DD);
      const { data: closeDiv } = await GolfErpAPI.getCloseDiv({
        bsnDate: moment(this.currentDate).format('YYYY-MM-DD'),
        storeCode: 'FRONT'
      });
      if (closeDiv !== 'Y') {
        const requestList = [
          'SP_CLOSE_PART_DIV',
          'SP_CLOSE_SALES_STORE',
          'SP_CLOSE_ACMTL_BASIC',
          'SP_CLOSE_ACMTL_SALES',
          'SP_CLOSE_CARD',
          'SP_CLOSE_PAYMT',
          'SP_CLOSE_SALES_FEE'
        ];
        const inParams = [moment(this.currentDate).format('YYYYMMDD')];
        let request = [];
        requestList.map((item) => request.push({ name: item, inParams }));
        await GolfErpAPI.putStoredProcedureExecute({ storedProcedureInfos: request });
      }
      let reportData = await GolfErpAPI.getDashboard({
        bsnDate,
        amtFlag: false
      });
      reportData.closeAcmtlSalesList?.map(item => {
        const foodFieldName = `${item.acmtlSumDiv.toLowerCase()}FeeFoodNorAmt`;
        const shopFieldName = `${item.acmtlSumDiv.toLowerCase()}FeeShopNorAmt`;
        item.foodSalesAmt = reportData?.consignmentSalesReportInfoV2?.[foodFieldName] || 0;
        item.shopSalesAmt = reportData?.consignmentSalesReportInfoV2?.[shopFieldName] || 0;
        return item;
      });
      this.reportData = reportData;
    }
  }
};
</script>