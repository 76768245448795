import axios from 'axios';
import store from '@/store';
import GolfErpAPIBadRequestError from '@/api/errors/GolfErpAPIBadRequestError';
import GolfErpAPIUnauthorizedError
  from '@/api/errors/GolfErpAPIUnauthorizedError';
import GolfErpAPIForbiddenError from '@/api/errors/GolfErpAPIForbiddenError';
import GolfErpAPINotFoundError from '@/api/errors/GolfErpAPINotFoundError';
import GolfErpAPIInternalServerError
  from '@/api/errors/GolfErpAPIInternalServerError';
import {
  issueAuthorization,
  removeAuthorization
} from '@/api/auth/Authorization';
import { compactObjectValues } from '@/utils/ObjectUtil';
import { getAccessToken } from '@/utils/AuthorizationUtil';
import {
  fetchBusinessPlaces,
  fetchTotalInfo,
  putImageUpload
} from '@/api/common/Common';
import { fetchReservationCalendar } from '@/api/calender/Calender';
import {
  getDailyReport,
  getCloseDiv,
  putStoredProcedureExecute
} from '@/api/daily-close/DailyReport';
import { getDashboard } from '@/api/dashboard/Dashboard';
import {
  fetchMisMembersLookUp,
  fetchMisVisitLookup,
  fetchMisVisitStatus,
  fetchMisVisitStatusByBsnDate,
  fetchMisSalesStatus,
  saveMisReservation,
  timeHoldingMis,
  webTimeCancel,
  fetchAllSmsWords,
  sendSMS
} from '@/api/mis/Mis';
import {
  fetchReservationTimes,
  fetchReservationTimesDetail
} from '@/api/reservation/ReservationTime';
import NetworkError from '@/errors/NetworkError';

function createAxiosInstance(baseURL, timeout = 30000) {
  const http = axios.create({
    baseURL,
    timeout
  });

  http.interceptors.request.use(
    (config) => {
      const accessToken = getAccessToken();

      if (accessToken) {
        config.headers['Authorization'] = `AdminYJ ${accessToken}`;
      }

      if (!config.isBackground) {
        store._vm.$EventBus.$emit('loaderOn');
      }

      switch (config.method) {
        case 'get':
          if (config.params) {
            config.params = compactObjectValues(
              JSON.parse(JSON.stringify(config.params)),
              true,
              false
            );
          }
          break;
        case 'post':
        case 'put':
        case 'patch':
        case 'delete':
          if (config.data) {
            config.data = compactObjectValues(
              JSON.parse(JSON.stringify(config.data)),
              false,
              true
            );
          }
          break;
      }

      return config;
    },
    (error) => {
      store._vm.$EventBus.$emit('loaderOff');

      return Promise.reject(error);
    }
  );
  http.interceptors.response.use(
    (response) => {
      if (!response.config.isBackground) {
        store._vm.$EventBus.$emit('loaderOff');
      }

      const { data } = response;

      if (response.headers['content-type'] === 'application/vnd.ms-excel') {
        const filename = response.headers['x-suggested-filename'];

        const url = window.URL.createObjectURL(
          new Blob([data], { type: 'application/vnd.ms-excel' })
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      }

      return data;
    },
    async error => {
      if (
        !error.response ||
        error.response.status === 408 ||
        error.code === 'ECONNABORTED'
      ) {
        return Promise.reject(new NetworkError(error));
      }

      const { response } = error;

      if (!response.config.isBackground) {
        store._vm.$EventBus.$emit('loaderOff');
      }

      if (response.data?.status === 'SESSION_EXPIRED') {
        await store.dispatch('logout');
        store._vm.$EventBus.$emit('user-session-expired');
      }

      switch (response.status) {
        case 400:
          throw new GolfErpAPIBadRequestError(response.data);
        case 401:
          throw new GolfErpAPIUnauthorizedError(response.data);
        case 403:
          throw new GolfErpAPIForbiddenError(response.data);
        case 404:
          throw new GolfErpAPINotFoundError(response.data);
        case 500:
          throw new GolfErpAPIInternalServerError(response.data);
      }

      return Promise.reject(error);
    }
  );

  return http;
}

class GolfErpAPI {
  constructor() {
    this.v1 = createAxiosInstance(
      process.env.VUE_APP_HOST_API_BASE_V1,
      process.env.VUE_APP_API_TIMEOUT
    );
    this.v2 = createAxiosInstance(
      process.env.VUE_APP_HOST_API_BASE_V2,
      process.env.VUE_APP_API_TIMEOUT
    );
    this.interruptTokeSourceMap = {};
  }

  interrupt(interruptTokenId, message) {
    const cancelTokenSource = this.interruptTokeSourceMap[interruptTokenId];
    if (cancelTokenSource) {
      cancelTokenSource.cancel(message);
      delete this.interruptTokeSourceMap[interruptTokenId];
    }
  }

  async login(email, password) {
    const {
      status,
      data: { accessToken }
    } = await issueAuthorization.apply(this, [email, password]);
    return { status, accessToken };
  }

  async logout() {
    await removeAuthorization.apply(this);
  }

  async fetchBusinessPlaces() {
    const { data } = await fetchBusinessPlaces.apply(this, []);
    return data;
  }

  async fetchTotalInfo() {
    const {
      data: {
        commonCodes,
        standardInfos,
        bisMstInfo,
        authList
      }
    } = await fetchTotalInfo.apply(this, []);

    return {
      commonCodes,
      standardInfos,
      bisMstInfo,
      authList
    };
  }

  async putImageUpload(request) {
    const { data } = await putImageUpload.apply(this, [request]);
    return data;
  }

  async fetchReservationCalendar(args) {
    const { data } = await fetchReservationCalendar.apply(this, [args]);
    return data;
  }

  async getDailyReport(args) {
    const { data } = await getDailyReport.apply(this, [args]);
    return data;
  }

  async getCloseDiv(args) {
    return await getCloseDiv.apply(this, [args]);
  }

  async putStoredProcedureExecute(request) {
    const { status } = await putStoredProcedureExecute.apply(this, [
      request
    ]);
    return status;
  }

  async getDashboard(args) {
    const { data } = await getDashboard.apply(this, [args]);
    return data;
  }
  async saveMisReservation(args) {
    const  data  = await saveMisReservation.apply(this, [args]);
    return data;
  }
  async timeHoldingMis(args,status) {
    const  data  = await timeHoldingMis.apply(this, [args,status]);
    return data;
  }
  async webTimeCancel(args) {
    const  data  = await webTimeCancel.apply(this, [args]);
    return data;
  }
  async fetchMisVisitStatus(args) {
    const { data } = await fetchMisVisitStatus.apply(this, [args]);
    return data;
  }
  async fetchMisVisitLookup(args) {
    const { data } = await  fetchMisVisitLookup.apply(this, [args]);
    return data
  }
  async fetchAllSmsWords() {
    const { data } = await  fetchAllSmsWords.apply(this, []);
    return data
  }
  async sendSMS(args) {
    const { data } = await  sendSMS.apply(this, [args]);
    return data
  }
  async fetchMisMembersLookUp(args) {
    const { data } = await  fetchMisMembersLookUp.apply(this, [args]);
    return data
  }

  async fetchMisVisitStatusByBsnDate(args) {
    const { data } = await fetchMisVisitStatusByBsnDate.apply(this, [args]);
    return data;
  }

  async fetchMisSalesStatus(args) {
    const { data } = await fetchMisSalesStatus.apply(this, [args]);
    return data;
  }

  async fetchReservationTimes(args) {
    const { data } = await fetchReservationTimes.apply(this, [args]);
    return data;
  }

  async fetchReservationTimesDetail(args) {
    const { data } = await fetchReservationTimesDetail.apply(this, [args]);
    return data;
  }
}

export default new GolfErpAPI();
