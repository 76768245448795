<template>
  <div class='ui-modal-alert'>
    <div class='outer'>
      <div class='inner'>
        <div class='i-modal-wrap'>
          <div class='i-modal-container custom-box'>
            <div
                class='i-modal-body'
                v-html='this.message'
            />
            <ul class='i-modal-footer custom-footer'>
              <li @click='submit' class="custom-modal" v-if="isSubmitModal">
                <button>
                  <div class='i-item'>
                    {{ $t('common.submit') }}
                  </div>
                </button>
              </li>
              <li @click='close' class="custom-modal">
                <button class="custom-button">
                  <div class='i-item'>
                    {{ $t('common.cancel') }}
                  </div>
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'TimeCheckModal',
  data() {
    return {
      message: null,
      isSubmitModal:false,
      data:[]
    };
  },
  methods: {
    putMessage(msg,isSubmitCheck=false,data=undefined) {
      this.message = msg
      this.isSubmitModal = isSubmitCheck
      this.data = data
    },
    close() {
      this.$emit('close');
    },
    submit() {
      this.$emit('submit',this.data);
    }
  }
};
</script>
<style scoped>
.i-modal-wrap {
  height: auto !important;
}
.custom-box {
  border-radius: 20px;
  overflow: hidden;
}
.custom-footer{
  display: flex;
}
.custom-modal {
  flex: 1;
  position: relative !important;
}
.custom-button {
  border-radius: 0 !important;
  background-color: gray !important;
}
.light .custom-button {
  background-color: lightgray !important;
}
</style>

