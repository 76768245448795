<template>
  <footer>
    <div class='footer-wrap'>
      <ul class='menu'>
        <!-- 활성(Class) : active -->
        <li
          v-for='(item, index) in getFooterMenuList' :key='index'
          :class='[
            item.className,
            routerName === item.routerName && "active"
          ]'
          @click='onClickMenu(item)'
        >
          <div class='item'>
            {{ item.label }}
          </div>
        </li>
      </ul>
    </div>
  </footer>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'TheFooter',
  data() {
    return {
      routerName: null,
      /* TODO : 메뉴 권한 */
      footerMenuList: [
        {
          className: 'dashboard',
          routerName: 'Main',
          label: this.$t('main.dashboard'),
          authList: [
            'misDashboardGet'
          ]
        }, {
          className: 'report',
          routerName: 'DailyReport',
          path: 'daily-report',
          label: this.$t('main.dailyReport'),
          authList: [
            'misDailyReportGet'
          ]
        }, {
          className: 'reservation',
          routerName: 'ReserveStatus',
          path: 'reserve-status',
          label: this.$t('main.reserveStatus'),
          authList: [
            'misResveStatusGet'
          ]
        }, {
          className: 'visit',
          routerName: 'VisitStatus',
          path: 'visit-status',
          label: this.$t('main.visitStatus'),
          authList: [
            'misVisitStatusGet'
          ]
        }, {
          className: 'sales',
          routerName: 'SalesStatus',
          path: 'sales-status',
          label: this.$t('main.salesStatus'),
          authList: [
            'misSalesStatusGet'
          ]
        }]
    };
  },
  watch: {
    '$route.name'() {
      this.routerName = this.$route.name;
    }
  },
  async created() {
    this.routerName = this.$route.name;
  },
  computed: {
    ...mapGetters(['authList']),
    getFooterMenuList() {
      let result = [];
      this.footerMenuList
        .forEach(item => {
          let flag = true;
          if (item.authList) {
            for (const auth of item.authList) {
              if (!this.authList.includes(auth)) {
                flag = false;
                console.log(auth);
              }
            }
            if (flag) result.push(item);
          }
        });
      return result;
    }
  },
  methods: {
    async onClickMenu(item) {
      const path = `/${item.path || ''}`;
      await this.$router.push(path);
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }
  }
};
</script>
