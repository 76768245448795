export default class GolfErpAPIError extends Error {
  #data;

  constructor(data) {
    super(`Project API Error: '${data.status}'`);
    this.#data = data;
  }

  get data() {
    return JSON.parse(JSON.stringify(this.#data));
  }

  get httpErrorMessage() {
    return 'ProjectAPIError';
  }
}
