<template>
  <!-- 보고서 형태로 보기(Class) : reportView -->
  <div :class="['container', isReportMode && 'reportView']">
    <div class="body-header">
      <div class="date">
        <div class="prev" @click="goToDay('PREV')">
          <div class="item">
            {{ $t("common.prev") }}
          </div>
        </div>
        <div class="title">
          <div class="item" @click="onOpenDatePicker">
            <strong>{{ currentDate_YYYY_MM_DD }}</strong>
            {{ currentDate_ddd }}
          </div>
          <date-picker ref="datePicker" @selected="onSelectedDatePicker" />
        </div>
        <div class="next" @click="goToDay('NEXT')">
          <div class="item">
            {{ $t("common.next") }}
          </div>
        </div>
      </div>
      <div class="share" @click="download">
        <div class="item">
          {{ $t("common.share") }}
        </div>
      </div>
      <!--      <div-->
      <!--        :class='[isReportMode ? "card" : "report"]'-->
      <!--        @click='onClickReportMode'-->
      <!--      >-->
      <!--        <div class='item'>-->
      <!--          {{ $t(`common.${isReportMode ? 'cardView' : 'reportView'}`) }}-->
      <!--        </div>-->
      <!--      </div>-->
      <div class="refresh" @click="fetch">
        <div class="item">
          {{ $t("common.refresh") }}
        </div>
      </div>
    </div>
    <div class="body-content">
      <div class="content-main">
        <div
          class="title"
          v-html="$t('dailyReport.top.title', [currentDate_M])"
        />
        <ul class="info">
          <li class="day">
            <div class="contain">
              <div class="caption">
                {{ $t("dailyReport.top.day") }}
              </div>
              <div class="content">
                {{
                  $t("common.days", [
                    reportData.monthlyBsnDateInfo.weekdayBsnDay,
                  ])
                }}
              </div>
            </div>
          </li>
          <li class="weekend">
            <div class="contain">
              <div class="caption">
                {{ $t("dailyReport.top.weekend") }}
              </div>
              <div class="content">
                {{
                  $t("common.days", [
                    reportData.monthlyBsnDateInfo.weekendBsnDay,
                  ])
                }}
              </div>
            </div>
          </li>
          <li class="total">
            <div class="contain">
              <div class="caption">
                {{ $t("dailyReport.top.total") }}
              </div>
              <div class="content">
                {{
                  $t("common.days", [reportData.monthlyBsnDateInfo.totBsnDay])
                }}
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div class="content-sub">
        <ul class="card">
          <!-- 활성(Class) : active -->
          <li :class="reportViewIndexList.includes(0) && 'active'">
            <div class="subTitle" @click="onClickReportView(0)">
              <div class="title">
                {{ $t("dailyReport.subTitle.salesStatus") }}
              </div>
              <i />
            </div>
            <div class="info">
              <ul class="list">
                <li>
                  <div class="header">
                    <div class="header-title">
                      {{ $t("report.today") }}
                    </div>
                  </div>
                  <ul class="body">
                    <li
                      v-for="(obj, key, index) in salesStatusDataList.day"
                      :key="index"
                    >
                      <div class="caption">
                        {{ $t(`report.${key}`) }}
                      </div>
                      <div class="content">
                        {{ filterNumber(obj) || "-" }}
                      </div>
                    </li>
                  </ul>
                </li>
                <li>
                  <div class="header">
                    <div class="header-title">
                      {{ $t("report.MM") }}
                    </div>
                  </div>
                  <ul class="body">
                    <li
                      v-for="(obj, key, index) in salesStatusDataList.month"
                      :key="index"
                    >
                      <div class="caption">
                        {{ $t(`report.${key}`) }}
                      </div>
                      <div class="content">
                        {{ filterNumber(obj) || "-" }}
                      </div>
                    </li>
                  </ul>
                </li>
                <li>
                  <div class="header">
                    <div class="header-title">
                      {{ $t("report.YY") }}
                    </div>
                  </div>
                  <ul class="body">
                    <li
                      v-for="(obj, key, index) in salesStatusDataList.year"
                      :key="index"
                    >
                      <div class="caption">
                        {{ $t(`report.${key}`) }}
                      </div>
                      <div class="content">
                        {{ filterNumber(obj) || "-" }}
                      </div>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </li>
          <!-- 활성(Class) : active -->
          <li :class="reportViewIndexList.includes(1) && 'active'">
            <div class="subTitle" @click="onClickReportView(1)">
              <div class="title">
                {{ $t("dailyReport.subTitle.guestPriceStatus") }}
              </div>
              <i />
            </div>
            <div class="info">
              <ul class="list">
                <li>
                  <div class="header">
                    <div class="header-title">
                      {{ $t("report.today") }}
                    </div>
                  </div>
                  <ul class="body">
                    <li
                      v-for="(item, key, index) in reportData.guestPrice.day"
                      :key="index"
                    >
                      <div class="caption">
                        {{ $t(`report.${key}`) }}
                      </div>
                      <div class="content">
                        {{ filterNumber(item) || "-" }}
                      </div>
                    </li>
                  </ul>
                </li>
                <li>
                  <div class="header">
                    <div class="header-title">
                      {{ $t("report.MM") }}
                    </div>
                  </div>
                  <ul class="body">
                    <li
                      v-for="(item, key, index) in reportData.guestPrice.month"
                      :key="index"
                    >
                      <div class="caption">
                        {{ $t(`report.${key}`) }}
                      </div>
                      <div class="content">
                        {{ filterNumber(item) || "-" }}
                      </div>
                    </li>
                  </ul>
                </li>
                <li>
                  <div class="header">
                    <div class="header-title">
                      {{ $t("report.YY") }}
                    </div>
                  </div>
                  <ul class="body">
                    <li
                      v-for="(item, key, index) in reportData.guestPrice.year"
                      :key="index"
                    >
                      <div class="caption">
                        {{ $t(`report.${key}`) }}
                      </div>
                      <div class="content">
                        {{ filterNumber(item) || "-" }}
                      </div>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </li>
          <!-- 활성(Class) : active -->
          <li :class="reportViewIndexList.includes(2) && 'active'">
            <div class="subTitle" @click="onClickReportView(2)">
              <div class="title">
                {{ $t("dailyReport.subTitle.consignmentStatus") }}
              </div>
              <i />
            </div>
            <div class="info">
              <ul class="list">
                <li>
                  <div class="header">
                    <div class="header-title">
                      {{ $t("report.today") }}
                    </div>
                  </div>
                  <ul class="body">
                    <li
                      v-for="(item, key, index) in reportData
                        .consignmentSalesInfo.day"
                      :key="index"
                    >
                      <div class="caption">
                        {{ $t(`report.${key}`) }}
                      </div>
                      <div class="content">
                        {{ filterNumber(item) || "-" }}
                      </div>
                    </li>
                  </ul>
                </li>
                <li>
                  <div class="header">
                    <div class="header-title">
                      {{ $t("report.MM") }}
                    </div>
                  </div>
                  <ul class="body">
                    <li
                      v-for="(item, key, index) in reportData
                        .consignmentSalesInfo.month"
                      :key="index"
                    >
                      <div class="caption">
                        {{ $t(`report.${key}`) }}
                      </div>
                      <div class="content">
                        {{ filterNumber(item) || "-" }}
                      </div>
                    </li>
                  </ul>
                </li>

                <li>
                  <div class="header">
                    <div class="header-title">
                      {{ $t("report.YY") }}
                    </div>
                  </div>
                  <ul class="body">
                    <li
                      v-for="(item, key, index) in reportData
                        .consignmentSalesInfo.year"
                      :key="index"
                    >
                      <div class="caption">
                        {{ $t(`report.${key}`) }}
                      </div>
                      <div class="content">
                        {{ filterNumber(item) || "-" }}
                      </div>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </li>
          <!-- 활성(Class) : active -->
          <li :class="reportViewIndexList.includes(3) && 'active'">
            <div class="subTitle" @click="onClickReportView(3)">
              <div class="title">
                {{ $t("dailyReport.subTitle.visitStatus") }}
                <span>{{
                  $t("dailyReport.caption.visitStatus", [
                    visitTeam("DD"),
                    teamAvgPer("DD"),
                  ])
                }}</span>
              </div>
              <i />
            </div>
            <div class="info">
              <ul class="list">
                <li>
                  <div class="header">
                    <div class="header-title">
                      {{ $t("report.today") }}
                    </div>
                  </div>
                </li>
                <li>
                  <div class="header">
                    <div class="header-title">
                      {{ $t("report.visitTeamCount") }}
                    </div>
                  </div>
                  <ul class="body">
                    <li
                      v-for="(item, key, index) in reportData.visitStatus
                        .dayVisit.team"
                      :key="index"
                    >
                      <div class="caption">
                        {{ $t(`report.${key}`) }}
                      </div>
                      <div class="content">
                        {{ filterNumber(item) || "-" }}
                      </div>
                    </li>
                  </ul>
                </li>
                <li>
                  <div class="header">
                    <div class="header-title">
                      {{ $t("report.visitCount") }}
                    </div>
                  </div>
                  <ul class="body">
                    <li
                      v-for="(item, key, index) in reportData.visitStatus
                        .dayVisit.per"
                      :key="index"
                    >
                      <div class="caption">
                        {{ $t(`report.${key}`) }}
                      </div>
                      <div class="content">
                        {{ filterNumber(item) || "-" }}
                      </div>
                    </li>
                  </ul>
                </li>
                <li>
                  <div class="header">
                    <div class="header-title">
                      {{ $t("report.MM") }}
                    </div>
                  </div>
                </li>
                <li>
                  <div class="header">
                    <div class="header-title">
                      {{ $t("report.visitTeamCount") }}
                    </div>
                  </div>
                  <ul class="body">
                    <li
                      v-for="(item, key, index) in reportData.visitStatus
                        .monthVisit.team"
                      :key="index"
                    >
                      <div class="caption">
                        {{ $t(`report.${key}`) }}
                      </div>
                      <div class="content">
                        {{ filterNumber(item) || "-" }}
                      </div>
                    </li>
                  </ul>
                </li>
                <li>
                  <div class="header">
                    <div class="header-title">
                      {{ $t("report.visitCount") }}
                    </div>
                  </div>
                  <ul class="body">
                    <li
                      v-for="(item, key, index) in reportData.visitStatus
                        .monthVisit.per"
                      :key="index"
                    >
                      <div class="caption">
                        {{ $t(`report.${key}`) }}
                      </div>
                      <div class="content">
                        {{ filterNumber(item) || "-" }}
                      </div>
                    </li>
                  </ul>
                </li>
                <li>
                  <div class="header">
                    <div class="header-title">
                      {{ $t("report.YY") }}
                    </div>
                  </div>
                </li>
                <li>
                  <div class="header">
                    <div class="header-title">
                      {{ $t("report.visitTeamCount") }}
                    </div>
                  </div>
                  <ul class="body">
                    <li
                      v-for="(item, key, index) in reportData.visitStatus
                        .yearVisit.team"
                      :key="index"
                    >
                      <div class="caption">
                        {{ $t(`report.${key}`) }}
                      </div>
                      <div class="content">
                        {{ filterNumber(item) || "-" }}
                      </div>
                    </li>
                  </ul>
                </li>
                <li>
                  <div class="header">
                    <div class="header-title">
                      {{ $t("report.visitCount") }}
                    </div>
                  </div>
                  <ul class="body">
                    <li
                      v-for="(item, key, index) in reportData.visitStatus
                        .yearVisit.per"
                      :key="index"
                    >
                      <div class="caption">
                        {{ $t(`report.${key}`) }}
                      </div>
                      <div class="content">
                        {{ filterNumber(item) || "-" }}
                      </div>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </li>
          <!-- 활성(Class) : active -->
          <li :class="reportViewIndexList.includes(4) && 'active'">
            <div class="subTitle" @click="onClickReportView(4)">
              <div class="title">
                {{ $t("dailyReport.subTitle.reserveStatus") }}
              </div>
              <i />
            </div>
            <div class="info">
              <ul class="list">
                <li
                  v-for="(
                    item, index
                  ) in reportData.weeklyReservationStatusList"
                  :key="index"
                >
                  <div class="header">
                    <div class="header-title">
                      {{ item.bsnDate }}({{ item.dwName }})
                    </div>
                  </div>
                  <ul class="body">
                    <li>
                      <div class="caption">
                        {{ $t("report.positive") }}
                      </div>
                      <div class="content">
                        {{ filterNumber(item.resveAbleTeam) || "-" }}
                      </div>
                    </li>
                    <li>
                      <div class="caption">
                        {{ $t("report.reservationTeam") }}
                      </div>
                      <div class="content">
                        {{ filterNumber(item.resveTeam) || "-" }}
                      </div>
                    </li>
                    <li>
                      <div class="caption">
                        {{ $t("report.reservationRate") }}
                      </div>
                      <div class="content">
                        {{
                          filterNumber(
                            (item.resveRate || 0).toFixed(0),
                            " %",
                            false
                          )
                        }}
                      </div>
                    </li>
                    <li>
                      <div class="caption">
                        {{ $t("report.normal") }}
                      </div>
                      <div class="content">
                        {{ filterNumber(item.resveFitTeam) || "-" }}
                      </div>
                    </li>
                    <li>
                      <div class="caption">
                        {{ $t("report.groupForKorean") }}
                      </div>
                      <div class="content">
                        {{ filterNumber(item.resveGrpTeam) || "-" }}
                      </div>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </li>
          <!-- 활성(Class) : active -->
          <li :class="reportViewIndexList.includes(5) && 'active'">
            <div class="subTitle" @click="onClickReportView(5)">
              <div class="title">
                {{ $t("dailyReport.subTitle.signUpStatus") }}
              </div>
              <i />
            </div>
            <div class="info">
              <ul class="list">
                <li>
                  <ul class="body">
                    <li>
                      <div class="caption">
                        {{ $t("report.DD") }}
                      </div>
                      <div class="content">
                        {{
                          filterNumber(
                            getWebMemberReportInfo("dayMemberCnt")
                          ) || "-"
                        }}
                      </div>
                    </li>
                    <li>
                      <div class="caption">
                        {{ $t("report.MM") }}
                      </div>
                      <div class="content">
                        {{
                          filterNumber(
                            getWebMemberReportInfo("monMemberCnt")
                          ) || "-"
                        }}
                      </div>
                    </li>
                    <li>
                      <div class="caption">
                        {{ $t("report.cumulative") }}
                      </div>
                      <div class="content">
                        {{
                          filterNumber(
                            getWebMemberReportInfo("totMemberCnt")
                          ) || "-"
                        }}
                      </div>
                    </li>
                    <li>
                      <div class="caption">
                        {{ $t("report.localResidents") }}
                      </div>
                      <div class="content">
                        {{
                          filterNumber(
                            getWebMemberReportInfo("areaMemberCnt")
                          ) || "-"
                        }}
                      </div>
                    </li>
                    <li>
                      <div class="caption">
                        {{ $t("report.importance") }}
                      </div>
                      <div class="content">
                        {{
                          filterNumber(
                            getWebMemberReportInfo("areaMemberRate"),
                            " %"
                          ) || "-"
                        }}
                      </div>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <!--    <div-->
    <!--      id='reportView'-->
    <!--      class='body-content report'-->
    <!--    >-->
    <!--      <div class='content-main'>-->
    <!--        <div class='title'>-->
    <!--          {{ $t('dailyReport.title') }}-->
    <!--        </div>-->
    <!--        <div class='caption'>-->
    <!--          {{ currentDate_YYYY_MM_DD }} {{ currentDate_ddd }}-->
    <!--        </div>-->
    <!--      </div>-->
    <!--      <div class='content-sub'>-->
    <!--        <ul class='report'>-->
    <!--          <li>-->
    <!--            <div class='subTitle'>-->
    <!--              <div class='title'>-->
    <!--                {{ $t('dailyReport.subTitle.salesStatus') }}-->
    <!--              </div>-->
    <!--              <div class='caption'>-->
    <!--                {{ reportViewCaption }}-->
    <!--              </div>-->
    <!--            </div>-->
    <!--            <div class='subTable'>-->
    <!--              <div class='table'>-->
    <!--                <table>-->
    <!--                  <colgroup>-->
    <!--                    <col style='width: 12%;' />-->
    <!--                    <col />-->
    <!--                    <col />-->
    <!--                    <col />-->
    <!--                  </colgroup>-->
    <!--                  <thead>-->
    <!--                  <tr>-->
    <!--                    <th scope='col'>-->
    <!--                      {{ $t('report.division') }}-->
    <!--                    </th>-->
    <!--                    <th>-->
    <!--                      {{ $t('report.totalWithSpace') }}-->
    <!--                    </th>-->
    <!--                    <th>-->
    <!--                      {{ $t('report.fee') }}-->
    <!--                    </th>-->
    <!--                    <th>-->
    <!--                      {{ $t('report.cart') }}-->
    <!--                    </th>-->
    <!--                  </tr>-->
    <!--                  </thead>-->
    <!--                  <tbody>-->
    <!--                  <tr v-for='(item, index) in reportData.closeAcmtlSalesList' :key='index'>-->
    <!--                    <th>-->
    <!--                      {{ item.acmtlSumDivName }}-->
    <!--                    </th>-->
    <!--                    <td :class='[ item.acmtlSumDiv === "DD" && "highlight" ]'>-->
    <!--                      {{ filterNumber(item.totAmt) }}-->
    <!--                    </td>-->
    <!--                    <td>-->
    <!--                      {{ filterNumber(item.feeSalesAmt) }}-->
    <!--                    </td>-->
    <!--                    <td>-->
    <!--                      {{ filterNumber(item.cartSalesAmt) }}-->
    <!--                    </td>-->
    <!--                  </tr>-->
    <!--                  </tbody>-->
    <!--                </table>-->
    <!--                <table>-->
    <!--                  <colgroup>-->
    <!--                    <col style='width: 12%;' />-->
    <!--                    <col />-->
    <!--                    <col />-->
    <!--                    <col />-->
    <!--                  </colgroup>-->
    <!--                  <thead>-->
    <!--                  <tr>-->
    <!--                    <th rowspan='2'>-->
    <!--                      {{ $t('report.division') }}-->
    <!--                    </th>-->
    <!--                    <th rowspan='2'>-->
    <!--                      {{ $t('report.rent') }}-->
    <!--                    </th>-->
    <!--                    <th colspan='2'>-->
    <!--                      {{ $t('report.feesSales') }}-->
    <!--                    </th>-->
    <!--                  </tr>-->
    <!--                  <tr>-->
    <!--                    <th class='borderLeft'>-->
    <!--                      {{ $t('report.food') }}-->
    <!--                    </th>-->
    <!--                    <th>-->
    <!--                      {{ $t('report.proShop') }}-->
    <!--                    </th>-->
    <!--                  </tr>-->
    <!--                  </thead>-->
    <!--                  <tbody>-->
    <!--                  <tr v-for='(item, index) in reportData.closeAcmtlSalesList' :key='index'>-->
    <!--                    <th>{{ item.acmtlSumDivName }}</th>-->
    <!--                    <td>{{ filterNumber(item.rentSalesAmt) }}</td>-->
    <!--                    <td>{{ filterNumber(item.foodSalesAmt) }}</td>-->
    <!--                    <td>{{ filterNumber(item.shopSalesAmt) }}</td>-->
    <!--                  </tr>-->
    <!--                  </tbody>-->
    <!--                </table>-->
    <!--              </div>-->
    <!--            </div>-->
    <!--          </li>-->
    <!--          <li>-->
    <!--            <div class='subTitle'>-->
    <!--              <div class='title'>-->
    <!--                {{ $t('dailyReport.subTitle.guestPriceStatus') }}-->
    <!--              </div>-->
    <!--            </div>-->
    <!--            <div class='subTable'>-->
    <!--              <div class='table'>-->
    <!--                <table>-->
    <!--                  <colgroup>-->
    <!--                    <col />-->
    <!--                    <col />-->
    <!--                    <col />-->
    <!--                    <col />-->
    <!--                  </colgroup>-->
    <!--                  <thead>-->
    <!--                  <tr>-->
    <!--                    <th>-->
    <!--                      {{ $t('report.division') }}-->
    <!--                    </th>-->
    <!--                    <th>-->
    <!--                      {{ $t('report.DD') }}-->
    <!--                    </th>-->
    <!--                    <th>-->
    <!--                      {{ $t('report.MM') }}-->
    <!--                    </th>-->
    <!--                    <th>-->
    <!--                      {{ $t('report.YY') }}-->
    <!--                    </th>-->
    <!--                  </tr>-->
    <!--                  </thead>-->
    <!--                  <tbody>-->
    <!--                  <tr v-for='(item, index) in reportData.closeAcmtlSalesUnitList' :key='index'>-->
    <!--                    <th v-html='item.divName' />-->
    <!--                    <td :class='[ item.isGreenFee === "Y" && "highlight"]'>-->
    <!--                      {{ filterNumber(item.guestPriceLabel, $t('common.won', [])) }}-->
    <!--                    </td>-->
    <!--                    <td>-->
    <!--                      {{ filterNumber(item.foodGuestPrice, $t('common.won', [])) }}-->
    <!--                    </td>-->
    <!--                    <td>-->
    <!--                      {{ filterNumber(item.proShopGuestPrice, $t('common.won', [])) }}-->
    <!--                    </td>-->
    <!--                  </tr>-->
    <!--                  </tbody>-->
    <!--                </table>-->
    <!--              </div>-->
    <!--            </div>-->
    <!--          </li>-->
    <!--          <li>-->
    <!--            <div class='subTitle'>-->
    <!--              <div class='title'>-->
    <!--                {{ $t('dailyReport.subTitle.consignmentStatus') }}-->
    <!--              </div>-->
    <!--            </div>-->
    <!--            <div class='subTable'>-->
    <!--              <div class='table'>-->
    <!--                <table>-->
    <!--                  <colgroup>-->
    <!--                    <col />-->
    <!--                    <col />-->
    <!--                    <col />-->
    <!--                    <col />-->
    <!--                  </colgroup>-->
    <!--                  <thead>-->
    <!--                  <tr>-->
    <!--                    <th>-->
    <!--                      {{ $t('report.division') }}-->
    <!--                    </th>-->
    <!--                    <th>-->
    <!--                      {{ $t('report.totalSales') }}-->
    <!--                    </th>-->
    <!--                    <th>-->
    <!--                      {{ $t('report.food') }}-->
    <!--                    </th>-->
    <!--                    <th>-->
    <!--                      {{ $t('report.proShop') }}-->
    <!--                    </th>-->
    <!--                  </tr>-->
    <!--                  </thead>-->
    <!--                  <tbody>-->
    <!--                  <tr>-->
    <!--                    <th>-->
    <!--                      {{ $t('report.DD') }}-->
    <!--                    </th>-->
    <!--                    <td class='highlight'>-->
    <!--                      {{ filterNumber(reportData.consignmentSalesInfo.ddTotAmt) }}-->
    <!--                    </td>-->
    <!--                    <td>-->
    <!--                      {{ filterNumber(reportData.consignmentSalesInfo.ddFoodAmt) }}-->
    <!--                    </td>-->
    <!--                    <td>-->
    <!--                      {{ filterNumber(reportData.consignmentSalesInfo.ddShopAmt) }}-->
    <!--                    </td>-->
    <!--                  </tr>-->
    <!--                  <tr>-->
    <!--                    <th>-->
    <!--                      {{ $t('report.MM') }}-->
    <!--                    </th>-->
    <!--                    <td>-->
    <!--                      {{ filterNumber(reportData.consignmentSalesInfo.mmTotAmt) }}-->
    <!--                    </td>-->
    <!--                    <td>-->
    <!--                      {{ filterNumber(reportData.consignmentSalesInfo.mmFoodAmt) }}-->
    <!--                    </td>-->
    <!--                    <td>-->
    <!--                      {{ filterNumber(reportData.consignmentSalesInfo.mmShopAmt) }}-->
    <!--                    </td>-->
    <!--                  </tr>-->
    <!--                  <tr>-->
    <!--                    <th>-->
    <!--                      {{ $t('report.YY') }}-->
    <!--                    </th>-->
    <!--                    <td>-->
    <!--                      {{ filterNumber(reportData.consignmentSalesInfo.yyTotAmt) }}-->
    <!--                    </td>-->
    <!--                    <td>-->
    <!--                      {{ filterNumber(reportData.consignmentSalesInfo.yyFoodAmt) }}-->
    <!--                    </td>-->
    <!--                    <td>-->
    <!--                      {{ filterNumber(reportData.consignmentSalesInfo.yyShopAmt) }}-->
    <!--                    </td>-->
    <!--                  </tr>-->
    <!--                  </tbody>-->
    <!--                </table>-->
    <!--              </div>-->
    <!--            </div>-->
    <!--          </li>-->
    <!--          <li>-->
    <!--            <div class='subTitle'>-->
    <!--              <div class='title'>-->
    <!--                {{ $t('dailyReport.subTitle.visitStatus') }}-->
    <!--              </div>-->
    <!--              <div class='caption'>-->
    <!--                {{ $t('dailyReport.caption.visitStatus', [visitTeam('DD'), teamAvgPer('DD')]) }}-->
    <!--              </div>-->
    <!--            </div>-->
    <!--            <div class='subTable'>-->
    <!--              <div class='table'>-->
    <!--                <table>-->
    <!--                  <colgroup>-->
    <!--                    <col />-->
    <!--                    <col />-->
    <!--                    <col />-->
    <!--                    <col />-->
    <!--                    <col />-->
    <!--                    <col />-->
    <!--                    <col />-->
    <!--                  </colgroup>-->
    <!--                  <thead>-->
    <!--                  <tr>-->
    <!--                    <th rowspan='2'>-->
    <!--                      {{ $t('report.visitor') }}-->
    <!--                    </th>-->
    <!--                    <th colspan='3'>-->
    <!--                      {{ $t('report.today') }}-->
    <!--                    </th>-->
    <!--                    <th rowspan='2'>-->
    <!--                      {{ $t('report.DD') }}-->
    <!--                    </th>-->
    <!--                    <th rowspan='2'>-->
    <!--                      {{ $t('report.MM') }}-->
    <!--                    </th>-->
    <!--                    <th rowspan='2'>-->
    <!--                      {{ $t('report.YY') }}-->
    <!--                    </th>-->
    <!--                  </tr>-->
    <!--                  <tr>-->
    <!--                    <th class='borderLeft'>-->
    <!--                      {{ $t('report.9hole') }}-->
    <!--                    </th>-->
    <!--                    <th>-->
    <!--                      {{ $t('report.18hole') }}-->
    <!--                    </th>-->
    <!--                    <th>-->
    <!--                      {{ $t('report.9hole+') }}-->
    <!--                    </th>-->
    <!--                  </tr>-->
    <!--                  </thead>-->
    <!--                  <tbody>-->
    <!--                  <tr v-for='(item, index) in reportData.closePaymtList' :key='index'>-->
    <!--                    <th>-->
    <!--                      {{ item.paymtCodeName }}-->
    <!--                    </th>-->
    <!--                    <td>-->
    <!--                      {{ filterNumber(item.visit9hPer) }}-->
    <!--                    </td>-->
    <!--                    <td>-->
    <!--                      {{ filterNumber(item.visit18hPer) }}-->
    <!--                    </td>-->
    <!--                    <td>-->
    <!--                      {{ filterNumber(item.visit9hAditPer) }}-->
    <!--                    </td>-->
    <!--                    <td :class='[item.paymtCode === "DAY" && "highlight"]'>-->
    <!--                      {{ filterNumber(item.visitTotPer) }}-->
    <!--                    </td>-->
    <!--                    <td :class='[item.paymtCode === "MONTH" && "highlight"]'>-->
    <!--                      {{ filterNumber(item.visitMonthPer) }}-->
    <!--                    </td>-->
    <!--                    <td :class='[item.paymtCode === "YEAR" && "highlight"]'>-->
    <!--                      {{ filterNumber(item.visitYearPer) }}-->
    <!--                    </td>-->
    <!--                  </tr>-->
    <!--                  </tbody>-->
    <!--                </table>-->
    <!--              </div>-->
    <!--            </div>-->
    <!--          </li>-->
    <!--          <li class='reservationStatus'>-->
    <!--            <div class='subTitle'>-->
    <!--              <div class='title'>-->
    <!--                {{ $t('dailyReport.subTitle.reserveStatus') }}-->
    <!--              </div>-->
    <!--            </div>-->
    <!--            <div class='subTable'>-->
    <!--              <div class='table'>-->
    <!--                <table>-->
    <!--                  <colgroup>-->
    <!--                    <col />-->
    <!--                    <col />-->
    <!--                    <col />-->
    <!--                    <col />-->
    <!--                    <col />-->
    <!--                    <col />-->
    <!--                  </colgroup>-->
    <!--                  <thead>-->
    <!--                  <tr>-->
    <!--                    <th>-->
    <!--                      {{ $t('report.date') }}-->
    <!--                    </th>-->
    <!--                    <th>-->
    <!--                      {{ $t('report.positive') }}-->
    <!--                    </th>-->
    <!--                    <th>-->
    <!--                      {{ $t('report.reservationTeam') }}-->
    <!--                    </th>-->
    <!--                    <th>-->
    <!--                      {{ $t('report.reservationRate') }}-->
    <!--                    </th>-->
    <!--                    <th>-->
    <!--                      {{ $t('report.normal') }}-->
    <!--                    </th>-->
    <!--                    <th>-->
    <!--                      {{ $t('report.groupForKorean') }}-->
    <!--                    </th>-->
    <!--                  </tr>-->
    <!--                  </thead>-->
    <!--                  <tbody>-->
    <!--                  <tr v-for='(item, index) in reportData.weeklyReservationStatusList' :key='index'>-->
    <!--                    <th>-->
    <!--                      {{ item.bsnDate }}({{ item.dwName }})-->
    <!--                    </th>-->
    <!--                    <td>-->
    <!--                      {{ filterNumber(item.resveAbleTeam) }}-->
    <!--                    </td>-->
    <!--                    <td>-->
    <!--                      {{ filterNumber(item.resveTeam) }}-->
    <!--                    </td>-->
    <!--                    <td>-->
    <!--                      {{ filterNumber((item.resveRate || 0).toFixed(0), ' %', false) }}-->
    <!--                    </td>-->
    <!--                    <td>-->
    <!--                      {{ filterNumber(item.resveFitTeam) }}-->
    <!--                    </td>-->
    <!--                    <td>-->
    <!--                      {{ filterNumber(item.resveGrpTeam) }}-->
    <!--                    </td>-->
    <!--                  </tr>-->
    <!--                  </tbody>-->
    <!--                </table>-->
    <!--              </div>-->
    <!--            </div>-->
    <!--          </li>-->
    <!--          <li>-->
    <!--            <div class='subTitle'>-->
    <!--              <div class='title'>-->
    <!--                {{ $t('dailyReport.subTitle.signUpStatus') }}-->
    <!--              </div>-->
    <!--            </div>-->
    <!--            <div class='subTable'>-->
    <!--              <div class='table'>-->
    <!--                <table>-->
    <!--                  <colgroup>-->
    <!--                    <col />-->
    <!--                    <col />-->
    <!--                    <col />-->
    <!--                    <col />-->
    <!--                    <col />-->
    <!--                  </colgroup>-->
    <!--                  <thead>-->
    <!--                  <tr>-->
    <!--                    <th>-->
    <!--                      {{ $t('report.DD') }}-->
    <!--                    </th>-->
    <!--                    <th>-->
    <!--                      {{ $t('report.MM') }}-->
    <!--                    </th>-->
    <!--                    <th>-->
    <!--                      {{ $t('report.cumulative') }}-->
    <!--                    </th>-->
    <!--                    <th>-->
    <!--                      {{ $t('report.localResidents') }}-->
    <!--                    </th>-->
    <!--                    <th>-->
    <!--                      {{ $t('report.importance') }}-->
    <!--                    </th>-->
    <!--                  </tr>-->
    <!--                  </thead>-->
    <!--                  <tbody>-->
    <!--                  <tr>-->
    <!--                    <td>-->
    <!--                      {{ filterNumber(getWebMemberReportInfo('dayMemberCnt'), $t('common.people', []), false) }}-->
    <!--                    </td>-->
    <!--                    <td>-->
    <!--                      {{ filterNumber(getWebMemberReportInfo('monMemberCnt'), $t('common.people', []), false) }}-->
    <!--                    </td>-->
    <!--                    <td class='highlight'>-->
    <!--                      {{ filterNumber(getWebMemberReportInfo('totMemberCnt'), $t('common.people', []), false) }}-->
    <!--                    </td>-->
    <!--                    <td>-->
    <!--                      {{ filterNumber(getWebMemberReportInfo('areaMemberCnt'), $t('common.people', []), false) }}-->
    <!--                    </td>-->
    <!--                    <td>-->
    <!--                      {{ filterNumber(getWebMemberReportInfo('areaMemberRate'), ' %', false) }}-->
    <!--                    </td>-->
    <!--                  </tr>-->
    <!--                  </tbody>-->
    <!--                </table>-->
    <!--              </div>-->
    <!--            </div>-->
    <!--          </li>-->
    <!--          <li class='groupSignUpStatus'>-->
    <!--            <div class='subTitle'>-->
    <!--              <div class='title'>-->
    <!--                {{ $t('dailyReport.subTitle.teamSignUpStatus') }}-->
    <!--              </div>-->
    <!--            </div>-->
    <!--            <div class='subTable'>-->
    <!--              <div class='table'>-->
    <!--                <table>-->
    <!--                  <colgroup>-->
    <!--                    <col />-->
    <!--                    <col />-->
    <!--                  </colgroup>-->
    <!--                  <thead>-->
    <!--                  <tr>-->
    <!--                    <th>-->
    <!--                      {{ $t('dailyReport.caption.teamSignUpStatus.total') }}-->
    <!--                    </th>-->
    <!--                    <th>-->
    <!--                      {{ $t('dailyReport.caption.teamSignUpStatus.monthly') }}-->
    <!--                    </th>-->
    <!--                  </tr>-->
    <!--                  </thead>-->
    <!--                  <tbody>-->
    <!--                  <tr>-->
    <!--                    <td>-->
    <!--                      {{ filterNumber(reportData.yearGrpReportInfo.yearGrpCnt, ` ${$t('report.groupForKorean')}`, false)-->
    <!--                      }}-->
    <!--                    </td>-->
    <!--                    <td>-->
    <!--                      {{ filterNumber(reportData.yearGrpReportInfo.yearGrpBookgCnt, ` ${$t('report.team')}`, false) }}-->
    <!--                    </td>-->
    <!--                  </tr>-->
    <!--                  </tbody>-->
    <!--                </table>-->
    <!--              </div>-->
    <!--            </div>-->
    <!--          </li>-->
    <!--          <li>-->
    <!--            <div class="remarks">-->
    <!--              <div class="title">-->
    <!--                {{ $t('dailyReport.subTitle.depositMemberStatus')}}-->
    <!--              </div>-->
    <!--              <div class="content">-->
    <!--                <div class="item">-->
    <!--                  <p v-for="(item, idx) in reportData.depositMemberStatus" :key="idx">-->
    <!--                    {{ item }}-->
    <!--                  </p>-->
    <!--                </div>-->
    <!--              </div>-->
    <!--            </div>-->
    <!--          </li>-->
    <!--          <li>-->
    <!--            <div class='remarks'>-->
    <!--              <div class='title'>-->
    <!--                {{ $t('dailyReport.subTitle.remarks') }}-->
    <!--              </div>-->
    <!--              <div class='content'>-->
    <!--                <div class='item'>-->
    <!--                  {{ reportData.remarks }}-->
    <!--                </div>-->
    <!--              </div>-->
    <!--            </div>-->
    <!--          </li>-->
    <!--        </ul>-->
    <!--      </div>-->
    <!--    </div>-->
  </div>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";
import { toPng } from "html-to-image";
import { DATE_FORMAT_YYYY_MM_DD } from "@/utils/Date";
import { uploadImage } from "@/utils/FormUtil";
import { numberWithCommas } from "@/utils/Number";
import DatePicker from "@/components/DatePicker";
import GolfErpAPI from "@/api/GolfErpAPI";
import { sumVal } from "@/utils/ObjectUtil";

export default {
  name: "DailyReport",
  components: { DatePicker },
  data() {
    return {
      currentDate: moment(),
      reportData: { monthlyBsnDateInfo: { weekdayBsnDay: null } },
      reportViewIndexList: [0],
      salesStatusDataList: {},
    };
  },
  filters: { numberWithCommas },
  async mounted() {
    await this.fetch();
    await this.$nextTick();
    this.$refs.datePicker.setDate(
      this.currentDate.format(DATE_FORMAT_YYYY_MM_DD)
    );
  },
  computed: {
    ...mapGetters(["reportMode", "darkMode"]),
    pureCurrentDate() {
      return moment(this.currentDate).locale("ko");
    },
    currentDate_YYYY_MM_DD() {
      return this.pureCurrentDate.format("YYYY.MM.DD");
    },
    currentDate_M() {
      return this.pureCurrentDate.format("M");
    },
    currentDate_ddd() {
      return this.pureCurrentDate.format("(ddd)");
    },
    reportViewCaption() {
      return this.$t(`dailyReport.caption.salesStatus`, [
        this.currentDate_M,
        this.reportData.monthlyBsnDateInfo.weekdayBsnDay,
        this.reportData.monthlyBsnDateInfo.weekendBsnDay,
        this.reportData.monthlyBsnDateInfo.totBsnDay,
      ]);
    },
    // isReportMode() {
    //   return this.reportMode === 'Y';
    // },
    isDarkMode() {
      return this.darkMode === "Y";
    },
  },
  methods: {
    // ...mapActions(['changeReportMode']),
    // async onClickReportMode() {
    //   await this.changeReportMode({
    //     reportMode: this.isReportMode ? 'N' : 'Y'
    //   });
    // },
    onClickReportView(index) {
      this.reportViewIndexList.includes(index)
        ? (this.reportViewIndexList = this.reportViewIndexList?.filter(
            (viewIndex) => viewIndex !== index
          ))
        : this.reportViewIndexList.push(index);
    },

    download() {
      const self = this;
      const node = document.getElementById("reportView");
      this.$EventBus.$emit("loaderOn");
      toPng(node, {
        backgroundColor: this.isDarkMode ? "#181a20" : "#eef2f5",
      })
        .then(async (dataUrl) => {
          const data = await uploadImage(
            null,
            "MIS_DAILY_REPORT",
            dataUrl,
            "report.png"
          );
          data
            ? self.sendKakao(data.imageURL)
            : this.$vs.notification({
                icon: '<i class="bx bxs-error"/>',
                color: "danger",
                title: this.$t("common.error"),
                text: `${this.$t(
                  "errorMessage.imageUploadFailed"
                )}<br>ERROR : ${data || "NULL"}`,
              });
        })
        .catch((err) => alert(err))
        .finally(() => this.$EventBus.$emit("loaderOff"));
    },
    getCloseAcmtlSales(acmtlSumDiv) {
      return this.reportData?.closeAcmtlSalesList?.find(
        (item) => item.acmtlSumDiv === acmtlSumDiv
      );
    },

    getVisitTeamCntAndPerTeamList(acmtlSumDiv) {
      return this.reportData?.visitTeamCntAndPerTeamList?.find(
        (item) => item.acmtlSumDiv === acmtlSumDiv
      );
    },
    getWebMemberReportInfo(key) {
      return this.reportData?.webMemberReportInfo?.[key];
    },
    feeCartRentSalesAmt(acmtlSumDiv) {
      return (
        Math.floor(
          this.getCloseAcmtlSales(acmtlSumDiv)?.feeSalesAmt +
            this.getCloseAcmtlSales(acmtlSumDiv)?.cartSalesAmt +
            this.getCloseAcmtlSales(acmtlSumDiv)?.rentSalesAmt || 0
        ) / 1000
      );
    },
    feeRentCartSalesUnitPrice(acmtlSumDiv) {
      return (
        Math.floor(
          this.getCloseAcmtlSales(acmtlSumDiv)?.feeRentCartSalesUnitPrice || 0
        ) / 1000
      );
    },
    foodSalesUnitPrice(acmtlSumDiv) {
      return Math.floor(
        this.getCloseAcmtlSales(acmtlSumDiv)?.foodSalesUnitPrice || 0
      );
    },
    shopSalesUnitPrice(acmtlSumDiv) {
      return Math.floor(
        this.getCloseAcmtlSales(acmtlSumDiv)?.shopSalesUnitPrice || 0
      );
    },
    feeSalesAmt(acmtlSumDiv) {
      return Math.floor(this.getCloseAcmtlSales(acmtlSumDiv)?.feeSalesAmt || 0);
    },
    rentSalesAmt(acmtlSumDiv) {
      return Math.floor(
        this.getCloseAcmtlSales(acmtlSumDiv)?.rentSalesAmt || 0
      );
    },
    cartSalesAmt(acmtlSumDiv) {
      return Math.floor(
        this.getCloseAcmtlSales(acmtlSumDiv)?.cartSalesAmt || 0
      );
    },
    foodSalesAmt(acmtlSumDiv) {
      return Math.floor(
        this.getCloseAcmtlSales(acmtlSumDiv)?.foodSalesAmt || 0
      );
    },
    shopSalesAmt(acmtlSumDiv) {
      return Math.floor(
        this.getCloseAcmtlSales(acmtlSumDiv)?.shopSalesAmt || 0
      );
    },
    visitTeam(acmtlSumDiv) {
      return Math.floor(
        this.getVisitTeamCntAndPerTeamList(acmtlSumDiv)?.visitTeam || 0
      );
    },
    teamAvgPer(acmtlSumDiv) {
      return Math.floor(
        this.getVisitTeamCntAndPerTeamList(acmtlSumDiv)?.teamAvgPer || 0
      );
    },
    filterNumber(value, suffix = "", toEmpty = true) {
      return value > 0 || !toEmpty ? `${numberWithCommas(value)}${suffix}` : "";
    },
    async goToDay(to = "THIS") {
      switch (to) {
        case "PREV":
          this.currentDate = moment(this.currentDate).add(-1, "days");
          break;
        case "NEXT":
          this.currentDate = moment(this.currentDate).add(1, "days");
          break;
        default:
          this.currentDate = moment();
          break;
      }
      await this.fetch();
    },
    sendKakao(imageURL) {
      const currentDate = moment(this.currentDate)
        .locale("ko")
        .format(this.$t("common.dateFormat"));
      const viewUrl = `https://${location.host}/report-view?url=${imageURL}`;
      this.$kakao.Link.sendDefault({
        objectType: "feed",
        content: {
          title: `${currentDate} ${this.$t("dailyReport.title")} ${this.$t(
            "dailyReport.report"
          )}`,
          description: `${this.$t("businessName")} - ${this.$t(
            "title"
          )} ${this.$t("dailyReport.title")} ${this.$t("dailyReport.report")}`,
          imageUrl: imageURL,
          link: { mobileWebUrl: viewUrl, webUrl: viewUrl },
        },
      });
    },
    onOpenDatePicker() {
      this.$refs.datePicker.openPicker();
    },
    async onSelectedDatePicker(date) {
      this.currentDate = moment(date);
      await this.fetch();
    },
    async fetch() {
      const bsnDate = moment(this.currentDate).format("YYYY-MM-DD");

      const { data: closeDiv } = await GolfErpAPI.getCloseDiv({
        bsnDate: bsnDate,
        storeCode: "FRONT",
      });
      if (closeDiv !== "Y") {
        const bsnDate = moment(this.currentDate).format("YYYYMMDD");
        const requestList = [
          "SP_CLOSE_PART_DIV",
          "SP_CLOSE_SALES_STORE",
          "SP_CLOSE_ACMTL_BASIC",
          "SP_CLOSE_ACMTL_SALES",
          "SP_CLOSE_CARD",
          "SP_CLOSE_PAYMT",
          "SP_CLOSE_SALES_FEE",
        ];
        const inParams = [bsnDate];
        let request = [];
        requestList?.map((item) => request.push({ name: item, inParams }));
        await GolfErpAPI.putStoredProcedureExecute({
          storedProcedureInfos: request,
        });
      }
      let data = await GolfErpAPI.getDailyReport({
        bsnDate: this.currentDate.format(DATE_FORMAT_YYYY_MM_DD),
        amtFlag: false,
      });

      const salesAmt = data.closeAcmtlSalesList;

      const daySalesGuestPrice = {};
      const monthSalesGuestPrice = {};
      const yearSalesGuestPrice = {};

      daySalesGuestPrice[`guestPriceLabel`] = salesAmt?.find(
        (item) => item.acmtlSumDiv === "DD"
      )?.feeRentCartSalesUnitPrice;
      daySalesGuestPrice[`foodGuestPrice`] = salesAmt?.find(
        (item) => item.acmtlSumDiv === "DD"
      )?.foodSalesUnitPrice;
      daySalesGuestPrice[`proShop`] = salesAmt?.find(
        (item) => item.acmtlSumDiv === "DD"
      )?.shopSalesUnitPrice;
      daySalesGuestPrice["totalWithSpace"] = sumVal(daySalesGuestPrice);
      // daySalesGuestPrice['divName'] = this.$t('report.guestPriceLabelWithNewLine');
      // daySalesGuestPrice['isGreenFee'] = 'Y';

      monthSalesGuestPrice[`guestPriceLabel`] = salesAmt?.find(
        (item) => item.acmtlSumDiv === "MM"
      )?.feeRentCartSalesUnitPrice;
      monthSalesGuestPrice[`foodGuestPrice`] = salesAmt?.find(
        (item) => item.acmtlSumDiv === "MM"
      )?.foodSalesUnitPrice;
      monthSalesGuestPrice[`proShop`] = salesAmt?.find(
        (item) => item.acmtlSumDiv === "MM"
      )?.shopSalesUnitPrice;
      monthSalesGuestPrice["totalWithSpace"] = sumVal(monthSalesGuestPrice);
      // monthSalesGuestPrice['divName'] = this.$t('report.foodGuestPrice');

      yearSalesGuestPrice[`guestPriceLabel`] = salesAmt?.find(
        (item) => item.acmtlSumDiv === "YY"
      )?.feeRentCartSalesUnitPrice;
      yearSalesGuestPrice[`foodGuestPrice`] = salesAmt?.find(
        (item) => item.acmtlSumDiv === "YY"
      )?.foodSalesUnitPrice;
      yearSalesGuestPrice[`proShop`] = salesAmt?.find(
        (item) => item.acmtlSumDiv === "YY"
      )?.shopSalesUnitPrice;
      yearSalesGuestPrice["totalWithSpace"] = sumVal(yearSalesGuestPrice);
      // yearSalesGuestPrice['divName'] = this.$t('report.proShopGuestPrice');

      const guestPrice = {
        day: daySalesGuestPrice,
        month: monthSalesGuestPrice,
        year: yearSalesGuestPrice,
      };

      const daySalesPrice = {};
      const monthSalesPrice = {};
      const yearSalesPrice = {};

      daySalesPrice[`fee`] = salesAmt?.find(
        (item) => item.acmtlSumDiv === "DD"
      )?.feeSalesAmt;
      daySalesPrice[`cart`] = salesAmt?.find(
        (item) => item.acmtlSumDiv === "DD"
      )?.cartSalesAmt;
      daySalesPrice[`rent`] = salesAmt?.find(
        (item) => item.acmtlSumDiv === "DD"
      )?.rentSalesAmt;
      daySalesPrice[`food`] = salesAmt?.find(
        (item) => item.acmtlSumDiv === "DD"
      )?.foodSalesAmt;
      daySalesPrice[`proShop`] = salesAmt?.find(
        (item) => item.acmtlSumDiv === "DD"
      )?.shopSalesAmt;
      daySalesPrice["totalWithSpace"] = sumVal(daySalesPrice);

      monthSalesPrice[`fee`] = salesAmt?.find(
        (item) => item.acmtlSumDiv === "MM"
      )?.feeSalesAmt;
      monthSalesPrice[`cart`] = salesAmt?.find(
        (item) => item.acmtlSumDiv === "MM"
      )?.cartSalesAmt;
      monthSalesPrice[`rent`] = salesAmt?.find(
        (item) => item.acmtlSumDiv === "MM"
      )?.rentSalesAmt;
      monthSalesPrice[`food`] = salesAmt?.find(
        (item) => item.acmtlSumDiv === "MM"
      )?.foodSalesAmt;
      monthSalesPrice[`proShop`] = salesAmt?.find(
        (item) => item.acmtlSumDiv === "MM"
      )?.shopSalesAmt;
      monthSalesPrice["totalWithSpace"] = sumVal(monthSalesPrice);

      yearSalesPrice[`fee`] = salesAmt?.find(
        (item) => item.acmtlSumDiv === "YY"
      )?.feeSalesAmt;
      yearSalesPrice[`cart`] = salesAmt?.find(
        (item) => item.acmtlSumDiv === "YY"
      )?.cartSalesAmt;
      yearSalesPrice[`rent`] = salesAmt?.find(
        (item) => item.acmtlSumDiv === "YY"
      )?.rentSalesAmt;
      yearSalesPrice[`food`] = salesAmt?.find(
        (item) => item.acmtlSumDiv === "YY"
      )?.foodSalesAmt;
      yearSalesPrice[`proShop`] = salesAmt?.find(
        (item) => item.acmtlSumDiv === "YY"
      )?.shopSalesAmt;
      yearSalesPrice["totalWithSpace"] = sumVal(yearSalesPrice);

      this.salesStatusDataList = {
        day: daySalesPrice,
        month: monthSalesPrice,
        year: yearSalesPrice,
      };

      // data.closeAcmtlSalesList?.map(item => {
      //   const pureNetData = data.closeAcmtlSalesList
      //     ?.find(i => i.acmtlSumDiv === item.acmtlSumDiv);
      //   item.totTaxAmt = pureNetData?.golfNetAmt;
      //   if (item.acmtlSumDiv === 'DD') {
      //     item.foodSalesAmt = data?.consignmentSalesReportInfoV2?.ddFeeFoodNorAmt || 0;
      //     item.shopSalesAmt = data?.consignmentSalesReportInfoV2?.ddFeeShopNorAmt || 0;
      //   }
      //   if (item.acmtlSumDiv === 'MM') {
      //     item.foodSalesAmt = data?.consignmentSalesReportInfoV2?.mmFeeFoodNorAmt || 0;
      //     item.shopSalesAmt = data?.consignmentSalesReportInfoV2?.mmFeeShopNorAmt || 0;
      //   }
      //   if (item.acmtlSumDiv === 'YY') {
      //     item.foodSalesAmt = data?.consignmentSalesReportInfoV2?.yyFeeFoodNorAmt || 0;
      //     item.shopSalesAmt = data?.consignmentSalesReportInfoV2?.yyFeeShopNorAmt || 0;
      //   }
      //   item.totAmt = item.feeSalesAmt + item.cartSalesAmt + item.rentSalesAmt + item.foodSalesAmt +
      //     item.shopSalesAmt;
      //   return item;
      // });

      const dayConsignment = {};
      const monthConsignment = {};
      const yearConsignment = {};

      dayConsignment["food"] =
        data?.consignmentSalesReportInfoV2?.ddFoodAmt || 0;
      dayConsignment["foodFee"] =
        data?.consignmentSalesReportInfoV2?.ddFeeFoodNorAmt || 0;
      dayConsignment["proShop"] =
        data?.consignmentSalesReportInfoV2?.ddShopAmt || 0;
      dayConsignment["proShopFee"] =
        data?.consignmentSalesReportInfoV2?.ddFeeShopNorAmt || 0;
      dayConsignment["totalWithSpace"] = sumVal(dayConsignment, [
        "foodFee",
        "proShopFee",
      ]);

      monthConsignment["food"] =
        data?.consignmentSalesReportInfoV2?.mmFoodAmt || 0;
      monthConsignment["foodFee"] =
        data?.consignmentSalesReportInfoV2?.mmFeeFoodNorAmt || 0;
      monthConsignment["proShop"] =
        data?.consignmentSalesReportInfoV2?.mmShopAmt || 0;
      monthConsignment["proShopFee"] =
        data?.consignmentSalesReportInfoV2?.mmFeeShopNorAmt || 0;
      monthConsignment["totalWithSpace"] = sumVal(monthConsignment, [
        "foodFee",
        "proShopFee",
      ]);

      yearConsignment["food"] =
        data?.consignmentSalesReportInfoV2?.yyFoodAmt || 0;
      yearConsignment["foodFee"] =
        data?.consignmentSalesReportInfoV2?.yyFeeFoodNorAmt || 0;
      yearConsignment["proShop"] =
        data?.consignmentSalesReportInfoV2?.yyShopAmt || 0;
      yearConsignment["proShopFee"] =
        data?.consignmentSalesReportInfoV2?.yyFeeShopNorAmt || 0;
      yearConsignment["totalWithSpace"] = sumVal(yearConsignment, [
        "foodFee",
        "proShopFee",
      ]);

      const consignmentSalesInfo = {
        day: dayConsignment,
        month: monthConsignment,
        year: yearConsignment,
      };

      const { visitPerInfo } = await GolfErpAPI.getDashboard({
        bsnDate: bsnDate,
        amtFlag: false,
      });
      const dayVisit = { team: {}, per: {} };
      const monthVisit = { team: {}, per: {} };
      const yearVisit = { team: {}, per: {} };

      dayVisit.team["18hole"] = visitPerInfo?.ddVisit18hTeam || 0;
      dayVisit.team["9hole"] = visitPerInfo?.ddVisit09hTeam || 0;
      dayVisit.team["totalWithSpace"] = sumVal(dayVisit.team);
      dayVisit.per["18hole"] = visitPerInfo?.ddVisit18hPer || 0;
      dayVisit.per["9hole"] = visitPerInfo?.ddVisit09hPer || 0;
      dayVisit.per["totalWithSpace"] = sumVal(dayVisit.per);

      monthVisit.team["18hole"] = visitPerInfo?.mmVisit18hTeam || 0;
      monthVisit.team["9hole"] = visitPerInfo?.mmVisit09hTeam || 0;
      monthVisit.team["totalWithSpace"] = sumVal(monthVisit.team);
      monthVisit.per["18hole"] = visitPerInfo?.mmVisit18hPer || 0;
      monthVisit.per["9hole"] = visitPerInfo?.mmVisit09hPer || 0;
      monthVisit.per["totalWithSpace"] = sumVal(monthVisit.per);

      yearVisit.team["18hole"] = visitPerInfo?.yyVisit18hTeam || 0;
      yearVisit.team["9hole"] = visitPerInfo?.yyVisit09hTeam || 0;
      yearVisit.team["totalWithSpace"] = sumVal(yearVisit.team);
      yearVisit.per["18hole"] = visitPerInfo?.yyVisit18hPer || 0;
      yearVisit.per["9hole"] = visitPerInfo?.yyVisit09hPer || 0;
      yearVisit.per["totalWithSpace"] = sumVal(yearVisit.per);

      const visitStatus = {
        dayVisit,
        monthVisit,
        yearVisit,
      };

      let depositMemberStatus =
        "▷" +
        moment(this.currentDate)
          .locale("ko")
          .format("MM.DD(ddd) 예치금회원 가입현황") +
        "\n";
      const depositSumCount =
        data?.depositMemberStatus?.todayDepositCount -
        data?.depositMemberStatus?.yesterdayDepositCount;
      const depositAreaSumCount =
        data?.depositMemberStatus?.todayDepositAreaCount -
        data?.depositMemberStatus?.yesterdayDepositAreaCount;
      const totTodayCount =
        data?.depositMemberStatus?.todayDepositCount +
        data?.depositMemberStatus?.todayDepositAreaCount;
      const totYesterdayCount =
        data?.depositMemberStatus?.yesterdayDepositCount +
        data?.depositMemberStatus?.yesterdayDepositAreaCount;
      const totSumCount = totTodayCount - totYesterdayCount;
      depositMemberStatus +=
        "예치금(일반)" +
        " - " +
        numberWithCommas(data?.depositMemberStatus?.todayDepositCount) +
        "명" +
        "(" +
        numberWithCommas(Math.abs(depositSumCount)) +
        (depositSumCount > 0 ? " ↑ " : depositSumCount < 0 ? " ↓ " : "") +
        ")" +
        "\n";
      depositMemberStatus +=
        "예치금(지역)" +
        " - " +
        numberWithCommas(data?.depositMemberStatus?.todayDepositAreaCount) +
        "명" +
        "(" +
        numberWithCommas(Math.abs(depositAreaSumCount)) +
        (depositAreaSumCount > 0
          ? " ↑ "
          : depositAreaSumCount < 0
          ? " ↓ "
          : "") +
        ")" +
        "\n";
      depositMemberStatus +=
        "총 예치금 - " +
        numberWithCommas(totTodayCount) +
        "명" +
        "(" +
        Math.abs(totSumCount) +
        (totSumCount > 0 ? " ↑ " : totSumCount < 0 ? " ↓ " : "") +
        ")";
      depositMemberStatus = depositMemberStatus.split("\n");

      this.reportData = {
        ...data,
        consignmentSalesInfo,
        guestPrice,
        depositMemberStatus,
        visitStatus,
      };
    },
  },
};
</script>
