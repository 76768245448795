import CustomLoader from '@/components/CustomLoader';

export default {
  components: { CustomLoader },
  data() {
    return {
      loading: false
    };
  },
  async created() {
    this.$EventBus.$on('loaderOn', () => this.loaderOn());
    this.$EventBus.$on('loaderOff', () => this.loaderOff());
  },
  async beforeDestroy() {
    this.$EventBus.$off('loaderOn');
    this.$EventBus.$off('loaderOff');
  },
  methods: {
    loaderOn() {
      if (this.loading) return;
      this.loading = true;
    },
    loaderOff() {
      if (!this.loading) return;
      this.loading = false;
    }
  }
};
