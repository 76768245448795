import GolfErpAPI from '@/api/GolfErpAPI';

export function formPreventEnterEvent(event) {
  if (event.key === 'Enter') {
    event.preventDefault();
  }
}

export async function uploadImage(imageId, uploadDiv, dataUrl, name) {
  try {
    const imageUploadData = {
      imageFile: {
        path: dataUrl,
        name
      },
      uploadDiv: uploadDiv,
      imageId: imageId
    };
    return await GolfErpAPI.putImageUpload(imageUploadData);
  } catch (e) {
    return null;
  }
}
