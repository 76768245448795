const bizCodesObjectKey = 'Biz-Codes';
const bizCodeCurrentObjectKey = 'Current-Biz-Code';

export function setBizCodesObject(bizCodes) {
  sessionStorage.setItem(bizCodesObjectKey, JSON.stringify(bizCodes));
}

export function getCurrentBizCode() {
  return sessionStorage.getItem(bizCodeCurrentObjectKey);
}

export function setCurrentBizCode(currentBizCode) {
  sessionStorage.setItem(bizCodeCurrentObjectKey, currentBizCode);
}
