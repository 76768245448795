<template>
  <div :class='[
    "container",
    isWeeksCalendar ? "weeksCalendar" : "basicCalendar",
  ]'>
    <div class='body-header'>
      <div class='date'>
        <div
          class='prev'
          @click='goToMonth("PREV")'
        >
          <div class='item'>
            {{ $t('common.prev') }}
          </div>
        </div>
        <div class='title'>
          <div
            class='item'
            @click='onOpenDatePicker'
          >
            <strong class="custom-font">{{ currentDate_YYYY_MM }}</strong>
          </div>
          <date-picker
            ref='datePicker'
            @selected='onSelectedDatePicker'
          />
        </div>
        <div
          class='next'
          @click='goToMonth("NEXT")'
        >
          <div class='item'>
            {{ $t('common.next') }}
          </div>
        </div>
      </div>
      <div class='caption'>
        <div class='item'>
          ({{ $t('report.visitTeam') }})
        </div>
      </div>
      <div
        class='refresh'
        @click='fetch'
      >
        <div class='item'>
          {{ $t('common.refresh') }}
        </div>
      </div>
    </div>
    <div class='body-content'>
      <div class='content-main'>
        <calendar
          ref='calendar'
          :output='getOutput'
          :is-weeks-calendar='isWeeksCalendar'
          @selectedDate='onSelectedDate'
        />
      </div>
      <div class='content-sub'>
        <div
          class='sub-header'
        >
          <div class='header'>
            <div class='label'>
              ({{ $t('report.visitor') }})
            </div>
            <div class='title'>
              <strong class="custom-font">{{ currentDate_YYYY_MM_DD }}</strong>
              {{ currentDate_ddd }}
            </div>
          </div>
          <div class='body'>
            <div class='contain'>
              <span class="custom_obj">
                <ul class="custom_ul custom-font">
                  <li>
                    [팀수]
                  </li>
                  <li>내장팀 {{this.$t('common.team', [visitStatusList.visitTeamCount || ' - '])}}</li>
                  <li>정산팀 {{this.$t('common.team', [visitStatusList.teamCheckoutCount || ' - ']) }}</li>
                  <li>미정산 {{this.$t('common.team', [visitStatusList.teamNotCheckoutCount || ' - '])}}</li>
                </ul>
                <ul class="custom_ul custom-font">
                  <li>[내장객]</li>
                  <li>전체 {{this.$t('common.people', [visitStatusList.visitorCount || ' - '])}}</li>
                  <li>남자 {{this.$t('common.people', [visitStatusList.visitorMCount || ' - ']) }}</li>
                  <li>여자 {{this.$t('common.people', [visitStatusList.visitorFCount || ' - '])}}</li>
                </ul>
              </span>
            </div>
          </div>
        </div>
        <div class='sub-body'>
          <ul class='info'>
            <li>
              <div class='title'>
                내장객 LIST
              </div>
              <div class='contain'>
                <ol class='custom_ol' :class="index===0 && 'custom_border'" v-for="(data,index) in visitList" :key="index">
                  <div
                      class="triangle"
                      :style="reservationPrivileges&&`border-top: 20px solid ${timeColor(timeCheck[index])}`"
                  >
                  </div>
                  <li class="position_relative">
                    <div>{{data.startTime}}</div>
                    <div>{{courseDivision(data.startCourse)}}</div>
                    <div :style="data.galleyTeamFlag && 'color:red'"
                    >{{data.resveName}}</div>
                    <div class="caddie-name" v-if="data.tgCaddieArgmts.length">
                      <label v-for="(Cname,index) in data.tgCaddieArgmts" :key="index"
                             :style="Cname.tgCaddieMst.sexCode==='F'?'color:pink'
                             :'color:skyblue'">
                        {{Cname.caddieName}}
                      </label>
                    </div>
                  </li>
                  <li>
                    <span class="custom_span" v-for="(data2,index) in data.tgVisitCmpns" :key="index">
                      {{data2.visitName}}
                    </span>
                  </li>
                </ol>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { DATE_FORMAT_YYYY_MM_DD } from '@/utils/Date';
import { numberWithCommas } from '@/utils/Number';
import {commonCodesGetColorValue, commonCodesGetCommonCode, commonCodesGetSortNo} from '@/utils/CommonCodeUtil';
import Calendar from '@/components/Calendar';
import DatePicker from '@/components/DatePicker';
import commonMixin from '@/mixin/commonMixin';
import GolfErpAPI from '@/api/GolfErpAPI';
import {orderBy as _orderBy} from "lodash";
import {mapGetters} from "vuex";

export default {
  name: 'VisitStatus',
  components: {
    Calendar,
    DatePicker,
  },
  mixins: [commonMixin],
  filters: { numberWithCommas },
  data() {
    return {
      currentDate: moment(),
      calendarList: [],
      visitList: [],
      visitStatusList: [],
      timeCheck: {},
      touchPosition: {
        startY: null,
        endY: null
      },
      isWeeksCalendar: false,
    };
  },
  async created() {
    document.addEventListener('touchstart', this.handleTouchStart, false);
    document.addEventListener('touchmove', this.handleTouchMove, false);
    document.addEventListener('touchend', this.handleTouchEnd, false);
  },
  async mounted() {
    await this.fetch();
    await this.$nextTick();
    this.$refs.datePicker.setDate(this.currentDate.format(DATE_FORMAT_YYYY_MM_DD), true);
  },
  async beforeDestroy() {
    document.removeEventListener('touchstart', this.handleTouchStart, false);
    document.removeEventListener('touchmove', this.handleTouchMove, false);
    document.removeEventListener('touchend', this.handleTouchEnd, false);
  },
  computed: {
    ...mapGetters(['authList']),
    reservationPrivileges() {
      return this.authList.includes('misResveStatusReg')
    },
    pureCurrentDate() {
      return moment(this.currentDate).locale('ko');
    },
    currentDate_YYYY_MM_DD() {
      return this.pureCurrentDate.format('YYYY.MM.DD');
    },
    currentDate_YYYY_MM() {
      return this.pureCurrentDate.format('YYYY.MM');
    },
    currentDate_ddd() {
      return this.pureCurrentDate.format('(ddd)');
    },
    dateFields() {
      return ['DD', 'MM', 'YY'];
    },
    getOutput() {
      const month = bsnDate => Number(moment(bsnDate).format('M'));
      const day = bsnDate => Number(moment(bsnDate).format('D'));
      const dayColorValueArray = this.calendarList
        ?.map(item => {
          const block = {};
          block.type = 'COLOR_VALUE';
          block.month = month(item.bsnDate);
          block.day = day(item.bsnDate);
          block.style = { color: this.getDayColorValue(item.bsnDate) };
          return block;
        });
      const dayValueArray = this.calendarList
        ?.map(item => {
          const count = (
            item.visitTeam > 0 &&
            this.$t('visitStatus.caption.visitTeamStatus', [item.visitTeam])
          ) || '';
          const per = Number.isInteger(item.visitPerHoleDiv)
            ? item.visitPerHoleDiv
            : item.visitPerHoleDiv.toFixed(1);
          const block = {};
          block.type = 'VALUE';
          block.month = month(item.bsnDate);
          block.day = day(item.bsnDate);
          block.value = per > 0 && `${count}${per}`;
          return block;
        });
      const dayClosedArray = this.calendarList
        ?.map(item => {
          const block = {};
          block.type = 'CLOSED';
          block.month = month(item.bsnDate);
          block.day = day(item.bsnDate);
          block.value = item.bsnCode === 'CLOSE';
          return block;
        });
      return [
        dayColorValueArray,
        dayValueArray,
        dayClosedArray
      ];
    },
  },
  methods: {
    timeColor(timeData) {
      if(timeData.cancelDiv){
        return  commonCodesGetColorValue("CHKIN_STATUS",timeData.cancelDiv);
      } else if(timeData.chkinStatus){
        return  commonCodesGetColorValue("CHKIN_STATUS",timeData.chkinStatus);
      } else {
        return 'transparent';
      }
    },
    courseDivision (data){
      const course = commonCodesGetCommonCode('COURSE_CODE')
      const [{comName}] = course.filter(item => item.comCode===data)
      return comName;
    },
    getCalendarInfo(bsnDate) {
      return this.calendarList
        ?.find(item => item.bsnDate === bsnDate);
    },
    getDayColorValue(day) {
      const info = this.getCalendarInfo(moment(day).format('YYYYMMDD'));
      const dwCode = info?.dwCode || '';
      const bsnCode = info?.bsnCode || '';
      const color = dwCode === '1' || dwCode === '7'
        ? commonCodesGetColorValue('DW_CODE', dwCode.toString())
        : bsnCode === 'WEEKEND'
          ? commonCodesGetColorValue('BSN_CODE', bsnCode.toString())
          : commonCodesGetColorValue('DW_CODE', dwCode.toString());
      return color !== '#000000' && color;
    },
    goToMonth(to) {
      this.$refs.calendar.goToMonth(to === 'NEXT');
    },
    handleTouchStart(args) {
      this.touchPosition.startY = args.touches[0].clientY;
    },
    handleTouchMove(args) {
      this.touchPosition.endY = args.touches[0].clientY;
    },
    handleTouchEnd() {
      if (
        !this.touchPosition.startY ||
        !this.touchPosition.endY
      ) {
        return;
      }
      // const screenHeight = screen.height;
      const startY = this.touchPosition.startY;
      const scrollY = window.scrollY;
      const yDiff = this.touchPosition.startY - this.touchPosition.endY;
      const isUpSwipe = yDiff > 0;
      const distance = Math.abs(yDiff);
      if (
        !this.isWeeksCalendar && // 월 단위 캘린더
        isUpSwipe && // 터치 드래그 올림
        // startY >= screenHeight - 200 && // 화면 밑에서 약 200px
        distance >= 80
      ) {
        this.isWeeksCalendar = true;
      } else if (
        !this.isVisitDetailPopupOpened &&
        this.isWeeksCalendar &&
        isUpSwipe &&
        startY <= 460 - scrollY &&
        distance >= 60
      ) {
        this.isVisitDetailPopupOpened = true;
      } else if (
        !this.isVisitDetailPopupOpened &&
        this.isWeeksCalendar &&
        !isUpSwipe &&
        startY <= 360 - scrollY &&
        distance >= 20
      ) {
        this.isWeeksCalendar = false;
      } else if (
        this.isVisitDetailPopupOpened &&
        !isUpSwipe &&
        startY <= 200 &&
        distance >= 60
      ) {
        this.isVisitDetailPopupOpened = false;
      }
      this.touchPosition.startY = null;
      this.touchPosition.endY = null;
    },
    onOpenDatePicker() {
      this.$refs.datePicker.openPicker();
    },
    async onSelectedDatePicker(date) {
      this.$refs.calendar.goToDate(date);
    },
    async onSelectedDate(day, isWeeksCalendar = true) {
      this.currentDate = moment(day);
      if (
        !this.isWeeksCalendar &&
        isWeeksCalendar
      ) this.isWeeksCalendar = true;
      await this.fetch();
    },
    async fetch() {
      const { data: closeDiv } = await GolfErpAPI.getCloseDiv({
        bsnDate: moment(this.currentDate).format('YYYY-MM-DD'),
        storeCode: 'FRONT'
      });
      if (closeDiv !== 'Y') {
        const bsnDate = moment(this.currentDate).format('YYYYMMDD');
        const requestList = [
          'SP_CLOSE_PART_DIV',
          'SP_CLOSE_SALES_STORE',
          'SP_CLOSE_ACMTL_BASIC',
          'SP_CLOSE_ACMTL_SALES',
          'SP_CLOSE_CARD',
          'SP_CLOSE_PAYMT',
          'SP_CLOSE_SALES_FEE'
        ];
        const inParams = [bsnDate];
        let request = [];
        requestList.map((item) => request.push({ name: item, inParams }));
        await GolfErpAPI.putStoredProcedureExecute({
          storedProcedureInfos: request
        });
      }
      this.calendarList = await GolfErpAPI.fetchMisVisitStatus({
        year: moment(this.currentDate).format('YYYY'),
        month: moment(this.currentDate).format('MM')
      });
         const {visitList,visitStatus} =  await GolfErpAPI.fetchMisVisitLookup({
        bsnDate: moment(this.currentDate).format('YYYY-MM-DD'),
      })
      this.visitList = _orderBy(visitList.map(item => ({
        ...item,
        courseCodeSortNo: commonCodesGetSortNo("COURSE_CODE",item.startCourse),
      })), ["startTime", "courseCodeSortNo"]);
      this.visitStatusList = visitStatus;
      this.timeCheck = this.visitList.map((data) => {
        return {
          chkinStatus: data.chkinStatus,
          cancelDiv: data.tgResveConfirm.cancelDiv,
          agncyOpenFlag: data.tgResveConfirm.tgResveTime.agncyOpenFlag,
          webOpenFlag: data.tgResveConfirm.tgResveTime.webOpenFlag,
          timeDiv: data.tgResveConfirm.tgResveTime.timeDiv,
          timeStatus: data.tgResveConfirm.tgResveTime.timeStatus
        }
      })
    },
  }
};
</script>
<style scoped>
* {
  font-size: 14px;
  font-family: 'notoSansCjkKr'
}
.triangle {
  position: absolute ;
  left: 0;
  top: 0;
  width: 0;
  height: 0;
  border-bottom: 20px solid transparent;
  border-right: 20px solid transparent;
}
.custom_obj {
  display: flex;
  flex-flow: row nowrap;
}
.custom_obj .custom_ul {
  margin: 0;
  padding: 0;
  font-family: 'notoSansCjkKr';
  flex: 1;
  list-style: none;
  color: white;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
}
.custom_obj .custom_ul li {
  margin-bottom: 0.35rem;
}
.custom_obj .custom_ul li:first-child{
  font-size: 1.2rem;
  margin-bottom: 10px;
}
body.id-visit .container .body-content .content-sub .sub-body .info > li {
  width: 100%;
}
body.id-visit .container .body-content .content-sub .sub-body .info > li .contain {
  padding-left: 10px;
  padding-right: 10px;
}
body.id-visit .container .body-content .content-sub .sub-body .info > li .contain .data > li::before {
display: none;
}
.custom_ol {
  position: relative;
  display: flex;
  margin: 0;
  padding: 10px;
  flex-flow: column nowrap;
  list-style: none;
  color: white;
  font-family: 'notoSansCjkKr';
  font-size: 1rem;
  height: 50px;
  border: 1px solid #ccc;
  border-radius: 10px;
  margin-bottom: 10px;
  overflow: hidden;
  justify-content: space-between;

}
.custom_ol > li {
  display: flex;
  flex-flow: row nowrap;
}
.custom_ol > li:last-child {
  justify-content: end;
  text-align: center;
}
.position_relative {
  position: relative;
}
.position_relative .caddie-name {
  position: absolute ;
  right: 0 ;
  margin-right: 0;
  border-radius: 5px;
  box-sizing: border-box;
  font-size: 12px;
}
.position_relative .caddie-name > label::after{
  content: ",";
}
.position_relative .caddie-name > label:last-child::after{
  content: none;
}
.custom_ol > li > div {
  margin-right: 2rem;
}
.custom_ol > li > span {
  flex: 0.2;
}
.light .custom_ul {
  color: black !important;
}
.light .custom_ol {
  color: black !important;
}
.custom-font{
  font-size: 0.95rem;
}
</style>
