export const PATH_AUTH = '/auth/mis';
export const PATH_AUTH_ISSUE = `${PATH_AUTH}/issue`;
export const PATH_AUTH_WITHDRAW = `${PATH_AUTH}/withdraw`;

export function issueAuthorization(email, password) {
  return this.v1.post(PATH_AUTH_ISSUE, { email, password });
}

export function removeAuthorization() {
  return this.v1.delete(PATH_AUTH_WITHDRAW);
}
