export const PATH_CALENDER = '/mis/calender';

export function fetchReservationCalendar(args) {
  return this.v1.get(`${PATH_CALENDER}/reservation-calendar`, {
    params: {
      year: args.year,
      month: args.month,
      div: args.div,
      isAditTeam: args.isAditTeam
    }
  });
}
