<template>
  <div class='custom loader'>
    <div class='custom-spinner' v-show='loading'>
      <div
        class='custom-clip'
        :style='{
          ...spinnerStyle,
          borderColor: `${color} ${color} rgba(0, 0, 0, .1)`,
        }'
      />
    </div>
  </div>
</template>

<style lang='scss' scoped>
.custom-spinner {
  position: relative;
  margin-left: -74px;
  text-align: center;
  > .custom-clip {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    animation: custom-clip-delay .75s 0s infinite linear;
    animation-fill-mode: both;
  }
}

@keyframes custom-clip-delay {
  0% {transform: rotate(0deg)}
  50% {transform: rotate(180deg)}
  100% {transform: rotate(360deg)}
}
</style>

<script>
export default {
  name: 'CustomLoader',
  props: {
    loading: {
      type: Boolean,
      default: true
    },
    color: {
      type: String,
      default: '#3863f9'
    },
    size: {
      type: String,
      default: '64px'
    },
    radius: {
      type: String,
      default: '100%'
    }
  },
  computed: {
    spinnerStyle() {
      return {
        height: this.size,
        width: this.size,
        borderWidth: '5px',
        borderStyle: 'solid',
        borderRadius: this.radius,
        background: 'transparent'
      };
    }
  }
};
</script>
