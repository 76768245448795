<template>
  <div class='ui-modal-basic i-modal-reservationInfo'>
    <div class='i-modal-wrap'>
      <div
        class='i-modal-container'
        @click.self='close'
      >
        <div
          class='i-modal-header'
          @click.self='close'
        >
          <div
            class='body'
            @click.self='close'
          >
            <div
              v-if='data.vipFlag'
              class='vip'
            >
              <div class='item'>
                VIP
              </div>
            </div>
            <div
              class='profile'
              @click='onShowMemberImageClick(data.memberImagePath)'
            >
              <div
                class='item'
                :style='{
                  backgroundImage: data.reservationConfirm.reservationMember.memberImagePath
                  && `url(${data.reservationConfirm.reservationMember.memberImagePath})`,
                  backgroundSize: data.reservationConfirm.reservationMember.memberImagePath && "cover"
                }'
              >
                {{ $t('common.profileImage') }}
              </div>
            </div>
            <div
              class='close'
              @click='close'
            >
              <div class='item'>
                {{ $t('common.close') }}
              </div>
            </div>
            <div
              class='title'
              @click='close'
            >
              <div class='item'>
                {{ headerTitle }}
              </div>
            </div>
          </div>
        </div>
        <div
          class='i-modal-body'
          @click='close'
        >
          <div class='wrap'>
            <ul class='info'>
              <li>
                <div class='caption'>
                  {{ $t('common.grpName') }}
                </div>
                <div
                  v-if='data.grpKind'
                  class='content'
                >
                  {{ data.grpName }}
                  ({{ data.grpKind }})
                </div>
              </li>
              <li>
                <div class='caption'>
                  {{ $t('common.resveName') }}
                </div>
                <div class='content'>
                  {{ data.resveName }}
                </div>
              </li>
              <li>
                <div class='caption'>
                  {{ $t('common.contactTel') }}
                </div>
                <div class='content'>
                  {{ data.contactTel | cellphoneNumberFormatter }}
                </div>
              </li>
              <li>
                <div class='caption'>
                  {{ $t('common.memberDiv') }}
                </div>
                <div class='content'>
                  {{ data.memberDiv }}
                </div>
              </li>
              <li>
                <div class='caption'>
                  {{ $t('common.memberGrade') }}
                </div>
                <div class='content'>
                  {{ data.memberGrade }}
                </div>
              </li>
              <li>
                <div class='caption'>
                  {{ $t('common.resveKind') }}
                </div>
                <div class='content'>
                  {{ data.resveKind }}
                </div>
              </li>
              <li>
                <div class='caption'>
                  {{ $t('common.resveChannel') }}
                </div>
                <div class='content'>
                  {{ data.resveChannel }}
                </div>
              </li>
              <li>
                <div class='caption'>
                  {{ $t('common.resveRemarks') }}
                </div>
                <div class='content'>
                  {{ data.resveRemarks }}
                </div>
              </li>
              <li>
                <div class='caption'>
                  {{ $t('common.smsSendLabel') }}
                </div>
                <div class='content'>
                  {{ smsSendLabel }}
                </div>
              </li>
              <li v-if='data.insertDt'>
                <div class='caption'>
                  {{ $t('common.insertLabel') }}
                </div>
                <div class='content'>
                  {{ data.insertName }} / {{ data.insertDt }}
                </div>
              </li>
              <li v-if='data.updateDt'>
                <div class='caption'>
                  {{ $t('common.updateLabel') }}
                </div>
                <div class='content'>
                  {{ data.updateName }} / {{ data.updateDt }}
                </div>
              </li>
              <li>
                <div class='caption'>
                  {{ $t('common.areaCode') }}
                </div>
                <div class='content'>
                  {{ data.areaCode }}
                </div>
              </li>
              <li>
                <div class='caption'>
                  {{ $t('common.sexCode') }}
                </div>
                <div class='content'>
                  {{ data.sexCode }}
                </div>
              </li>
              <li>
                <div class='caption'>
                  {{ $t('common.recomendr') }}
                </div>
                <div class='content'>
                  {{ data.recomendr }}
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { commonCodesGetComName } from '@/utils/CommonCodeUtil';
import { cellphoneNumberFormatter } from '@/utils/CellphoneNumberFilter';

export default {
  name: 'ReservationInfoPopup',
  filters: { cellphoneNumberFormatter },
  props: {
    currentDate: {
      type: Object,
      default: null
    },
  },
  data() {
    return {
      data: {
        resveTime: null,
        resveCourse: null,
        partDiv: null,
        grpKind: null,
        grpName: null,
        resveName: null,
        contactTel: null,
        memberDiv: null,
        memberGrade: null,
        resveKind: null,
        resveChannel: null,
        resveRemarks: null,
        smsSendFlag: null,
        smsSendCnt: null,
        smsSendDt: null,
        insertName: null,
        insertDt: null,
        updateName: null,
        updateDt: null,
        areaCode: null,
        sexCode: null,
        recomendr: null,
        vipFlag: null
      },
      $viewer: null
    };
  },
  computed: {
    headerTitle() {
      const courseLabel = commonCodesGetComName('COURSE_CODE', this.data.resveCourse);
      const partLabel = commonCodesGetComName('PART_DIV', this.data.partDiv);
      return `${this.todayLabel} ${this.data.resveTime} ${courseLabel} ${partLabel}`;
    },
    todayLabel() {
      return moment(this.currentDate)
        .locale('ko')
        .format('YYYY.MM.DD (ddd)');
    },
    smsSendLabel() {
      const isSentSms = this.$t(`common.${this.data.smsSendFlag ? 'smsSend' : 'smsNotSend'}`);
      const prevLabel = `${isSentSms} ${this.$t('common.count', [this.data.smsSendCnt])}`;
      return this.data.smsSendFlag
        ? `${prevLabel} / ${this.data.smsSendDt}`
        : prevLabel;
    }
  },
  methods: {
    loadData(data) {
      this.data = data;
    },
    onShowMemberImageClick(imageUrl) {
      if (!imageUrl) return;
      this.$viewer = this.$viewerApi({ images: [imageUrl] });
    },
    close() {
      this.$emit('close');
    }
  },
  beforeRouteLeave(to, from, next) {
    if (this.$viewer) {
      this.$viewer.destroy();
      this.$viewer = null;
    }
    next();
  }
};
</script>
