<template>
  <header>
    <div class='header-wrap'>
      <h1 class='ci'>
        <div class='item'>
          {{ $t('title') }}
        </div>
      </h1>
      <div class='navigation'>
        <div
          class='mode'
          @click='onClickDarkMode'
        >
          <div class='switch'>
            <label>
              <input
                type='checkbox'
                :checked='isNotDarkMode'
              />
              <i />
            </label>
          </div>
        </div>
        <div
          class='logout'
          @click='onClickLogout'
        >
          <div class='item'>
            {{ $t('common.signOut') }}
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'TheHeader',
  computed: {
    ...mapGetters(['username', 'darkMode']),
    isNotDarkMode() {
      return this.darkMode === 'N';
    }
  },
  methods: {
    ...mapActions(['changeDarkMode']),
    onClickLogout() {
      this.$emit('signOut');
    },
    async onClickDarkMode() {
      await this.changeDarkMode({
        darkMode: this.darkMode === 'Y' ? 'N' : 'Y'
      });
      await this.$router.go(0);
    }
  }
};
</script>
