<template>
  <input-telephone
    ref="input-telephone"
    v-bind="$_attrs"
    v-on="$_listeners"
    v-model="$_valueModel"
    :max-length="maxLength"
  />
</template>

<script>
import InputTelephone from "@/components/InputTelephone";
import { phoneNumberFormatter} from "@/utils/CellphoneNumberFilter";

export default {
  name: "ComponentTelephone",
  components: { InputTelephone },
  props: {
    value: {
      type: String,
    },
    maxLength: {
      type: Number,
      default: () => 12
    }
  },
  data() {
    return {
      prevCursorIndex: 0,
    };
  },
  computed: {
    $_valueModel: {
      get() {
        return phoneNumberFormatter(this.value?.replace(/[^\d*]/gi, ""));
      },
      set(phoneNumber) {
        this.$emit("input", phoneNumber?.replace(/[^\d*]/gi, ""));

        // after input. position is already to behind
        const prevText = phoneNumberFormatter(this.value);
        const nextText = phoneNumberFormatter(phoneNumber);
        const prevHyphenCount = (prevText.match(/-/g) || []).length;
        const nextHyphenCount = (nextText.match(/-/g) || []).length;

        if (prevText.length > nextText.length) {
          if (0 < Math.abs(prevHyphenCount - nextHyphenCount)) {
            this.$refs["input-telephone"].$el.setSelectionRange(this.prevCursorIndex - 1, this.prevCursorIndex - 1);
          } else {
            this.$refs["input-telephone"].$el.setSelectionRange(this.prevCursorIndex, this.prevCursorIndex);
          }
        } else {
          const nextCursorIndex = this.prevCursorIndex + 1;
          if (0 < Math.abs(prevHyphenCount - nextHyphenCount)) {
            this.$refs["input-telephone"].$el.setSelectionRange(nextCursorIndex + 1, nextCursorIndex + 1);
          } else {
            this.$refs["input-telephone"].$el.setSelectionRange(nextCursorIndex, nextCursorIndex);
          }
        }
      },
    },
    $_attrs() {
      return Object.assign({}, this.$attrs, {
        placeholder:
          phoneNumberFormatter(this.$attrs.placeholder),
      });
    },
    $_listeners() {
      return Object.assign({}, this.$listeners, {
        input: () => {}, // override empty input event handler, cause value model sync as computed
        keydown: (event) => {
          if (event.key === "Backspace") {
            this.prevCursorIndex = event.target.selectionStart - 1;
          } else {
            this.prevCursorIndex = event.target.selectionStart;
          }
          this.$emit("keydown", event);
        }
      });
    },
  },
  methods: {
    focus() {
      this.$refs["input-telephone"].$el.focus();
    },
    click() {
      this.$refs["input-telephone"].$el.click();
    },
  },
};
</script>

<style scoped></style>
