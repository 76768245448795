const commonCodesKey = 'Common-Codes';
const standardInfosKey = 'Standard-Infos';
const bizMstInfoKey = 'Biz-Mst-Info';

let commonCodesMap = new Map();

/**
 * 특정 groupCode의 공통코드 값 가져오기
 * JSON.parse를 사용하여 deep copy
 *
 * @param groupCode
 * @returns {any}
 */
function getParsedCommonCodes(groupCode) {
  const commonCodesByGroupCode = commonCodesMap.get(groupCode);
  if (!commonCodesByGroupCode) {
    console.error(`### No Common Codes with ${groupCode} ###`);
  } else {
    return JSON.parse(JSON.stringify(commonCodesByGroupCode));
  }
}

export function commonCodesSetBizMstInfo(bizMstInfoJson) {
  if (bizMstInfoJson && bizMstInfoJson.tsBizMstInfo) {
    sessionStorage.setItem(
      bizMstInfoKey,
      JSON.stringify(bizMstInfoJson.tsBizMstInfo)
    );
  }
}
export function commonCodesGetCommonCodeAttrbNameByCodeAndIdx(
    groupCode,
    comCode,
    idx
) {
  let returnAttrbNumber = null;
  let commonCodes = getParsedCommonCodes(groupCode);
  let commonCode = commonCodes.find((code) => code.comCode === comCode);
  if (commonCode?.jsonData) {
    let commonCodeJsonDataArray = JSON.parse(commonCode.jsonData);
    let commonCodeJsonData = commonCodeJsonDataArray.find(
        (jsonData) => jsonData.IDX === idx
    );

    if (commonCodeJsonData !== undefined) {
      returnAttrbNumber = Number(commonCodeJsonData.ATTRB_NAME);
    }
    if (isNaN(returnAttrbNumber)) {
      returnAttrbNumber = commonCodeJsonData.ATTRB_NAME;
    }
  }
  return returnAttrbNumber;
}


export function commonCodesGetBizMstInfo() {
  return JSON.parse(sessionStorage.getItem(bizMstInfoKey));
}

export function commonCodesGetStandardInfo(key) {
  const standardInfoObject = JSON.parse(
      sessionStorage.getItem(standardInfosKey)
  );
  return standardInfoObject[key];
}

export function commonCodesSetCommonCode(commonCodesJson) {
  sessionStorage.setItem(commonCodesKey, JSON.stringify(commonCodesJson));
}

export function hasCommonCode() {
  return Object.prototype.hasOwnProperty.call(sessionStorage, commonCodesKey);
}

export function commonCodesLoadCommonCodesMap() {
  commonCodesMap.clear();

  let loadedCommonCodesJson = JSON.parse(
    sessionStorage.getItem(commonCodesKey)
  );
  let commonCodesJsonKeys = Object.keys(loadedCommonCodesJson);

  if (commonCodesJsonKeys && commonCodesJsonKeys.length > 0) {
    commonCodesJsonKeys.forEach((commonCodeKey) => {
      commonCodesMap.set(commonCodeKey, loadedCommonCodesJson[commonCodeKey]);
    });
    return hasCommonCode();
  }
  return false;
}

/**
 * groupCode에 해당하는 모든 공통 코드 목록을 가져온다
 * bizCode와 상관 없이 모두 가져옴
 * @param groupCode 가져올 공통 코드의 그룹 코드
 * @param useFlag 가져올 공통 코드의 사용여부. true인 경우에는 useFlag === true 인 값만 가져온다. false인 경우에는 모두 다 가져온다
 * @returns {any}
 */
export function commonCodesGetCommonCode(groupCode, useFlag = false) {
  let commonCodes = getParsedCommonCodes(groupCode);
  let retCommonCodes = [];

  if (commonCodes && Array.isArray(commonCodes)) {
    if (!useFlag) {
      return commonCodes.slice(0);
    } else {
      commonCodes.forEach((commonCode) => {
        if (commonCode.useFlag !== null) {
          if (commonCode.useFlag === useFlag) {
            retCommonCodes.push(commonCode);
          }
        }
      });
      return retCommonCodes;
    }
  } else {
    return null;
  }
}

/**
 * groupCode에 해당하는 모든 공통 코드 중 JSON_DATA의 attrb 필드로 필터링한 목록을 가져온다
 * bizCode와 상관 없이 모두 가져옴
 * @param groupCode 가져올 공통 코드의 그룹 코드
 * @param attrb 가져올 공통 코드 중, 필터링할 JSON_DATA의 ATTRB
 * @param useFlag 가져올 공통 코드의 사용여부. true면 사용여부가 true인 것만, false면 전체.
 * @returns {any}
 */
export function commonCodesGetCommonCodeByAttrb(
  groupCode,
  attrb,
  useFlag = false
) {
  let commonCodes = getParsedCommonCodes(groupCode);
  let retCommonCodes = [];
  if (Array.isArray(commonCodes)) {
    commonCodes.forEach((commonCode) => {
      if (commonCode.jsonData != null) {
        let commonCodeJsonDataArray = JSON.parse(commonCode.jsonData);
        if (
          Array.isArray(commonCodeJsonDataArray) &&
          commonCodeJsonDataArray.findIndex(
            (jsonData) => attrb === jsonData.ATTRB
          ) !== -1
        ) {
          if (useFlag) {
            if (commonCode.useFlag) {
              retCommonCodes.push(commonCode);
            }
          } else {
            retCommonCodes.push(commonCode);
          }
        }
      }
    });
  }
  return retCommonCodes;
}

/**
 * 특정 코드의 colorValue 값을 가져온다
 * @param groupCode 그룹 코드
 * @param comCode 코드값
 * @returns {*}
 */
export function commonCodesGetColorValue(groupCode, comCode) {
  let findedComCode = findElementWithComCode(groupCode, comCode);

  if (findedComCode) {
    return findedComCode.colorValue;
  } else {
    return null;
  }
}

/**
 * 특정 코드의 comName 값을 가져온다
 * @param groupCode 그룹 코드
 * @param comCode 코드값
 * @returns {*}
 */
export function commonCodesGetComName(groupCode, comCode) {
  let findedComCode = findElementWithComCode(groupCode, comCode);

  if (findedComCode) {
    return findedComCode.comName;
  } else {
    return null;
  }
}

/**
 * 특정 코드의 codeAbrv 값을 가져온다
 * @param groupCode 그룹 코드
 * @param comCode 코드값
 * @returns {null|*}
 */
export function commonCodesGetCodeAbrv(groupCode, comCode) {
  let findedComCode = findElementWithComCode(groupCode, comCode);

  if (findedComCode) {
    return findedComCode.codeAbrv;
  } else {
    return null;
  }
}

/**
 * 특정 코드의 sortNo 값을 가져온다
 * @param groupCode 그룹 코드
 * @param comCode 코드값
 * @returns {*}
 */
export function commonCodesGetSortNo(groupCode, comCode) {
  let findedComCode = findElementWithComCode(groupCode, comCode);

  if (findedComCode) {
    return findedComCode.sortNo;
  } else {
    return null;
  }
}

/**
 * 특정 코드의 jsonData 값을 가져온다
 * @param groupCode
 * @param comCode
 */
export function commonCodesGetJsonData(groupCode, comCode) {
  let findedComCode = findElementWithComCode(groupCode, comCode);

  if (findedComCode) {
    return JSON.parse(findedComCode.jsonData);
  } else {
    return null;
  }
}

function findElementWithComCode(groupCode, comCode) {
  let comCodesOfGroupCode = commonCodesGetCommonCode(groupCode, false);
  let findedComCode = null;

  if (comCodesOfGroupCode && comCodesOfGroupCode.length > 0) {
    findedComCode = comCodesOfGroupCode.find(
      (element) => comCode === element.comCode
    );
  }

  return findedComCode;
}

export function commonCodesSetStandardInfos(standardInfosJson) {
  if (standardInfosJson) {
    sessionStorage.setItem(
      standardInfosKey,
      JSON.stringify(standardInfosJson)
    );
  }
}
export function commonCodeGetComCodeDefaultValue(groupCode) {
  let commonCodes = commonCodesGetCommonCode(groupCode);
  return getDefaultComCode(commonCodes);
}

function getDefaultComCode(commonCodes) {
  let defaultValue;

  if (Array.isArray(commonCodes)) {
    const defaultCommonCode = commonCodes.find(
        (commonCode) => commonCode.defaultFlag === true
    );
    if (defaultCommonCode) {
      defaultValue = defaultCommonCode.comCode;
    }
  }

  return defaultValue;
}
