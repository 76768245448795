export const PATH_MIS = '/mis';

export function fetchMisVisitStatus(args) {
  return this.v1.get(`${PATH_MIS}/visit/status`, {
    params: {
      year: args.year,
      month: args.month
    }
  });
}
export function fetchMisVisitLookup(args) {
  return this.v1.get(`${PATH_MIS}/visit/${args.bsnDate}`);
}
export function sendSMS(args) {
    return this.v1.post(`${PATH_MIS}/sms`,args);
}
export function fetchAllSmsWords() {
    return this.v1.get(`${PATH_MIS}/sms/words`);
}
export function fetchMisVisitStatusByBsnDate(args) {
  return this.v1.get(`${PATH_MIS}/visit/status/${args.bsnDate}`);
}
export function fetchMisMembersLookUp(args) {
  return this.v1.get(`${PATH_MIS}/members`,{
    params: {
      memberName:args
    }
  });
}
export function saveMisReservation(args) {
  return this.v1.post(`${PATH_MIS}/reservation-save`, {
      timeId: args.timeId,
      resveName: args.memberName,
      membershipId: args.membershipId,
      memberNo: args.memberNo,
      memberDiv: args.memberDiv,
      memberGrade: args.memberGrade,
      resveKind: args.resveKind,
      resveChannel: args.resveChannel,
      sexCode: args.sexCode,
      contactTel: args.hpNo,
      resveRemarks: args.resveRemarks,
      areaCode: args.areaCode
  });
}
export function webTimeCancel(args) {
    return this.v1.put(`${PATH_MIS}/web-time-cancel/${args}`);
}
export function timeHoldingMis(args,status) {
  return this.v1.post(`${PATH_MIS}/time-holding/${args}/${status}`);
}
export function fetchMisSalesStatus(args) {
  return this.v1.get(`${PATH_MIS}/sales/status`, {
    params: {
      year: args.year,
      month: args.month
    }
  });
}
