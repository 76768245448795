<template>
  <div class='wrapper'>
    <custom-loader v-if='loading' />
    <the-layout>
      <router-view />
    </the-layout>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import i18n from '@/i18n';
import GolfErpAPIUnauthorizedError from '@/api/errors/GolfErpAPIUnauthorizedError';
import UnauthorizedError from '@/errors/UnauthorizedError';
import TheLayout from '@/layouts/TheLayout';
import Loading from '@/mixin/loading';

export default {
  name: 'App',
  metaInfo: {
    title: `MIS - ${i18n.t('title')}`,
    meta: [
      { charset: 'utf-8' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no, shrink-to-fit=no' }
    ]
  },
  components: { TheLayout },
  mixins: [Loading],
  data() {
    return {
      loading: false
    };
  },
  async created() {
    this.$EventBus.$on('loaderOn', () => this.loaderOn());
    this.$EventBus.$on('loaderOff', () => this.loaderOff());
  },
  async mounted() {
    if (this.$route.path === '/report-view')
      return;
    try {
      this.loaderOn();
      await this.loadApplication();
    } catch (e) {
      if (
        e instanceof GolfErpAPIUnauthorizedError ||
        e instanceof UnauthorizedError
      ) {
        // TODO : 세션 만료 알림 임시 주석
        /*this.$vs.notification({
          icon: '<i class="bx bxs-time"/>',
          color: 'dark',
          title: '세션 만료',
          text: '세션이 만료되었습니다. 다시 로그인해주세요!'
        });*/
        await this.logout();
      } else {
        throw e;
      }
    } finally {
      this.loaderOff();
    }
  },
  async beforeDestroy() {
    this.$EventBus.$off('loaderOn');
    this.$EventBus.$off('loaderOff');
  },
  methods: {
    ...mapActions(['loadApplication', 'logout']),
    loaderOn() {
      this.loading = true;
    },
    loaderOff() {
      this.loading = false;
    }
  }
};
</script>
