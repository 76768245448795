<template>
  <!-- 전체보기(Class) : allView -->
  <div :class='[
    "ui-modal-basic",
    "i-modal-timeList",
    isAllViewMode && "allView"
  ]'>
    <div class='i-modal-wrap'>
      <div class='i-modal-container'>
        <div class='i-modal-header'>
          <div class='header'>
            <div
              class='prev'
              @click='close'
            >
              {{ $t('common.prev') }}
            </div>
            <div class='title'>
              <strong>{{ currentDate_YYYY_MM_DD }}</strong>
              {{ currentDate_ddd }}
            </div>
            <div
              :class='[isAllViewMode ? "card" : "all"]'
              @click='isAllViewMode = !isAllViewMode'
            >
              <div class='item'>
                {{ $t(`common.${isAllViewMode ? 'allView' : 'cardView'}`) }}
              </div>
            </div>
          </div>
          <div class='body'>
            <div class='card'>
              <vue-slick-carousel
                v-bind='partSettings'
                @beforeChange='onBeforeChangeCarousel'
              >
                <div
                  v-for='n in 3' :key='n'
                  class='object'
                >
                  <div class='contain'>
                    <ul class='info'>
                      <li v-for='(part, partIndex) in partDivs' :key='partIndex'>
                        <div class='title'>
                          {{ part.comName }}
                        </div>
                        <div class='content'>
                          {{ getCountOfParts(part.comCode) }}
                        </div>
                      </li>
                      <li>
                        <div class='title'>
                          {{ $t('common.total') }}
                        </div>
                        <div class='content'>
                          {{ getCountOfAllReserve }}
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </vue-slick-carousel>
            </div>
          </div>
        </div>
        <div class='i-modal-body'>
          <vue-slick-carousel
            ref='slickCarousel'
            v-bind='timeSettings'
          >
            <div
              v-for='n in 2' :key='n'
              class='body'
            >
              <ul class='tab'>
                <!-- 활성(Class) : active -->
                <li
                  v-for='(course, courseIndex) in courseCodes' :key='courseIndex'
                  :class='[activatedCourseCode === course.comCode && "active"]'
                  :style="`
                    float: left;
                    width: calc(((100% - 0.4px) / ${courseCodes.length}) - 5px);
                    margin-right: ${courseIndex === 0 ? '3px' : (courseIndex + 1) === courseCodes.length ? '' : '1.5px'};
                    margin-left: ${courseIndex === 0 ? '' : (courseIndex + 1) === courseCodes.length ? '3px' : '1.5px'};
                  `"
                  @click='activatedCourseCode = course.comCode'
                >
                  <div class='item'>
                    {{ course.comName }}
                  </div>
                </li>
              </ul>
              <ul class='timeList'>
                <!-- 활성(Class) : active -->
                <li
                  v-for='(part, partIndex) in partDivs' :key='partIndex'
                  :class='partViewIndexList.includes(part.comCode) && "active"'
                >
                  <div
                    class='list-header'
                    @click='onClickPartView(part.comCode)'
                  >
                    <div class='contain'>
                      <div class='title'>
                        {{ part.comName }}
                      </div>
                      <div class='content'>
                        <strong>{{ teamCountWithCardView(part.comCode) }}</strong>팀
                      </div>
                      <i />
                    </div>
                  </div>
                  <div class='list-body'>
                    <div class='list-wrap'>
                      <ul
                        v-for='(row, rowIndex) in timeListWithCardView(part.comCode)' :key='rowIndex'
                        class='list'
                      >
                        <li
                          v-for='(time, timeIndex) in row' :key='timeIndex'
                          @click='onClickColumn(time)'
                        >
                          <div class='contain'>
                            <div class='time'>
                              <div class='item'>
                                {{ time.resveTime }}
                              </div>
                            </div>
                            <div
                              v-if='time.vipFlag'
                              class='vip'
                            >
                              <div class='item'>
                                VIP
                              </div>
                            </div>
                            <div
                              v-if='time.resveRemarks'
                              class='memo'
                            >
                              <div class='item'>
                                {{ $t('common.memo') }}
                              </div>
                            </div>
                            <div class='name'>
                              <div class='item'>
                                {{ time.resveName }}
                              </div>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </vue-slick-carousel>
        </div>
        <div class='i-modal-body all'>
          <div class='body'>
            <div
              v-for='(course, courseIndex) in courseCodes' :key='courseIndex'
              :style="`
                float: left;
                width: calc(((100% - 0.4px) / ${courseCodes.length}) - 3px);
                padding-right: ${courseIndex === 0 ? '3px' : (courseIndex + 1) === courseCodes.length ? '' : '1.5px'};
                padding-left: ${courseIndex === 0 ? '' : (courseIndex + 1) === courseCodes.length ? '3px' : '1.5px'};
              `"
            >
              <div class='title'>
                {{ course.comName }}
              </div>
              <div class='table'>
                <div class='table-wrap'>
                  <table v-for='(part, partIndex) in partDivs' :key='partIndex'>
                    <colgroup>
                      <col class='time' />
                      <col class='name' />
                      <col class='remarks' />
                    </colgroup>
                    <tr
                      v-for='(time, timeIndex) in timeListWithSideView(course.comCode, part.comCode)' :key='timeIndex'
                      :class='[time.isSummary && "tfoot",!time.isSummary && "custom-tr",timeIndex===0 && "top-none"]'
                      @click='onClickColumn(time)'
                    >
                      <div v-if="!time.isSummary"
                           class="triangle"
                           :style="reservationPrivileges&&`border-top: 20px solid ${timeColor(time)}`"
                      ></div>
                      <td
                          class='time'
                      >
                        <div class='item'>
                          {{ time.resveTime }}
                        </div>
                        <div
                            v-if='time.vipFlag'
                            class='vip'
                        >
                          <div class='item'>
                            VIP
                          </div>
                        </div>
                      </td>
                      <td class='name'>
                        <div class='contain custom-display'>
                          <div v-if="time.resveRemarks" class="resveRemarks-img">
                          </div>
                          <div class='item'>
                            {{ time.resveName }}
                          </div>
                        </div>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import VueSlickCarousel from 'vue-slick-carousel';
import 'vue-slick-carousel/dist/vue-slick-carousel.css';
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css';
import {commonCodesGetColorValue} from "@/utils/CommonCodeUtil";
import {mapGetters} from "vuex";

export default {
  name: 'ReservationTimeListPopup',
  components: { VueSlickCarousel },
  props: {
    currentDate: {
      type: Object,
      default: null
    },
    courseCodes: {
      type: Array,
      default: () => []
    },
    partDivs: {
      type: Array,
      default: () => []
    },
    reserveCourseList: {
      type: Array,
      default: () => []
    },
    getCountOfAllReserve: {
      type: Number,
      default: 0
    },
    getCountOfParts: {
      type: Function,
      default: null
    }
  },
  data() {
    return {
      activatedCourseCode: this.courseCodes[0]?.comCode,
      partViewIndexList: ['1'],
      isAllViewMode: true
    };
  },
  computed: {
    ...mapGetters(['authList']),
    reservationPrivileges() {
      return this.authList.includes('misResveStatusReg')
    },
    pureCurrentDate() {
      return moment(this.currentDate).locale('ko');
    },
    currentDate_YYYY_MM_DD() {
      return this.pureCurrentDate.format('YYYY.MM.DD');
    },
    currentDate_ddd() {
      return this.pureCurrentDate.format('(ddd)');
    },
    partSettings() {
      return {
        arrows: false
      };
    },
    timeSettings() {
      return {
        arrows: false,
        touchMove: false
      };
    }
  },
  methods: {
    timeColor(timeData) {
      if(timeData.timeStatus === "BLOCK" || timeData.timeStatus === "HOLD"){
        return  commonCodesGetColorValue("TIME_STATUS",timeData.timeStatus);
      } else if(timeData.cancelDiv ===  "RAIN" || timeData.cancelDiv === "NOSHOW"){
        return  commonCodesGetColorValue("CANCEL_DIV",timeData.cancelDiv);
      } else if(timeData.chkinStatus === "CHKIN"){
        return  commonCodesGetColorValue("CHKIN_STATUS",timeData.chkinStatus);
      } else if (timeData.agncyOpenFlag) {
        return commonCodesGetColorValue("COLOR_TIME_STATUS", "AGNCY_OPEN_FLAG");
      } else if (timeData.webOpenFlag) {
        if (timeData.timeDiv === "OCLUB") {
            return commonCodesGetColorValue("COLOR_TIME_STATUS", "WEB_OCLUB_TIME");
        } else {
            return commonCodesGetColorValue("COLOR_TIME_STATUS", "WEB_OPEN_FLAG");
        }
      } else {
        return 'transparent';
      }
    },
    async onBeforeChangeCarousel(prev, next) {
      const toNext = (
        prev === 0 && next === 1 ||
        prev === 1 && next === 2 ||
        prev === 2 && next === 0
      );
      await this.$nextTick();
      toNext
        ? this.$refs.slickCarousel.next()
        : this.$refs.slickCarousel.prev();
      this.$emit('submit', toNext);
    },
    close() {
      this.$emit('close');
    },
    onClickColumn(time) {
      this.$emit('clickColumn', time);
    },
    onClickPartView(comCode) {
      this.partViewIndexList.includes(comCode)
        ? this.partViewIndexList = this.partViewIndexList
          ?.filter(viewComCode => viewComCode !== comCode)
        : this.partViewIndexList.push(comCode);
    },
    timeListViewDataOnly(courseCode = this.activatedCourseCode) {
      return (
        this.reserveCourseList
          ?.filter(item => item.courseCode === courseCode)[0]
          ?.data
      );
    },
    timeListWithCardView(partDiv) {
      const viewDataOnly = this.timeListViewDataOnly()
        ?.filter(item => item.partDiv === partDiv && !item.isSummary);
      let timeStack = [];
      const timeSlots = [...new Set(viewDataOnly?.map(item => item.resveTimeSlot))];
      timeSlots?.forEach(timeSlot => {
        timeStack.push(
          viewDataOnly
            ?.filter(item => item.resveTimeSlot === timeSlot)
        );
      });
      return timeStack;
    },
    teamCountWithCardView(partDiv) {
      const viewDataOnly = this.timeListViewDataOnly()
        ?.filter(item => item.partDiv === partDiv && !item.isSummary && !item.isTotalSummary);
      return viewDataOnly?.length;
    },
    timeListWithSideView(courseCode, partDiv) {
      const arr = this.timeListViewDataOnly(courseCode)
          ?.filter(item => item.partDiv === partDiv);
      if(arr.length!==0) {
        const table = this.blankTable(arr, partDiv);
        return table;
      }
    },
    blankTable(arr,partDiv){
      let arr2 =  this.reserveCourseList.map(item=>{
        return  item.data.filter(item2 => item2.partDiv===partDiv).length
      })
      const max = Math.max(...arr2)
      let length = arr.length
      while(length<max){
        arr.splice(length-1,0,{
          isSummary:false,
          blankCheck:true
        })
        length++;
      }
      const tfoot =  arr.some(data=>data.isSummary===true)
      if(!tfoot){
        arr[max-1].isSummary = true
      }
      return arr
    },
  }
};
</script>
<style scoped>
.triangle {
  cursor: pointer;
  position: absolute ;
  left: 0;
  top: 0;
  width: 0;
  height: 0;
  border-bottom: 20px solid transparent;
  border-right: 20px solid transparent;
}
.i-modal-timeList .i-modal-body .body .table .table-wrap table tr td  {
  padding: 0;
  flex: 1;
  text-align: left;
}
.i-modal-timeList .i-modal-body .body .table .table-wrap table col.name {
   width: auto;
}
.i-modal-timeList .i-modal-body .body .table .table-wrap table col.time {
  width: 100%;
}
.i-modal-timeList .i-modal-body .body .table .table-wrap table:last-child tr.tfoot td:first-child {
  border-radius: 0;
}
.tfoot {
  display: flex;
  flex-flow: column nowrap;
  height: 50px;
}
.top-none {
  margin-top: 0 !important;
}
body.id-reservation.light .custom-tr {
  background-color: #fff;
}
body.id-reservation .custom-tr {
  background-color: #262a34;
}
body.id-reservation .tfoot td{
  background-color: transparent !important;
}
body.id-reservation.light .tfoot td {
  background-color: #eef2f5 !important;
}
.custom-tr{
  position: relative;
  display: flex;
  border-radius: 5px;
  flex-flow: column nowrap;
  height: 80px;
  overflow: hidden;
  margin-top: 5px;
  padding: 5px 10px 5px 10px;
  box-sizing: border-box;
  cursor: pointer;
}
.tfoot td {
  text-align: center !important;
}
.i-modal-timeList .i-modal-body .body .table .table-wrap table {
  background-color: transparent !important;
}
.i-modal-timeList .i-modal-body .body .table .table-wrap table tr td.name  {
  text-align: right;
}
.i-modal-timeList .i-modal-body .body .table .table-wrap table tr td.remarks {
  text-align: center;
}
.i-modal-timeList .i-modal-body .body .table .table-wrap {
  min-width: auto;
}
.vip {
  position: absolute;
  top: 0;
  right: 0;
}
.i-modal-timeList .i-modal-body .body .table .table-wrap table tr .time{
  position: relative;
}
.i-modal-timeList .i-modal-body .body .table .table-wrap table tr .time .vip {
  float: left;
  line-height: 20px;
  padding: 3px 0 0 6px;
}
.i-modal-timeList .i-modal-body .body .table .table-wrap table tr .time  .vip .item {
  background-color: #3863f9;
  border-radius: 8px;
  color: #fff;
  font-family: 'notoSansCjkKr';
  font-size: 12px;
  font-weight: 700;
  height: 20px;
  line-height: 20px;
  text-align: center;
  width: 36px;
}
.resveRemarks-img{
  width: 20px;
  background-size: 100% 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAeCAYAAAA/xX6fAAAAAXNSR0IArs4c6QAAAfBJREFUSEvtljFrU1EUx3/nJVgjUXGxiJtChg4KLn4CN+eAjm5FkFYSIUjiSWMGIYklYzfX4FdwkQ66OGkwgxSDUkVp7YuKqC9H7msD1Sax4m0Q6p3ee5x7f++c///ce4UJD5kwD9GF+hXEFoHDewzvYTInWqmFE4ANcuk5oLk3Leb2tLwDzn+gNx/9GyVttVqJdqf7BDjjIzWDF+mDdjafz38amqEDPut0OwKnPQFXJUplVK9+HFlSVQ3g6BEfwJmZk71sNhvFbbfVfvuwLWLjtF972lc3Pqvq17El1YVaByHjQ0ODdYnIqObejzZNpf7QMC9tIcYq/QPnVa+F+9g0PrQbtsbIkjYajVQYyvR48MYbVf3yJz83FKiqSUukuwInxi1msFIu5k4NYprN5tTaWjR2DoloxcX/tNOYmZSr9QdmnBsHFGNZS7mLLqZ8u3bJjCUgvYuM+3+9tWml9hY4DoQG8XVl20gKpIAg/mbS9AHchESJY6rzH9xjtbo4/a0fNcAub8FfAfNazN33CnSnQ/v5y1lEqoA7cSLM7tI/VHZH1A4Nd6HBjpCB+8AuQHAHbKD/MoHN6s380+2T/GW4qZ+AvcOCG7eK1++JyK+axi71dRF2nlmaSn4vFAqF9VHV8nXVfxSYzZVK+ce/k+UHvIc+SBTMTE0AAAAASUVORK5CYII=);
}
.custom-display{
  display: flex !important;
  flex-flow: row nowrap;
  justify-content: space-between;
}
.custom-display .item {
  flex: 1;
}
.custom-display .item ::after{
  contain: none;
}
</style>
