import Vue from 'vue';
import Vuex from 'vuex';
import router from '@/router';
import createPersistedState from 'vuex-persistedstate';
import GolfErpAPI from '@/api/GolfErpAPI';
import {
  getCurrentBizCode,
  setBizCodesObject,
  setCurrentBizCode
} from '@/utils/BusinessUtil';
import { orderBy as _orderBy } from 'lodash';
import {
  commonCodesLoadCommonCodesMap,
  commonCodesSetBizMstInfo,
  commonCodesSetCommonCode,
  commonCodesSetStandardInfos
} from '@/utils/CommonCodeUtil';
import {
  getIsDarkMode,
  saveIsDarkMode,
  getIsReportMode,
  saveIsReportMode,
  getIsReportModeByDashboard,
  saveIsReportModeByDashboard
} from '@/utils/CustomConfigUtil';
import jwt_decode from 'jwt-decode';
import {
  getAccessToken,
  removeAccessToken
} from '@/utils/AuthorizationUtil';
import UnauthorizedError from '@/errors/UnauthorizedError';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    username: null,
    tempNotAutoLogin: false,
    authStatus: false,
    authList: [],
    darkMode: 'Y',
    reportMode: 'N',
    reportModeByDashboard: 'N'
  },
  actions: {
    async loadApplication({ commit }) {
      const businessPlaces = await GolfErpAPI.fetchBusinessPlaces();
      setBizCodesObject(
        _orderBy(businessPlaces, 'order').map(({ dbms, code, name }) => ({
          dbms,
          bizCode: code,
          bizName: name
        }))
      );

      // 위에서 401 이 떨어지지 않았다면, 여기서부터 진행 가능
      const accessToken = getAccessToken();
      if (!accessToken) {
        throw new UnauthorizedError();
      }

      const { aud } = jwt_decode(accessToken);
      const bizCode = getCurrentBizCode() || 'K01';
      const username = aud[1];

      setCurrentBizCode(bizCode);

      commit('SET_USERNAME', username);

      const {
        commonCodes,
        standardInfos,
        bisMstInfo,
        authList
      } = await GolfErpAPI.fetchTotalInfo();

      // 공통코드 저장
      commonCodesSetCommonCode(commonCodes);
      commonCodesLoadCommonCodesMap();
      // 시스템 환경설정 저장
      commonCodesSetStandardInfos(standardInfos);
      // 해당 유저의 사업장 정보 저장
      commonCodesSetBizMstInfo(bisMstInfo);
      // Frontend 권한, Menu 정보 저장, 즐겨찾기 저장
      commit('SET_AUTH_STATUS', true);
      commit('SET_AUTH_LIST', authList);
      commit('SET_DARK_MODE', getIsDarkMode() || 'Y');
      commit('SET_REPORT_MODE', getIsReportMode() || 'N');
      commit('SET_REPORT_MODE_BY_DASHBOARD',
        getIsReportModeByDashboard() || 'N');
    },
    async logout() {
      removeAccessToken();
      await GolfErpAPI.logout();
      await router.push({ name: 'SignIn' });
    },
    changeDarkMode({ commit }, args) {
      saveIsDarkMode(args.darkMode);
      commit('SET_DARK_MODE', args.darkMode);
    },
    changeReportMode({ commit }, args) {
      saveIsReportMode(args.reportMode);
      commit('SET_REPORT_MODE', args.reportMode);
    },
    changeReportModeByDashboard({ commit }, args) {
      saveIsReportModeByDashboard(args.reportModeByDashboard);
      commit('SET_REPORT_MODE_BY_DASHBOARD', args.reportModeByDashboard);
    }
  },
  mutations: {
    SET_USERNAME(state, username) {
      state.username = username;
    },
    SET_AUTH_STATUS(state, authStatus) {
      state.authStatus = authStatus;
    },
    SET_AUTH_LIST(state, authList) {
      state.authList = authList;
    },
    SET_DARK_MODE(state, darkMode) {
      state.darkMode = darkMode;
    },
    SET_REPORT_MODE(state, reportMode) {
      state.reportMode = reportMode;
    },
    SET_REPORT_MODE_BY_DASHBOARD(state, reportModeByDashboard) {
      state.reportModeByDashboard = reportModeByDashboard;
    }
  },
  getters: {
    username: ({ username }) => username,
    authList: ({ authList }) => authList,
    darkMode: ({ darkMode }) => darkMode,
    reportMode: ({ reportMode }) => reportMode,
    reportModeByDashboard: ({ reportModeByDashboard }) => reportModeByDashboard
  },
  plugins: [createPersistedState()]
});
