export const LOCALSTORAGE_KEY_LOGIN_EMAIL = '___les___';
export const LOCALSTORAGE_KEY_TEMP_LOGIN_PASSWORD = '___tlp___';
export const LOCALSTORAGE_KEY_TEMP_AUTO_LOGIN = '___tal___';
export const LOCALSTORAGE_KEY_ACCESS_TOKEN = '___act___';

export function saveLoginEmail(email) {
  localStorage.setItem(LOCALSTORAGE_KEY_LOGIN_EMAIL, email);
}

export function getLoginEmail() {
  return localStorage.getItem(LOCALSTORAGE_KEY_LOGIN_EMAIL);
}

export function clearLoginEmail() {
  localStorage.removeItem(LOCALSTORAGE_KEY_LOGIN_EMAIL);
}

export function saveTempLoginPassword(password) {
  localStorage.setItem(LOCALSTORAGE_KEY_TEMP_LOGIN_PASSWORD, password);
}

export function getTempLoginPassword() {
  return localStorage.getItem(LOCALSTORAGE_KEY_TEMP_LOGIN_PASSWORD);
}

export function clearTempLoginPassword() {
  localStorage.removeItem(LOCALSTORAGE_KEY_TEMP_LOGIN_PASSWORD);
}

export function saveTempAutoLogin(isAutoLogin) {
  localStorage.setItem(LOCALSTORAGE_KEY_TEMP_AUTO_LOGIN, isAutoLogin);
}

export function getTempAutoLogin() {
  return localStorage.getItem(LOCALSTORAGE_KEY_TEMP_AUTO_LOGIN);
}

export function clearTempAutoLogin() {
  localStorage.removeItem(LOCALSTORAGE_KEY_TEMP_AUTO_LOGIN);
}

export function saveAccessToken(accessToken) {
  localStorage.setItem(LOCALSTORAGE_KEY_ACCESS_TOKEN, accessToken);
}

export function getAccessToken() {
  return localStorage.getItem(LOCALSTORAGE_KEY_ACCESS_TOKEN);
}

export function removeAccessToken() {
  localStorage.removeItem(LOCALSTORAGE_KEY_ACCESS_TOKEN);
}
