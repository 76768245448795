export const LOCALSTORAGE_KEY_IS_DARK_MODE = 'IS_DARK_MODE';
export const LOCALSTORAGE_KEY_IS_REPORT_MODE = 'IS_REPORT_MODE';
export const LOCALSTORAGE_KEY_IS_REPORT_MODE_BY_DASHBOARD = 'IS_REPORT_MODE_BY_DASHBOARD';

export function saveIsDarkMode(isDarkMode) {
  localStorage.setItem(LOCALSTORAGE_KEY_IS_DARK_MODE, isDarkMode);
}

export function getIsDarkMode() {
  return localStorage.getItem(LOCALSTORAGE_KEY_IS_DARK_MODE);
}

export function saveIsReportMode(isReportMode) {
  localStorage.setItem(LOCALSTORAGE_KEY_IS_REPORT_MODE, isReportMode);
}

export function getIsReportMode() {
  return localStorage.getItem(LOCALSTORAGE_KEY_IS_REPORT_MODE);
}

export function saveIsReportModeByDashboard(isReportModeByDashboard) {
  localStorage.setItem(LOCALSTORAGE_KEY_IS_REPORT_MODE_BY_DASHBOARD,
    isReportModeByDashboard);
}

export function getIsReportModeByDashboard() {
  return localStorage.getItem(LOCALSTORAGE_KEY_IS_REPORT_MODE_BY_DASHBOARD);
}
