import Vue from 'vue';
import VueMeta from 'vue-meta';
import Vuesax from 'vuesax';
import VueViewer from 'v-viewer';
import VueKakaoSdk from 'vue-kakao-sdk';
import App from './App.vue';
import router from './router';
import store from './store';
import i18n from './i18n';
import GolfErpAPIError from '@/api/errors/GolfErpAPIError';
import GolfErpAPIUnauthorizedError
  from '@/api/errors/GolfErpAPIUnauthorizedError';
import NetworkError from '@/errors/NetworkError';
import 'vuesax/dist/vuesax.css';
import 'viewerjs/dist/viewer.css';
import 'boxicons/css/boxicons.min.css';

const apiKey = '74438a4d39c00b55662557c30e6590ac';

Vue.use(VueMeta);
Vue.use(Vuesax, {
  // options here
});
Vue.use(VueViewer, {
  defaultOptions: {
    title: false,
    navbar: false,
    zIndex: 10000000
  }
});
Vue.use(VueKakaoSdk, { apiKey });

Vue.config.productionTip = false;

// Global Vue Instance Event Bus
Vue.prototype.$EventBus = new Vue();

// Error handling
Vue.config.errorHandler = async (err, vm) => {
  if (err instanceof GolfErpAPIError) {
    const { data } = err.data;
    if (data) {
      const message =
        typeof data === 'string'
          ? data
          : err.httpErrorMessage || 'ProjectAPIError';
      vm.$vs.notification({
        color: 'primary',
        title: i18n.t('common.alert'),
        text: message
      });
    }

    // required to authorize. induce login
    if (err instanceof GolfErpAPIUnauthorizedError) {
      await vm.$store.dispatch('clearUserSession');
      await vm.$router.push({ name: 'SignIn' });
    }
  } else if (err instanceof NetworkError) {
    console.error(err.error);
    vm.$vs.notification({
      icon: '<i class="bx bxs-error"/>',
      color: 'danger',
      title: i18n.t('common.error'),
      text: i18n.t('errorMessage.serverError')
    });
  } else if (err.name === 'NavigationDuplicated') {
    process.env.NODE_ENV !== 'production' && console.error(err);
  } else {
    console.log('Front error ===> ', err);
  }
};

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App)
}).$mount('#app');
