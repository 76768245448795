export function cellphoneNumberFormatter(data) {
  if (data === undefined || data === null)
    return '';
  if (
    /^([\d]){3}-([\w*]){3}-([\d]){4}$/.test(data) ||
    /^([\d]){3}-([\w*]){4}-([\d]){4}$/.test(data)
  )
    return data;
  if (/^([\d]){3}([\w*]){3}([\d]){4}$/.test(data))
    return data.replace(/^(\d{3})([\w*]{3})(\d{4})$/, '$1-$2-$3');
  if (/^([\d]){3}([\w*]){4}([\d]){4}$/.test(data))
    return data.replace(/^(\d{3})([\w*]{4})(\d{4})$/, '$1-$2-$3');
  return data;
}

export function phoneNumberFormatter(data = "") {
  if (data?.startsWith("050")) {
    if (4 < data?.length) {
      if (data?.length < 9) {
        return data?.replace(/^([\d*]{4})([\d*]*)$/, "$1-$2");
      } else if (data?.length < 13) {
        return data?.replace(/^([\d*]{4})([\d*]*)([\d*]{4})$/, "$1-$2-$3");
      }
    }
  } else if (data?.startsWith("02")) {
    if (2 < data?.length) {
      if (data?.length < 7) {
        return data?.replace(/^([\d*]{2})([\d*]*)$/, "$1-$2");
      } else if (data?.length < 11) {
        return data?.replace(/^([\d*]{2})([\d*]*)([\d*]{4})$/, "$1-$2-$3");
      }
    }
  } else {
    if (3 < data?.length) {
      if (data?.length === 8) {
        return data?.replace(/^([\d*]{4})([\d*]{4})$/, "$1-$2");
      } else if (data?.length < 8) {
        return data?.replace(/^([\d*]{3})([\d*]*)$/, "$1-$2");
      } else if (data?.length < 12) {
        return data?.replace(/^([\d*]{3})([\d*]*)([\d*]{4})$/, "$1-$2-$3");
      }
    }
  }

  switch (data?.length) {
    case 8:
      return data?.replace(/^([\d*]{4})([\d*]{4})$/, "$1-$2");
    case 9:
      return data?.replace(/^([\d*]{2})([\d*]{3})([\d*]{4})$/, "$1-$2-$3");
    case 10:
      return data?.replace(/^([\d*]{3})([\d*]{3})([\d*]{4})$/, "$1-$2-$3");
    case 11:
      return data?.replace(/^([\d*]{3})([\d*]{4})([\d*]{4})$/, "$1-$2-$3");
    case 12:
      return data?.replace(/^([\d*]{4})([\d*]{4})([\d*]{4})$/, "$1-$2-$3");
  }

  return data;
}