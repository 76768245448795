<template>
  <datepicker
    ref='datePicker'
    v-model='date'
    v-bind='options'
    @selected='onSelectedDatePicker'
  />
</template>

<script>
import moment from 'moment';
import Datepicker from 'vuejs-datepicker';
import { DATE_FORMAT_YYYY_MM_DD } from '@/utils/Date';

export default {
  name: 'DatePicker',
  components: { Datepicker },
  data() {
    return {
      date: null,
      isMonthType: false
    };
  },
  computed: {
    options() {
      return this.isMonthType
        ? {
          format: 'YYYY-MM-DD',
          language: 'ko',
          'minimum-view': 'month'
        }
        : {
          format: 'YYYY-MM-DD',
          language: 'ko'
        };
    }
  },
  methods: {
    setDate(date, isMonthType = false) {
      this.date = date;
      this.isMonthType = isMonthType;
    },
    openPicker() {
      this.$refs.datePicker.showCalendar();
    },
    onSelectedDatePicker(date) {
      this.$emit('selected', moment(date).format(DATE_FORMAT_YYYY_MM_DD));
    }
  }
};
</script>
