<template>
  <component :is='$route.meta.layout || "DefaultLayout"'>
    <slot />
  </component>
</template>

<script>
import DefaultLayout from './DefaultLayout';
import SignLayout from './SignLayout';

export default {
  name: 'TheLayout',
  components: {
    DefaultLayout,
    SignLayout
  }
};
</script>
