<template>
  <div class='outer'>
    <slot />
  </div>
</template>

<script>
export default {
  name: 'SignLayout'
};
</script>
