export const PATH_BUSINESS_PLACES = '/business-places';
export const PATH_TOTAL_INFO = '/common/total-info';
export const PATH_COMMON = '/common';

export function fetchBusinessPlaces() {
  return this.v1.get(PATH_BUSINESS_PLACES);
}

export function fetchTotalInfo() {
  return this.v1.get(PATH_TOTAL_INFO);
}

export function putImageUpload(request) {
  return this.v1.put(`${PATH_COMMON}/image-upload`, request);
}
