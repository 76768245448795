<template>
  <div class='ui-modal-alert'>
    <div class='outer'>
      <div class='inner'>
        <div class='i-modal-wrap'>
          <div class='i-modal-container'>
            <div
              class='i-modal-body'
              v-html='$t("commonConfirmMessage.isSignOut")'
            />
            <ul class='i-modal-footer'>
              <li @click='close'>
                <button>
                  <div class='i-item'>
                    {{ $t('common.cancel') }}
                  </div>
                </button>
              </li>
              <li
                class='logout'
                @click='submit'
              >
                <!-- 비활성(Attribute) : disabled -->
                <button>
                  <div class='i-item'>
                    {{ $t('common.signOut') }}
                  </div>
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { clearTempAutoLogin } from '@/utils/AuthorizationUtil';

export default {
  name: 'SignOutPopup',
  methods: {
    ...mapActions(['logout']),
    async submit() {
      clearTempAutoLogin();
      await this.logout();
    },
    close() {
      this.$emit('close');
    }
  }
};
</script>
