export const PATH_DAILY_REPORT = '/mis/daily-report';
export const PATH_CLOSE = '/close';

export function getDailyReport(args) {
  return this.v1.get(`${PATH_DAILY_REPORT}/${args.bsnDate}`, {
    params: {
      amtFlag: args.amtFlag
    }
  });
}

export function getCloseDiv(args) {
  return this.v1.get(`${PATH_CLOSE}/check/close_div`, {
    params: {
      bsnDate: args.bsnDate,
      storeCode: args.storeCode
    }
  });
}

export function putStoredProcedureExecute(request) {
  return this.v1.put(`${PATH_CLOSE}/stored-procedure-execute`, request);
}
