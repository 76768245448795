<template>
  <div class="custom-box">
    <header class="header-title" >
      회원검색
      <div class="close" @click="close"></div>
    </header>
    <div class="input-Box">
      <div>
        <input type="text" placeholder="회원명 / 핸드폰 뒷4자리" ref="memberNameInput" @keydown.enter="getMemberProfile()" v-model="memberProfile">
        <button type="button" @click="getMemberProfile"></button>
      </div>
    </div>
    <div class="padding-box">
      <div class="table-box">
        <div v-if="!memberList.length" class="no_data">
          NO_DATA...
        </div>
        <ul
            v-for="(data,index) in memberList"
            :key="index"
            class="custom-cell"
            :ref="`list${index}`"
            @click="memberSendList(index)"
        >
          <li>
            <div>{{data.memberName}}</div>
            <div>{{ getComCodevalue("MEMBER_DIV",data.memberDiv) }}</div>
            <div>{{ getComCodevalue("SEX_CODE",data.sexCode) }}</div>
            <div>{{data.birthDay}}</div>
          </li>
          <li>
            <div>{{phoneFormatter(data.hpNo)}}</div>
            <div>{{ getComCodevalue("NEARBY_CODE",data.nearbyCode) }}</div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import GolfErpAPI from "@/api/GolfErpAPI";
import {commonCodeGetComCodeDefaultValue, commonCodesGetCommonCode} from '@/utils/CommonCodeUtil';
import {phoneNumberFormatter} from "@/utils/CellphoneNumberFilter";

export default {
  name: "MemberLookUpListPopup",

  data() {
    return {
      memberList:[],
      memberProfile:null
    };
  },
  methods: {
    phoneFormatter(hpNo){
      return phoneNumberFormatter(hpNo)
    },
    getComCodevalue(code,data=null){
      if(data){
        const comCode =  commonCodesGetCommonCode(code)
        const [{comName}] = comCode.filter( item => item.comCode === data )
        return comName;
      }
    },
    async getMemberProfile(){
      if(this.memberProfile){
        this.memberList=[]
        const arr = await GolfErpAPI.fetchMisMembersLookUp(this.memberProfile);
        this.memberList = arr.map(data=>{
            return{
              ...data,
              ...data.tgMemberAddi,
              ...data.tgMemberships[0],
              ...data.tsImageFile
            }
          })
          this.memberList.unshift({
            memberName:this.memberProfile,
            memberDiv:"NOM",
            memberGrade: "NOR",
            sexCode:"M",
            areaCode:commonCodeGetComCodeDefaultValue("AREA_CODE")
          })
        }
    },

    close() {
      this.$emit("memberLookUpClose")
    },
    async memberSendList(idx){
      this.$emit("memberSendList",this.memberList[idx])
      await this.$nextTick();
      this.close()
    }
  }
}
</script>

<style scoped>
*{
  font-family: 'notoSansCjkKr';
}
.header-title {
  padding-left: 10px;
  border-bottom: 1px solid #ccc;
  width: 100%;
  height: 45px;
  font-size: 12px;
  line-height: 45px;
  color: black;
  font-weight: 700;
  box-sizing: border-box;
}
.input-Box {
  display: flex;
  flex-flow:row nowrap ;
  justify-content: center;
  padding: 10px 20px 0 0;
  box-sizing: border-box;
  font-size: 14px;
}
.input-Box > div {
  background-color: #ccc;
  margin: 0 auto;
  vertical-align: middle;
  white-space: nowrap;
  height: 35px;
  border-radius: 5px 0 0 5px;
  width: 70%;
}
.input-Box > div > input {
  width: 100%;
  height: 100%;
  border: 1px solid #ccc;
  border-right: 0;

  float: left;
  padding-left: 15px;
  border-radius: 5px 0 0 5px;
  box-sizing: border-box;
}
.input-Box > div > button {
  background: white;
  height: 35px;
  width: 30px;
  background-image: url('../../assets/images/button-icon-search.png');
  background-repeat: no-repeat;
  background-size: 12px 12px;
  background-position: center;
  border: 1px solid #ccc;
  border-left: 0;
  border-radius: 0 5px 5px 0;
}
.close{
  cursor: pointer;
  width: 35px;
  height: 35px;
  position: absolute;
  right: 0;
  top: 0;
  background: transparent url('../../assets/images/close_active.png') no-repeat center center/16px 16px;
}
.custom-box{
  width: 90%;
  height: 80%;
  background-color: white;
  z-index: 1000000000;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  border-radius: 10px;
  overflow: hidden;
  border: 1px solid #ccc;
}
.table-box{
  display: flex;
  flex-flow: row wrap;
  align-content: flex-start;
  overflow: auto;
  height: 75%;
  border: 1px solid #ccc;
  border-right: 0;
  border-left: 0;
}
.table-box > ul {
  display: flex;
  flex-flow: column nowrap;
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 70px;
  border: 1px solid #ccc;
  border-width: 1px 0 0 0;
  padding: 10px;
  box-sizing: border-box;
}
.table-box > ul > li {
  display: flex;
}
.table-box > ul > li:first-child{
  flex: 1;
}
.table-box > ul > li:first-child > div:first-child{
  font-size: 14px;
  font-weight: bold;
  text-align: left;
  width: 100px;
  overflow:hidden;
  text-overflow:ellipsis;
  white-space:nowrap;
}
.table-box > ul > li:last-child{
  flex: 0.3;
  font-size: 12px;
  flex-flow: row nowrap;
}
.table-box > ul > li:last-child > div:first-child {
  width: 100px;
  text-align: left;
}
.table-box > ul > li >  div {
  margin-right: 10px;
  font-size: 12px;
  text-align: center;
}
.table-box > ul > li:last-child >  div:first-child {
  font-weight: bold;
}
.table-box > ul:last-child {
  border-bottom: 1px solid #ccc;
}
.padding-box {
  padding: 15px;
  padding-left: 0;
  padding-right: 0;
  box-sizing: border-box;
  height: 100%;
  box-sizing: border-box;
}
.custom-cell {
  cursor: pointer;
}
.no_data {
  color: #ccc;
  font-size: 23px;
  font-weight: bold;
  padding: 10px;
}
</style>