import moment from "moment";
export const DATE_FORMAT_YYYY_MM_DD = 'YYYY-MM-DD';
export const DATE_FORMAT_YYYY_MM = 'YYYY-MM';
export const DATE_FORMAT_YYYY_M = 'YYYY-M';
export const DATE_FORMAT_YYYY = 'YYYY';
export const DATE_FORMAT_M = 'M';
export const DATE_FORMAT_D = 'D';

const baseDayOfWeekCaptionData = ["일", "월", "화", "수", "목", "금", "토"];

export function getDayOfWeekCaption(date) {
    if (date) {
        if (typeof date === "string") {
            const momentObj = moment(date, DATE_FORMAT_YYYY_MM_DD);

            return baseDayOfWeekCaptionData[momentObj.toDate().getDay()];
        }
        if (date instanceof Date) {
            return baseDayOfWeekCaptionData[date.getDay()];
        }
    } else {
        return null;
    }
}