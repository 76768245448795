<template>
  <div :class='[
    "container",
    isWeeksCalendar ? "weeksCalendar" : "basicCalendar",
  ]'>
    <div class='body-header'>
      <div class='date'>
        <div
          class='prev'
          @click='goToMonth("PREV")'
        >
          <div class='item'>
            {{ $t('common.prev') }}
          </div>
        </div>
        <div class='title'>
          <div
            class='item'
            @click='onOpenDatePicker'
          >
            <strong>{{ currentDate_YYYY_MM }}</strong>
          </div>
          <date-picker
            ref='datePicker'
            @selected='onSelectedDatePicker'
          />
        </div>
        <div
          class='next'
          @click='goToMonth("NEXT")'
        >
          <div class='item'>
            {{ $t('common.next') }}
          </div>
        </div>
      </div>
      <div class='caption'>
        <div class='item'>
          ({{ $t('report.salesUnit') }})
        </div>
      </div>
      <div
        class='refresh'
        @click='fetch'
      >
        <div class='item'>
          {{ $t('common.refresh') }}
        </div>
      </div>
    </div>
    <div class='body-content'>
      <div class='content-main'>
        <calendar
          ref='calendar'
          :output='getOutput'
          :is-weeks-calendar='isWeeksCalendar'
          @selectedDate='onSelectedDate'
        />
      </div>
      <div class='content-sub'>
        <div
          class='sub-header'
          @click='isSalesDetailPopupOpened = true'
        >
          <div class='header'>
            <div class='label'>
              ({{ $t('report.pureDeposit') }})
            </div>
            <div class='title'>
              <strong>{{ currentDate_YYYY_MM_DD }}</strong>
              {{ currentDate_ddd }}
            </div>
          </div>
          <div class='body'>
            <div class='contain'>
              <ul class='info'>
                <li v-for='(item, key, index) in salesListSummary' :key='index'>
                  <div class='caption'>
                    {{ $t(`report.${key}`) }}
                  </div>
                  <div class='content'>
                    {{ filterNumber(item) || '-' }}
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class='sub-body'>
          <ul class='info'>
            <li v-for='(item, key, index) in salesListfilteredByDate' :key='index'>
              <div class="title-wrapper">
                <div class='title'>
                  {{ $t(`report.${key}`) }}
                </div>
              </div>
              <div class='contain'>
                <ul class='data'>
                  <li v-for='(dateItem, dateKey, dateIndex) in item' :key='dateIndex'>
                    <div class="header">
                      {{$t(`report.${dateKey}`)}}
                    </div>
                    <div class='item'>
                      {{ filterNumber(dateItem) || '-' }}
                    </div>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- 모달 -->
    <div
      v-if='isPopupOpened'
      class='ui-modal'
    >
      <sales-detail-popup
        v-if='isSalesDetailPopupOpened'
        ref='salesDetailPopup'
        :sales-list-summary='salesListSummary'
        :current-date='currentDate'
        :view-sales-list='viewSalesList'
        :filter-number='filterNumber'
        @submit='onSubmitSalesDetailPopup'
        @close='onCloseSalesDetailPopup'
      />
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import Calendar from '@/components/Calendar';
import DatePicker from '@/components/DatePicker';
import SalesDetailPopup from '@/components/Modals/SalesDetailPopup';
import { numberWithCommas } from '@/utils/Number';
import { commonCodesGetColorValue } from '@/utils/CommonCodeUtil';
import commonMixin from '@/mixin/commonMixin';
import GolfErpAPI from '@/api/GolfErpAPI';
import { DATE_FORMAT_YYYY_MM_DD } from '@/utils/Date';
import {sumVal} from "@/utils/ObjectUtil";

export default {
  name: 'SalesStatus',
  components: {
    Calendar,
    DatePicker,
    SalesDetailPopup
  },
  mixins: [commonMixin],
  filters: { numberWithCommas },
  data() {
    return {
      currentDate: moment(),
      calendarList: [],
      salesListObj: {},
      salesListfilteredByDate: {},
      touchPosition: {
        startY: null,
        endY: null
      },
      isWeeksCalendar: false,
      isSalesDetailPopupOpened: false
    };
  },
  async created() {
    document.addEventListener('touchstart', this.handleTouchStart, false);
    document.addEventListener('touchmove', this.handleTouchMove, false);
    document.addEventListener('touchend', this.handleTouchEnd, false);
  },
  async mounted() {
    await this.fetch();
    await this.$nextTick();
    this.$refs.datePicker.setDate(this.currentDate.format(DATE_FORMAT_YYYY_MM_DD), true);
  },
  async beforeDestroy() {
    document.removeEventListener('touchstart', this.handleTouchStart, false);
    document.removeEventListener('touchmove', this.handleTouchMove, false);
    document.removeEventListener('touchend', this.handleTouchEnd, false);
  },
  computed: {
    pureCurrentDate() {
      return moment(this.currentDate).locale('ko');
    },
    currentDate_YYYY_MM_DD() {
      return this.pureCurrentDate.format('YYYY.MM.DD');
    },
    currentDate_YYYY_MM() {
      return this.pureCurrentDate.format('YYYY.MM');
    },
    currentDate_ddd() {
      return this.pureCurrentDate.format('(ddd)');
    },
    dateFields() {
      return ['today', 'MM', 'YY'];
    },
    salesListSummary() {
      return this.salesListObj;
    },
    getOutput() {
      const month = bsnDate => Number(moment(bsnDate).format('M'));
      const day = bsnDate => Number(moment(bsnDate).format('D'));
      const dayColorValueArray = this.calendarList
        ?.map(item => {
          const block = {};
          block.type = 'COLOR_VALUE';
          block.month = month(item.bsnDate);
          block.day = day(item.bsnDate);
          block.style = { color: this.getDayColorValue(item.bsnDate) };
          return block;
        });
      const dayValueArray = this.calendarList
        ?.map(item => {
          const block = {};
          block.type = 'VALUE';
          block.month = month(item.bsnDate);
          block.day = day(item.bsnDate);
          block.value = (
            item.netAmtSot > 0 &&
            numberWithCommas(item.netAmtSot)
          ) || '';
          return block;
        });
      const dayClosedArray = this.calendarList
        ?.map(item => {
          const block = {};
          block.type = 'CLOSED';
          block.month = month(item.bsnDate);
          block.day = day(item.bsnDate);
          block.value = item.bsnCode === 'CLOSE';
          return block;
        });
      return [
        dayColorValueArray,
        dayValueArray,
        dayClosedArray
      ];
    },
    viewSalesList() {
      // return this.salesList
      //   ?.filter(item => !item.isSummary);
      return this.salesListfilteredByDate;
    },
    isPopupOpened() {
      return this.isSalesDetailPopupOpened;
    }
  },
  methods: {
    getCalendarInfo(bsnDate) {
      return this.calendarList
        ?.find(item => item.bsnDate === bsnDate);
    },
    getDayColorValue(day) {
      const info = this.getCalendarInfo(moment(day).format('YYYYMMDD'));
      const dwCode = info?.dwCode || '';
      const bsnCode = info?.bsnCode || '';
      const color = dwCode === '1' || dwCode === '7'
        ? commonCodesGetColorValue('DW_CODE', dwCode.toString())
        : bsnCode === 'WEEKEND'
          ? commonCodesGetColorValue('BSN_CODE', bsnCode.toString())
          : commonCodesGetColorValue('DW_CODE', dwCode.toString());
      return color !== '#000000' && color;
    },
    filterNumber(value, suffix = '', toEmpty = true) {
      return value > 0 || !toEmpty
          ? `${numberWithCommas(value)}${suffix}`
          : '';
    },
    getCountBySalesList(item, dateDiv = 'DD') {
      const fieldName = `${dateDiv.toLowerCase()}Amt`;
      return numberWithCommas(this.toFixed(item[fieldName])) || 0;
    },
    getSummaryBySalesList(dateDiv = 'DD') {
      const fieldName = `${dateDiv.toLowerCase()}Amt`;
      return numberWithCommas(
        this.toFixed(
          this.salesList
            ?.find(item => item.isSummary)?.[fieldName]
        )
      ) || 0;
    },
    toFixed(value) {
      return value
        ? value.toFixed(1)
        : 0;
    },
    goToMonth(to) {
      this.$refs.calendar.goToMonth(to === 'NEXT');
    },
    handleTouchStart(args) {
      this.touchPosition.startY = args.touches[0].clientY;
    },
    handleTouchMove(args) {
      this.touchPosition.endY = args.touches[0].clientY;
    },
    handleTouchEnd() {
      if (
        !this.touchPosition.startY ||
        !this.touchPosition.endY
      ) {
        return;
      }
      // const screenHeight = screen.height;
      const startY = this.touchPosition.startY;
      const scrollY = window.scrollY;
      const yDiff = this.touchPosition.startY - this.touchPosition.endY;
      const isUpSwipe = yDiff > 0;
      const distance = Math.abs(yDiff);
      if (
        !this.isWeeksCalendar && // 월 단위 캘린더
        isUpSwipe && // 터치 드래그 올림
        // startY >= screenHeight - 200 && // 화면 밑에서 약 200px
        distance >= 80
      ) {
        this.isWeeksCalendar = true;
      } else if (
        !this.isSalesDetailPopupOpened &&
        this.isWeeksCalendar &&
        isUpSwipe &&
        startY <= 460 - scrollY &&
        distance >= 60
      ) {
        this.isSalesDetailPopupOpened = true;
      } else if (
        !this.isSalesDetailPopupOpened &&
        this.isWeeksCalendar &&
        !isUpSwipe &&
        startY <= 360 - scrollY &&
        distance >= 20
      ) {
        this.isWeeksCalendar = false;
      } else if (
        this.isSalesDetailPopupOpened &&
        !isUpSwipe &&
        startY <= 200 &&
        distance >= 60
      ) {
        this.isSalesDetailPopupOpened = false;
      }
      this.touchPosition.startY = null;
      this.touchPosition.endY = null;
    },
    onOpenDatePicker() {
      this.$refs.datePicker.openPicker();
    },
    async onSelectedDatePicker(date) {
      this.$refs.calendar.goToDate(date);
    },
    async onSelectedDate(day, isWeeksCalendar = true) {
      this.currentDate = moment(day);
      if (
        !this.isWeeksCalendar &&
        isWeeksCalendar
      ) this.isWeeksCalendar = true;
      await this.fetch();
    },
    async fetch() {
      const { data: closeDiv } = await GolfErpAPI.getCloseDiv({
        bsnDate: moment(this.currentDate).format('YYYY-MM-DD'),
        storeCode: 'FRONT'
      });
      if (closeDiv !== 'Y') {
        const bsnDate = moment(this.currentDate).format('YYYYMMDD');
        const requestList = [
          'SP_CLOSE_PART_DIV',
          'SP_CLOSE_SALES_STORE',
          'SP_CLOSE_ACMTL_BASIC',
          'SP_CLOSE_ACMTL_SALES',
          'SP_CLOSE_CARD',
          'SP_CLOSE_PAYMT',
          'SP_CLOSE_SALES_FEE'
        ];
        const inParams = [bsnDate];
        let request = [];
        requestList.map((item) => request.push({ name: item, inParams }));
        await GolfErpAPI.putStoredProcedureExecute({
          storedProcedureInfos: request
        });
      }
      this.calendarList = await GolfErpAPI.fetchMisSalesStatus({
        year: moment(this.currentDate).format('YYYY'),
        month: moment(this.currentDate).format('MM')
      });
      let data = await GolfErpAPI.getDailyReport({
        bsnDate: this.currentDate.format(DATE_FORMAT_YYYY_MM_DD),
        amtFlag: false
      });
      
      const salesListDD = data.closeAcmtlSalesList.find(item => item.acmtlSumDiv === "DD");
      const salesListMM = data.closeAcmtlSalesList.find(item => item.acmtlSumDiv === "MM");
      const salesListYY = data.closeAcmtlSalesList.find(item => item.acmtlSumDiv === "YY");
      const salesListBuff = {
        today: {
          "fee": salesListDD?.feeSalesAmt || 0,
          "cart": salesListDD?.cartSalesAmt || 0,
          "rent": salesListDD?.rentSalesAmt || 0,
          "food": salesListDD?.foodSalesAmt || 0,
          "proShop": salesListDD?.shopSalesAmt || 0,
        },
        MM: {
          "fee": salesListMM?.feeSalesAmt || 0,
          "cart": salesListMM?.cartSalesAmt || 0,
          "rent": salesListMM?.rentSalesAmt || 0,
          "food": salesListMM?.foodSalesAmt || 0,
          "proShop": salesListMM?.shopSalesAmt || 0,
        },
        YY: {
          "fee": salesListYY?.feeSalesAmt || 0,
          "cart": salesListYY?.cartSalesAmt || 0,
          "rent": salesListYY?.rentSalesAmt || 0,
          "food": salesListYY?.foodSalesAmt || 0,
          "proShop": salesListYY?.shopSalesAmt || 0,
        },
      };

      salesListBuff.today["totalWithSpace"] = sumVal(salesListBuff.today);
      salesListBuff.MM["totalWithSpace"] = sumVal(salesListBuff.MM);
      salesListBuff.YY["totalWithSpace"] = sumVal(salesListBuff.YY);
      this.salesListfilteredByDate = salesListBuff;

      const salesList = {
        today: (
          salesListBuff.today["totalWithSpace"]
        ) || 0,
        MM: (
          salesListBuff.MM["totalWithSpace"]
        ) || 0,
        YY: (
          salesListBuff.YY["totalWithSpace"]
        ) || 0,
      };

      this.salesListObj = salesList;
    },
    async onSubmitSalesDetailPopup(toNext) {
      this.currentDate = moment(this.currentDate)
        .add(toNext ? 1 : -1, 'days');
      await this.fetch();
    },
    onCloseSalesDetailPopup() {
      this.isSalesDetailPopupOpened = false;
    }
  }
};
</script>
