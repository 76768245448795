<template>
  <div class='inner'>
    <div class='header'>
      <h1 class='ci'>
        <div class='item'>
          {{ $t('title') }}
        </div>
      </h1>
    </div>
    <div class='body'>
      <div class='contain'>
        <div class='form'>
          <ul class='input'>
            <li>
              <div class='co-input'>
                <input
                  ref='id'
                  type='text'
                  v-model='form.email'
                  :placeholder='$t("common.id")'
                  @keydown.enter='onFocusPassword'
                  @keydown='formPreventEnterEvent'
                />
                <i />
              </div>
            </li>
            <li class='password'>
              <div class='co-input'>
                <input
                  ref='password'
                  type='password'
                  v-model='form.password'
                  :placeholder='$t("common.password")'
                  @keydown.enter='onSignInClick'
                  @keydown='formPreventEnterEvent'
                />
                <i />
              </div>
            </li>
          </ul>
          <div class='checkbox'>
            <div class='save'>
              <div class='check'>
                <div class='component-check'>
                  <div class='co-body'>
                    <ul class='co-b-body'>
                      <li @click='isKeepLoginEmail = !isKeepLoginEmail'>
                        <label>
                          <input
                            type='checkbox'
                            v-model='isKeepLoginEmail'
                          />
                          <div class='co-label'>
                            <div class='co-item'>
                              {{ $t('common.saveId') }}
                            </div>
                          </div>
                        </label>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class='auto'>
              <div class='check'>
                <div class='component-check'>
                  <div class='co-body'>
                    <ul class='co-b-body'>
                      <li @click='isKeepTempAutoLogin = !isKeepTempAutoLogin'>
                        <label>
                          <input
                            type='checkbox'
                            v-model='isKeepTempAutoLogin'
                          />
                          <div class='co-label'>
                            <div class='co-item'>
                              {{ $t('common.keepAutoSignIn') }}
                            </div>
                          </div>
                        </label>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class='login'>
          <div
            class='component-button'
            @click.prevent='onSignInClick'
          >
            <button>
              <div class='co-item'>
                {{ $t('common.signIn') }}
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import {
  saveLoginEmail,
  getLoginEmail,
  clearLoginEmail,
  saveTempLoginPassword,
  getTempLoginPassword,
  clearTempLoginPassword,
  saveTempAutoLogin,
  getTempAutoLogin,
  clearTempAutoLogin,
  saveAccessToken
} from '@/utils/AuthorizationUtil';
import { formPreventEnterEvent } from '@/utils/FormUtil';
import GolfErpAPI from '@/api/GolfErpAPI';

export default {
  name: 'SignIn',
  data() {
    return {
      form: {
        email: getLoginEmail(),
        password: getTempLoginPassword()
      },
      isKeepLoginEmail: !!getLoginEmail(),
      isKeepTempLoginPassword: !!getTempLoginPassword(),
      isKeepTempAutoLogin: !!getTempAutoLogin()
    };
  },
  async created() {
    if (this.$store.state.authStatus) await this.goMainPage();
  },
  async mounted() {
    await this.$nextTick();
    // TODO : 임시 비밀번호 저장 및 자동 로그인 처리 : 보안 문제 있음.
    if (this.isKeepLoginEmail && this.isKeepTempAutoLogin) {
      await this.onSignInClick();
      return;
    }
    if (this.isKeepTempLoginPassword && !this.isKeepTempAutoLogin) {
      this.isKeepTempAutoLogin = true;
      saveTempAutoLogin('Y');
    }
    this.form.email
      ? this.$refs.password.focus()
      : this.$refs.id.focus();
  },
  computed: {
    ...mapGetters(['authList'])
  },
  methods: {
    ...mapActions(['loadApplication']),
    formPreventEnterEvent,
    async onFocusPassword() {
      await this.$nextTick();
      this.$refs.password.focus();
    },
    async goMainPage() {
      await this.$router.push({
        name: this.authList.includes('misResveStatusGet')
          ? 'ReserveStatus'
          : 'Main'
      });
    },
    async onSignInClick() {
      if (!this.form.email) {
        this.$vs.notification({
          color: 'warning',
          title: this.$t('common.error'),
          text: this.$t('commonMessage.enterId')
        });
        return;
      }
      if (!this.form.password) {
        this.$vs.notification({
          color: 'warning',
          title: this.$t('common.error'),
          text: this.$t('commonMessage.enterPassword')
        });
        return;
      }
      const { accessToken } = await GolfErpAPI.login(
        this.form.email,
        this.form.password
      );
      saveAccessToken(accessToken);
      this.isKeepLoginEmail
        ? saveLoginEmail(this.form.email)
        : clearLoginEmail();
      if (this.isKeepTempAutoLogin) {
        saveTempLoginPassword(this.form.password);
        saveTempAutoLogin('Y');
      } else {
        clearTempLoginPassword();
        clearTempAutoLogin();
      }
      await this.loadApplication();
      await this.goMainPage();
    }
  }
};
</script>
