<template>
  <div class='imageBox'>
    <img :src='url' alt='report'>
  </div>
</template>

<style lang='scss' scoped>
.imageBox {
  display: flex;
  width: 100%;
  > img {width: 100%}
}
</style>

<script>
export default {
  name: 'ReportView',
  data() {
    return {
      url: null
    };
  },
  created() {
    this.url = this.$route.query.url;
  }
};
</script>
