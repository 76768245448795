<template>
  <div class='layout'>
    <the-header @signOut='onSignOut' />
    <main>
      <div class='main-wrap'>
        <slot />
      </div>
    </main>
    <the-footer />
    <div
      v-if='isSignOutPopupOpened'
      class='ui-modal'
    >
      <sign-out-popup
        ref='signOutPopup'
        @close='onCloseSignOutPopup'
      />
    </div>
  </div>
</template>

<style scoped>
body .layout {height: 100%;}
</style>

<script>
import TheHeader from '@/components/Menu/TheHeader';
import TheFooter from '@/components/Menu/TheFooter';
import SignOutPopup from '@/components/Modals/SignOutPopup';

export default {
  name: 'DefaultLayout',
  components: {
    TheHeader,
    TheFooter,
    SignOutPopup
  },
  data() {
    return {
      isSignOutPopupOpened: false
    };
  },
  methods: {
    onSignOut() {
      if (this.isSignOutPopupOpened) return;
      this.isSignOutPopupOpened = true;
    },
    async onCloseSignOutPopup() {
      this.isSignOutPopupOpened = false;
    }
  }
};
</script>
